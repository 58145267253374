import { HttpClient } from '@angular/common/http';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Inject, Injectable, PLATFORM_ID, isDevMode } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Paths } from 'src/app/app-routing.module';
import { WebsocketService } from 'src/app/features/messaging/services/websocket.service';

const ms = require("ms");

export interface AuthData {
  email: string,
  password: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private email: string;
  private token: string;
  private tokenTimer: any;
  private isAuthenticated = false;
  private authStatusListener = new Subject<boolean>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private websocketService: WebsocketService,
    private cookieService: SsrCookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
    // private analyticsService: AnalyticsService,
  ) {

    this.checkForFaultyLogin();

  }
  createUser(registrationForm: FormGroup, res): void {

    const token = res.token;
    this.token = token;

    if (token) {

      this.cookieService.set('rs-my-id', res.ownId, { expires: 365, path: '/' });
      this.trackUser();

      this.email = registrationForm["email"];
      const expiresInDuration = ms(res.expiresIn);
      this.setAuthTimer(expiresInDuration);
      this.isAuthenticated = true;
      this.authStatusListener.next(true);
      const now = new Date();
      const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
      this.saveAuthData(token, expirationDate);
      this.router.navigate([Paths.InitProfile]);



    }

  }
  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }
  getEmail() {
    return this.email;
  }
  getIsAuthenticated() {

    this.isAuthenticated = this.cookieService.check('rs-jwt-token') ? true : false;

    return this.isAuthenticated;

  }
  public getToken(): string {

    if (!this.token) return this.cookieService.get('rs-jwt-token');

    return this.token;

  }
  login(response, authData): boolean {

    const token = response.token;
    this.token = token;

    if (token) {

      this.cookieService.set('rs-my-id', response.id, { expires: 365, path: '/' });
      this.trackUser();

      this.email = authData.email;
      const now = new Date();
      const expiresInDuration = ms(response.expiresIn);
      const expirationDate = new Date(now.getTime() + expiresInDuration);

      this.setAuthTimer(expiresInDuration);
      this.saveAuthData(token, expirationDate);
      this.websocketService.initWebSocketConnection(this as AuthService, "login");

      this.isAuthenticated = true;
      this.authStatusListener.next(true);

      return true;

    }

    return false;

  }
  autoAuthUser() {

    const authInformation = this.getAuthData();

    if (!authInformation) return;

    const now = new Date();

    // https://stackoverflow.com/questions/18492576/share-cookies-between-subdomain-and-domain
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie

    this.token = authInformation.token;
    this.isAuthenticated = true;
    this.websocketService.initWebSocketConnection(this as AuthService, "autoAutUser");
    this.authStatusListener.next(true);

  }
  async logout() {

    // Client only code.

    if (isPlatformBrowser(this.platformId)) {

      this.unsubscribeFromPushNotifications();

      this.token = null;
      this.isAuthenticated = false;

      this.authStatusListener.next(false);
      clearTimeout(this.tokenTimer);
      this.clearAuthData();
      this.router.navigate(['/']);

    }

  }
  private unsubscribeFromPushNotifications() {

    this.http.post<any>(environment.apiUrl + "unsubscribePNs", null, { withCredentials: true }).subscribe();

  }
  private setAuthTimer(duration: number) {

    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration);
  }
  private saveAuthData(token: string, expirationDate: Date) {

    this.cookieService.set('rs-jwt-token', token, {
      expires: 365,
      path: '/' // the authentication needs to be available for all paths, e.g. /de, /en
    });

  }
  private clearAuthData() {

    if (document && document.cookie && this.cookieService) {
      try {

        console.log("Clearing cookies ...");
        console.log(document.cookie);

        console.log("this.cookieService.getAll()");
        console.log(this.cookieService.getAll());

        document.cookie.split(";").forEach((c) => {

          // console.log(c);
          let cookieName = c.split("=")[0].trim();

          console.log(cookieName);

          if (cookieName === 'rs-jwt-token' || cookieName === 'rs-my-id') {

            console.log("Deleting cookie: " + cookieName);

            var now = new Date();
            var time = now.getTime();
            var expireTime = time;
            now.setTime(expireTime);

            document.cookie = cookieName + "=; expires=" + now.toUTCString() + "; path=/";

          }

        });

      } catch (e) {
        console.error(e);
      }

    }

  }
  private getAuthData() {

    const token = this.cookieService.get('rs-jwt-token');
    this.trackUser();

    if (!token) return;

    return {
      token: token
    }

  }
  /**
  * With the introduction of ssr we need to check for a faulty session cookie.
  * If the cookie is faulty we need to log the user out.
  *
  * TODO: Remove this method when the faulty session cookie bug is fixed.
  */
  private checkForFaultyLogin() {

    if (this.cookieService.check('rs-jwt-token') && !this.cookieService.check('rs-my-id')) this.logout();

  }
  private trackUser() {

    // if (this.cookieService.check('rs-my-id')) this.analyticsService.trackUser(this.cookieService.get('rs-my-id'));

  }
}
