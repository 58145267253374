<div id="ios-status-bar-container" [ngStyle]="{'background-color': iosStatusBarService.backgroundColorStatusBar()}">

  <div id="app-status-bar"></div>

  @if (this.heightAppBanner) {
  <div id="get-the-app-ads-container" [ngStyle]="{'background-color': iosStatusBarService.backgroundColorStatusBar()}">

    <a class="get-the-app-button" href="https://play.google.com/store/apps/details?id=org.rattlestork.twa" style="margin-right: 10px;"><img ngSrc='assets/icons/Google_Play.svg'></a>
    <a class="get-the-app-button" href="https://apps.apple.com/de/app/rattlestork/id6503442456"><img ngSrc="assets/icons/App_Store.svg"></a>

    <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;">
      <button (click)="closeAppAdsContainer()" mat-icon-button aria-label="Close icon button to close the app ads bar">
        <mat-icon>close</mat-icon>
      </button>
    </div>

  </div>
  }

</div>
