import { NavigationStart, Params } from '@angular/router';
import { BehaviorSubject, Subject, Subscription, filter, firstValueFrom } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, PLATFORM_ID, signal } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Chat, Message, RawChat } from '../components/chat/chat.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { WebsocketService } from './websocket.service';
import { MyProfileDataService } from '../../users/services/my-profile-data.service';
import { BlockUserService } from 'src/app/core/services/block-user.service';
import { Paths } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class ChatService implements OnDestroy {

  isInitialized: boolean = false;

  routingSubscription: Subscription = new Subscription();
  routingSubscriptionInitialized: boolean = false;
  incomingMessageSubscription: Subscription;

  $reset: Subject<boolean> = new Subject();

  input: HTMLElement;

  /**
   * MongoDB unique id of the person to chat with.
   */
  activeChatID: string = undefined;
  authToken: string;
  chats: Chat[];
  chatListChats = signal<Chat[]>([]);
  messagesOfActiveChat: Message[];
  myId: string;
  activeChat = null;
  /**
   * Used to notify the chatlist so the displayed last message can be updated
   */
  $newMessage: Subject<Message> = new Subject();
  unreadMessages: Message[] = [];
  $fetchedUnreadMessages: BehaviorSubject<boolean> = new BehaviorSubject(false);
  $unreadMessagesChanged: Subject<boolean> = new Subject();
  $mutedUser: Subject<string> = new Subject();
  fetchingChats: boolean;

  userIsSelectingImage: boolean = false;
  /**
  * The image the user has selected to send.
  */
  selectedImage: string = null;
  /**
   * The image that is displayed over the chat when the user
   * is clicked/pointed on a messages image.
   */
  overlayedImage: string = null;

  highlightFilterAlle = signal<boolean>(true);
  highlightFilterUngelesen = signal<boolean>(false);

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private websocketService: WebsocketService,
    private cookieService: SsrCookieService,
    private myProfileDataService: MyProfileDataService,
    private blockUserService: BlockUserService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {

  }
  ngAfterViewInit() {

    this.addRoutingSubscriptions();

  }
  ngOnDestroy() {

    if (this.routingSubscription) this.routingSubscription.unsubscribe();
    if (this.incomingMessageSubscription) this.incomingMessageSubscription.unsubscribe();

  }
  public async initializeChat() {

    if (isPlatformBrowser(this.platformId)) {

      this.reset();
      this.addIncomingMessageListener();

      this.fetchingChats = true;

      const chats = await this.getChatList();

      if (chats && chats.length > 0) {

        this.chatListChats.set(this.chats);
        this.activeChatID = this.getActiveChatID();
        this.messagesOfActiveChat = await this.getActiveChatMessages(this.activeChatID);

        setTimeout(_ => this.isInitialized = true, 1);

      }
      this.fetchingChats = false;

    }

  }
  /**
   * Adds the required routing subscription
   */
  private addRoutingSubscriptions(): void {

    if (!this.routingSubscriptionInitialized) {

      this.routingSubscription.add(this.router.events
        .pipe(filter(event => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {

          //Always reset this component before routing here
          if (isPlatformBrowser(this.platformId) && event.url == Paths.Chat) this.initializeChat();
          // remove the active chat id when leaving the chat component
          else this.activeChatID = undefined;

        }));

      this.routingSubscriptionInitialized = true;

    }

  }
  private addIncomingMessageListener(): void {

    this.incomingMessageSubscription = this.websocketService.$incomingMessage.subscribe((incomingMessage: Message) => {

      if (incomingMessage && incomingMessage.from == this.activeChatID) {

        if (Array.isArray(this.messagesOfActiveChat)) {

          let messageIds = this.messagesOfActiveChat.map(message => message._id);;

          // this if-clause is just implemented because of an error in production.
          // See issue Web-Platform 43
          if (incomingMessage["_id"] && messageIds.indexOf(incomingMessage["_id"]) == -1) {


            this.messagesOfActiveChat.push(incomingMessage);
            this.messagesOfActiveChat = [...this.messagesOfActiveChat];

            this.$newMessage.next(incomingMessage);

          }

        } else this.messagesOfActiveChat = [incomingMessage]; // In case the open chat has no messages yet, theoretically not possible

      } else if (incomingMessage && incomingMessage.from != this.cookieService.get('rs-my-id')) {

        this.addUnreadMessage(incomingMessage);

      } else {

      }

      setTimeout(_ => { }, 1);

    });

  }
  private async addUnreadMessage(incomingMessage: Message) {

    let isNewMsg: boolean = true;

    let a = {}

    this.unreadMessages.forEach(msg => {

      a[msg._id] = { msgid: msg._id, incomingMessage: incomingMessage._id };

      if (msg._id == incomingMessage._id) {

        isNewMsg = false;

      }

    });

    if (isNewMsg) {

      /**
       * TODO:
       * Add a check if a chat with the same id already exists in the chat list.
       * If so, just add the message to the chat. If not, fetch the chat list again.
       */
      const chats = await this.getChatList();
      this.chatListChats.set(this.chats);

      this.unreadMessages = [...this.unreadMessages, incomingMessage];
      /**
       * Remove duplicates from the unread messages array. These duplicates can occur
       * because there is some reason why a new message arrives twice (seems like there is
       * one additional new message listener subscribed to the websocket service). TODO: Fix this
       * so there is no reason for this workaround.
       */
      this.unreadMessages = [...new Set(this.unreadMessages)];

      this.$newMessage.next(incomingMessage);

    }


  }
  private reset(): void {

    this.chats = null;
    this.activeChatID = null;
    this.$reset.next(true);

  }
  /**
  * Fetches the list of all chat instances.
  */
  private async getChatList(): Promise<RawChat[]> {

    if (isPlatformBrowser(this.platformId) && this.authService.getIsAuthenticated()) {

      this.activeChatID = this.getActiveChatID();

      this.authToken = "Bearer " + this.authService.getToken();
      let chats: RawChat[];
      /**
       * Depending on the origin of routing the chat component will be opened
       * with initially determined chat (routing from the user search and then
       * selecting a user to chat with) or without (routing to messages from
       * the nav menu). In the first case the ID of the person to chat with is
       * given via the routing parameters. If it is a new correspondence the backend
       * needs to know this new id to deliver the meta data back to the user
       * (profile picture, last active and so on).
       */
      let params: HttpParams = new HttpParams().set("activeChat", this.activeChatID);

      let response = await firstValueFrom(this.http
        .get<RawChat[]>(environment.apiUrl + "chatList", {
          params,
          responseType: "json",
          observe: "response",
          withCredentials: true
        }));

      if (this.cookieService.check("rs-my-id")) this.myId = this.cookieService.get("rs-my-id");
      else if (localStorage.getItem("rs-my-id")) this.myId = localStorage.getItem("rs-my-id");

      chats = response.body;

      if (!chats) chats = null;

      const parsedChats = this.parseChats(chats);
      this.chats = parsedChats;

      return parsedChats;

    } else return [];

  }
  /**
   * Parses the received raw chat messages (just adds the isActive field).
   *
   * @param chats
   * @returns
   */
  private parseChats(rawChats: RawChat[]): Chat[] {

    let chats: any[] = JSON.parse(JSON.stringify(rawChats));
    //chats.sort()

    for (let chat of chats) chat["isActive"] = false;

    return chats;

  }
  /**
   * Sets the active chat and returns its id. The active chat is set by:
   *
   * - the activeChatID in the routing params if set (if coming from the user search)
   *
   * - the chat last chatted with
   *
   * @returns the ID of the active chat.
   */
  private getActiveChatID() {

    let activeChatID = undefined;

    if (this.router.url.includes(Paths.Chat)) {

      if (
        this.activatedRoute.snapshot.queryParams.activeChatId ||
        (
          this.activatedRoute.snapshot.queryParams._value &&
          this.activatedRoute.snapshot.queryParams._value.activeChatId // when coming from user list
        ) ||
        (
          this.activatedRoute.snapshot.queryParams._value &&
          this.activatedRoute.snapshot.queryParams._value.id // when coming from profile page
        )
      ) {

        activeChatID = this.activatedRoute.snapshot.queryParams.activeChatId;
        if (!activeChatID) activeChatID = this.activatedRoute.snapshot.queryParams.id;

        if (this.chats) {

          for (let chat of this.chats) {

            if (chat.id && chat.id == activeChatID) {

              chat.isActive = true;
              this.activeChat = chat;
              break;

            }

          }

        }

      }

    }

    return activeChatID;

  }
  public changeActiveChatIDQueryParam(chatID: string): void {

    const queryParams: Params = { activeChatId: chatID };

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

  }
  /**
 * Fetches all messages of the active chat. Additionally marks all
 * messages as viewed.
 *
 * TODO: Implement virtual scroll
 *
 * @param id of the active chat
 */
  private async getActiveChatMessages(id: string): Promise<Message[]> {

    if (isPlatformBrowser(this.platformId) && this.authService.getIsAuthenticated() && id) {

      this.authToken = "Bearer " + this.authService.getToken();

      this.activeChatID = id ? id : "";
      let params: HttpParams = new HttpParams().set("activeChat", id);
      let messagesOfActiveChat;

      let response = await firstValueFrom(this.http
        .get(environment.apiUrl + "activeChat", {
          params,
          responseType: "json",
          observe: "response",
          withCredentials: true
        }))

      messagesOfActiveChat = response.body["messagesOfActiveChat"] ? response.body["messagesOfActiveChat"] : [];

      if (!Array.isArray(messagesOfActiveChat)) messagesOfActiveChat = [messagesOfActiveChat];

      this.removeMessagesFromUnreadStack(id);

      return messagesOfActiveChat;

    } else return undefined;


  }
  private removeMessagesFromUnreadStack(id: string): void {

    let i = this.unreadMessages.length - 1;

    while (i >= 0) {

      if (this.unreadMessages[i] == null) this.unreadMessages.splice(i, 1);
      else if (this.unreadMessages[i].from == id) this.unreadMessages.splice(i, 1);
      else if (this.unreadMessages[i].to == id) this.unreadMessages.splice(i, 1);

      i--;

    }

    this.$unreadMessagesChanged.next(true);

  }
  public async sendMessage() {

    if (isPlatformBrowser(this.platformId)) {

      const messageContent = document.getElementById("rs-chat-form-input-div").innerHTML;

      // TODO: Sanitize message content (https://www.npmjs.com/package/sanitize-html)

      const image = this.selectedImage;

      const activeID = this.activatedRoute.snapshot.queryParams.activeChatId;

      const sendMessage = await this.websocketService
        .sendMessage(
          messageContent,
          activeID,
          this.authService,
          image ? image : null
        );

      this.messagesOfActiveChat = [...this.messagesOfActiveChat, sendMessage];

      this.selectedImage = null;
      this.userIsSelectingImage = false;

      this.$newMessage.next(sendMessage);

    }

  }
  public changeActiveChat(newActiveChat: RawChat) {

    let foundOldActive: boolean = false;
    let foundNewActive: boolean = false;

    if (this.chats) {

      for (let chat of this.chats) {

        if (foundNewActive && foundOldActive) break;

        if (chat.isActive) {

          chat.isActive = false;
          foundOldActive = true;

        }

        if (chat.id == newActiveChat.id) {

          chat.isActive = true;
          this.activeChat = chat;

          this.changeActiveChatIDQueryParam(chat.id);
          this.removeMessagesFromUnreadStack(chat.id);
          this.markMessageAsRead(chat.id);
          this.getActiveChatMessages(chat.id).then(messages => {

            if (messages) this.messagesOfActiveChat = messages;
            else this.messagesOfActiveChat = [];

          });

          foundNewActive = true;

        }


      }

    }

    setTimeout(_ => { }, 1);

  }
  public async getAllUnreadMessages(): Promise<Message[]> {

    if (isPlatformBrowser(this.platformId) && this.authService.getIsAuthenticated()) {

      this.authToken = "Bearer " + this.authService.getToken();
      let unreadMessages: Message[];

      unreadMessages = await firstValueFrom(this.http
        .get<Message[]>(environment.apiUrl + "unreadMessages", {
          responseType: "json",
          observe: "body",
          withCredentials: true
        }));


      if (!unreadMessages) unreadMessages = [];

      this.unreadMessages = unreadMessages;

      this.$fetchedUnreadMessages.next(true);

      return unreadMessages;

    } return [];

  }
  public markMessageAsRead(chatId: string): void {

    let params: HttpParams = new HttpParams().set("chatId", chatId);

    this.http.post<any>(environment.apiUrl + "markAsRead", null, { params: params, withCredentials: true })
      .subscribe({
        next: response => {

        },
        error: error => {

          console.log(error);

        }
      })


  }
  public getActiveChatThumbnail(): string {

    let thumbnail: string;
    let prefixThumbnail: string = "data:image/jpeg;charset=utf-8;base64,";

    if (this.activeChat && this.activeChat.thumbnail) thumbnail = prefixThumbnail + this.activeChat.thumbnail;
    else if (this.activeChat && !this.activeChat.thumbnail) thumbnail = this.activeChat.defaultProfilePicture;

    return thumbnail;

  }
  public closeImageOverlay(): void {

    this.overlayedImage = null;

  }
  blockUser(id: string): void {

    //Update the already fetched chat objects. Maybe it`d be better to just fetched the updated objects??
    if (this.activeChat.blocking.blockedBy.includes(this.myId)) {

      const index = this.activeChat.blocking.blockedBy.indexOf(this.myId);
      this.activeChat.blocking.blockedBy.splice(index, 1);

    } else {

      this.activeChat.blocking.blockedBy.push(this.myId);
      this.$mutedUser.next(this.activeChat.id);

    }
    this.blockUserService.blockUser(id)

  }
  resetActiveId() {
    this.activeChatID = null;
  }
}
