import { Component, Inject, Input, OnDestroy, PLATFORM_ID, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IosStatusBarService, ThemeColors } from '../../services/ios-status-bar.service';
import { breakpoint } from 'src/app/features/users/services/user-list.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'rs-splash-screen',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './splash-screen.component.html',
  styleUrl: './splash-screen.component.scss'
})
export class SplashScreenComponent implements OnDestroy {

  @Input() infoText = "Rattlestork";
  loadingProgress = signal<number>(0);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private iosStatusBarService: IosStatusBarService
  ) {

    if (isPlatformBrowser(this.platformId) && window.innerWidth) {

      if (window.innerWidth < breakpoint.mobile) this.iosStatusBarService.changeNativeStatusBarColor(ThemeColors.color1);
      else this.iosStatusBarService.changeNativeStatusBarColor(ThemeColors.color2);

    }
    this.estimateLoadingTime();

  }
  ngOnDestroy(): void {

    this.iosStatusBarService.resetStatusBarColor();

  }
  estimateLoadingTime() {

    const time = Math.floor(Math.random() * 1000);
    setTimeout(() => {
      this.loadingProgress.set(85);
    }, time);

  }
}
