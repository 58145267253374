
import { AfterViewInit, Component, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation, signal, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';

import { CommonModule, NgOptimizedImage, isPlatformBrowser } from '@angular/common';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { environment } from 'src/environments/environment';
import { Paths } from 'src/app/app-routing.module';
import { AppService } from 'src/app/app.service';
import { SafeHtmlPipe } from 'src/app/features/messaging/components/chat/pipes/safe-html.pipe';
import { ChatService } from 'src/app/features/messaging/services/chat.service';
import { MyProfileDataService } from 'src/app/features/users/services/my-profile-data.service';
import { IconRegistryModule } from 'src/app/shared/icons/icon-registry.module';
import { LanguageService } from 'src/app/shared/services/language.service';
import { AuthService } from '../../services/auth.service';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    SidenavComponent,
    MatBadgeModule,
    MatIconModule,
    MatButtonModule,
    IconRegistryModule,
    NgOptimizedImage,
    MatMenuModule,
    SafeHtmlPipe
  ],
  selector: 'rs-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopBarComponent implements OnInit, AfterViewInit, OnDestroy {

  userIsAuthenticated = false;
  hamburgerClicked;
  showLoginComponent;
  numberOfUnreadMessages = signal<number>(0);
  showMobileVersion: boolean = null;
  paths = Paths;
  apiUrl: string = environment.apiUrl;
  breakpointMobile: number;
  thumbnail = signal("");
  languageMetaInfo = Object.values(this.languageService.languagesMetaInfo);

  private subscriptions: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    public chatService: ChatService,
    public appService: AppService,
    public languageService: LanguageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public myProfileDataService: MyProfileDataService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {

    if (isPlatformBrowser(this.platformId)) {
      this.breakpointMobile = Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-mobile').replace("px", ""));
    }

  }

  ngOnInit() {

    this.profileImageHandler();

    this.showMobileVersion = this.switchToMobileStyle();

    this.userIsAuthenticated = this.authService.getIsAuthenticated();

    this.subscriptions.add(this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {

        this.userIsAuthenticated = isAuthenticated;

      }));

    this.subscriptions.add(this.chatService.$newMessage.subscribe(message => {

      this.numberOfUnreadMessages.set(this.chatService.unreadMessages.length);

      this.changeDetectorRef.detectChanges();

    }));

    this.subscriptions.add(this.chatService.$unreadMessagesChanged.subscribe(changed => {

      this.numberOfUnreadMessages.set(this.chatService.unreadMessages.length);
      setTimeout(() => { }, 1);

    }));

    this.subscriptions.add(this.chatService.$fetchedUnreadMessages.subscribe(_ => {

      this.numberOfUnreadMessages.set(this.chatService.unreadMessages.length);

      setTimeout(() => { }, 1);


    }));

    this.hamburgerClicked = this.appService.showHamburgerMenu;

    this.subscriptions.add(this.appService.$showHamburgerMenuSubject.subscribe({
      next: state => this.hamburgerClicked = state
    }));

  }
  ngAfterViewInit(): void {

    this.profileImageHandler();
    if (this.appService.onBrowserPlattForm && !this.chatService.isInitialized) this.chatService.initializeChat();

  }
  ngOnDestroy() {

    this.subscriptions.unsubscribe();

  }
  private profileImageHandler() {

    if (this.hasProfileImage()) this.thumbnail.set(this.myProfileDataService.profileImage.image);

    this.subscriptions.add(this.myProfileDataService.triggerProfileImageChangeDetection.subscribe(image => {

      if (this.hasProfileImage()) this.thumbnail.set(this.myProfileDataService.profileImage.image);

    }));

  }
  public hasProfileImage() {

    return this.myProfileDataService.myProfileImageFetched
      && this.myProfileDataService.profileImage;
  }
  public hasThumbnail() {
    return this.myProfileDataService.myProfileDataFetched
      && this.myProfileDataService.myProfileForm.value["defaultProfilePicture"];
  }
  switchToMobileStyle(): boolean {

    if (isPlatformBrowser(this.platformId)) {

      if (window.innerWidth < 1280) {
        return true;
      } else {
        return false;
      }

    }

  }
  onHamburgerClicked() {

    this.appService.showHamburgerMenu = !this.hamburgerClicked;

  }
  goHome() {

    this.appService.showHamburgerMenu = false;
    // this.router.navigate(["./"]);

  }
  onRouteToChat() {

    let queryParams: Params;
    let activeChatId = this.activatedRoute.snapshot.queryParams.activeChatId;

    if (activeChatId) queryParams = { activeChatId: activeChatId };

    this.router.navigate(['/chat'], { queryParams: queryParams });

  }
  onLogout() {

    this.authService.logout();

  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.showMobileVersion = this.switchToMobileStyle();

  }
  showAppMenu() {

    if (this.appService.onBrowserPlattForm) {
      return (window.innerWidth <= this.breakpointMobile && this.userIsAuthenticated);
    }

    return;

  }
}
