import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from "@angular/common";
import { Component, OnInit, OnDestroy, Input, isDevMode, HostListener } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule, ActivatedRoute, Params, Router } from "@angular/router";
import { Paths } from "src/app/app-routing.module";
import { AppService } from "src/app/app.service";
import { environment } from "src/environments/environment";
import { AuthService } from "../../services/auth.service";
import { IosStatusBarService, ThemeColors } from "../../services/ios-status-bar.service";


@Component({
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MatIconModule,
    MatDividerModule
  ],
  selector: 'rs-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  @Input("fill") fill: boolean = false;
  apiUrl: string = environment.apiUrl;
  paths = Paths;

  constructor(
    public authService: AuthService,
    public appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private iosStatusBarService: IosStatusBarService
  ) {

    if (this.appService.onBrowserPlattForm && window.innerWidth < this.appService.breakpointMobile) {
      this.iosStatusBarService.changeNativeStatusBarColor(ThemeColors.color3);
    }

  }
  ngOnInit() {

  }
  ngOnDestroy(): void {
    this.iosStatusBarService.changeNativeStatusBarColor(ThemeColors.color2);
  }
  onLogout() {
    this.authService.logout();
  }
  onClick() {
    this.appService.showHamburgerMenu = false;
  }
  /**
   * Keeps the activeChatID query param if set. This is needed
   * when routing from the chat, with set active chat id, to
   * the chat (same site routing using the sidebar).
   */
  onRouteToChat() {

    let queryParams: Params;
    let activeChatId = this.activatedRoute.snapshot.queryParams.activeChatId;

    if (activeChatId) queryParams = { activeChatId: activeChatId };

    this.router.navigate(['/chat'], { queryParams: queryParams });

  }
  useLanguage(language: string) {

    if (!isDevMode()) {
      if (language === 'en') {
        location.href = environment.apiUrl + language;
      } else if (language === 'de') {
        location.href = environment.apiUrl + language;
      }
    }

  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    // TODO: Close sidenav if click outside the sidenav when opened

    // if (this.appService.showHamburgerMenu = true) {

    //   if (this.eRef.nativeElement.contains(event.target)) {
    //     this.appService.showHamburgerMenu = true;
    //   } else {

    //     if (this.appService.showHamburgerMenu) {
    //       this.appService.showHamburgerMenu = false;
    //     }

    //   }

    // }

  }
}
