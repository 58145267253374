import { Component, ElementRef, Inject, LOCALE_ID, ViewChild, OnDestroy, HostListener, Renderer2, Output, EventEmitter, OnInit, isDevMode, Input } from '@angular/core';
import { Loader } from "@googlemaps/js-api-loader"
import { environment } from 'src/environments/environment';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

export class PlacePrediction {

  constructor(
    public description: string,
    public place_id: string,
    public structured_formatting: {
      main_text: string,
      secondary_text: string
    }
  ) { }
  toString(): string {
    return this.description;
  }
}
export class PlaceSelection extends PlacePrediction {
  constructor(
    public place: PlacePrediction,
    public location: {
      lat: number,
      lng: number
    }
  ) {
    super(place.description, place.place_id, place.structured_formatting);
  }
}

@Component({
  selector: 'location-select',
  standalone: true,
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    FormsModule,
    MatInputModule,
  ],
  templateUrl: './location-select.component.html',
  styleUrl: './location-select.component.scss'
})
export class LocationSelectComponent implements OnInit {

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @Input() initValue: PlacePrediction = new PlacePrediction("", "", { main_text: "", secondary_text: "" });
  @Output() onPlaceSelected: EventEmitter<PlacePrediction> = new EventEmitter<PlacePrediction>();

  private autocompleteService;
  private AutocompleteSessionTokenGenerator;
  private AutocompleteSessionToken;
  private geocoder;

  placesControl = new FormControl(this.initValue);
  prevSuggestionSeed: string = "";
  suggestions: PlacePrediction[] = [];
  placeholder: string = $localize`:@@location-select__placeholder:Geben Sie Ihre Region an`;

  constructor(@Inject(LOCALE_ID) public locale: string
  ) { }
  ngOnInit(): void {
    this.placesControl.patchValue(this.initValue);
    this.placesControl.updateValueAndValidity();
  }
  /**
   * Initializes the Google Places Autocomplete Service, gets a session token and a geocoder instance.
   */
  async initPlacesAutocomplete(): Promise<void> {

    if (!this.autocompleteService) {

      const loader = await new Loader({
        apiKey: environment.googlePlacesApiKeyWeb,
        version: "weekly",
        libraries: ["places"]
      });

      const { AutocompleteService, AutocompleteSessionToken, Place } = await loader.importLibrary("places");
      const { Geocoder } = await loader.importLibrary("geocoding");

      this.autocompleteService = new AutocompleteService();
      this.AutocompleteSessionTokenGenerator = AutocompleteSessionToken;
      this.geocoder = new Geocoder();

      this.createNewSessionToken();
    }

  }
  async suggestPlaces($event): Promise<void> {

    await this.initPlacesAutocomplete();

    const seed = $event.target.value !== "[object Object]" ? $event.target.value : null;

    try {

      if (!seed) {
        this.suggestions = [];
        return;
      }
      if (seed === this.prevSuggestionSeed) {
        return;
      }

      this.autocompleteService.getPlacePredictions({
        input: $event.target.value,
        componentRestrictions: {},
        language: this.locale,
        sessionToken: this.AutocompleteSessionToken,
        fields: ["name"],
        types: ["(cities)"] // Docs: https://developers.google.com/maps/documentation/javascript/supported_types#table2
      }, (predictions, status) => {

        if (status === "OK") {

          // Save the seed to avoid suggesting the same places again
          // when the user just scrolls the suggestions (same seed)
          this.prevSuggestionSeed = seed;

          if (predictions.length > 0) {

            this.suggestions = [];
            predictions.forEach(prediction => {
              this.suggestions.push(prediction)
            });

          }
        }

      })
    } catch (error) {

      if (isDevMode()) {
        console.error(error);
      }
      this.suggestions = [];

    }



  }
  displayFn(placePrediction: PlacePrediction): string {

    let text = "";

    if (!placePrediction) {
      return text;
    }

    if (placePrediction.description) {
      text += placePrediction.description;
    }

    return text;
  }
  createNewSessionToken(): void {

    this.AutocompleteSessionToken = new this.AutocompleteSessionTokenGenerator();

  }
  async onOptionSelected($event) {

    try {

      if ($event && $event.option && $event.option.value) {

        let place = $event.option.value;
        let geoData = await this.geocoder.geocode({ placeId: place.place_id });

        let lat, long;

        try {
          lat = geoData.results[0].geometry.location.lat();
          long = geoData.results[0].geometry.location.lng();
        } catch (error) { }

        let placeSelection = new PlaceSelection(place, { lat: lat, lng: long });

        this.onPlaceSelected.emit(placeSelection);

        this.createNewSessionToken();

      }

    } catch (error) {



    }


  }
}

