import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, LOCALE_ID, OnDestroy, PLATFORM_ID, signal } from '@angular/core';
import { Subject, Subscription, filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { ChatService } from './features/messaging/services/chat.service';

@Injectable({
  providedIn: 'root'
})
export class AppService implements OnDestroy {

  onBrowserPlattForm: boolean = null;
  breakpointMobile: number = null;
  backendUrl = environment.apiUrl;
  subscriptions: Subscription = new Subscription();
  currentURLPath = signal<string>("");

  // User Data

  ownId: string = null;
  ownThumbnail: string = null;
  public myID: string; // TODO: is ownID and myID the same???

  // Fields for structural behaviorr

  private _showHamburgerMenu = false;
  $showHamburgerMenuSubject = new Subject<boolean>();
  public get showHamburgerMenu() {
    return this._showHamburgerMenu;
  }
  public set showHamburgerMenu(show: boolean) {
    this._showHamburgerMenu = show;
    this.$showHamburgerMenuSubject.next(this._showHamburgerMenu);
  }
  locales = [
    { "de": "Deutsch" },
    { "de-CH": "Schweizerdeutsch" },
    { "en": "English" },
    { "en-IN": "English" },
    { "en-GB": "English" },
    { "es": "Español" },
    { "es-MX": "Español" },
    { "fr": "Français" },
    { "ms": "Bahasa Malaysia" },
    { "id": "Bahasa Indonesia" },
    { "pt": "Português" },
    { "pt-BR": "Português Brasileiro" },
    { "ru": "Русский" },
    { "hi": "हिन्दी" },
    { "zh": "简体中文" },
    { "it": "Italiano" },
    { "nl": "Nederlands" },
    { "pl": "Polski" },
    { "tr": "Türkçe" }
  ];

  constructor(
    private authService: AuthService,
    private chatService: ChatService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(LOCALE_ID) public locale: string
  ) {

    this.onBrowserPlattForm = isPlatformBrowser(this.platformId);
    if (this.onBrowserPlattForm) {
      this.breakpointMobile = Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-mobile').replace("px", ""));
      this.setAuthStateListener();
      this.startURLPathDetection();
    }

  }
  ngOnDestroy(): void {

    if (this.subscriptions) this.subscriptions.unsubscribe();

  }
  private startURLPathDetection() {

    this.subscriptions.add(this.router.events.subscribe({
      next: (event) => {

        if (event instanceof NavigationEnd) {

          try {
            this.currentURLPath.set(this.route.firstChild.snapshot.routeConfig.path);
          } catch (error) {
            console.error("Error in startURLPathDetection: ", error);
            console.error("event: ", event);
          }

        }

      }
    }));

  }
  setAuthStateListener() {

    this.subscriptions.add(this.authService.getAuthStatusListener().subscribe({
      next: isAuthenticated => {

        this.showHamburgerMenu = false;
        if (isAuthenticated) this.chatService.getAllUnreadMessages();

      }
    }));

  }
}
