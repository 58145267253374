import { Component, Inject, PLATFORM_ID, Injector, OnInit, OnDestroy, AfterViewInit, ApplicationRef, NgZone, signal, isDevMode } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Subscription, filter, map, mergeMap } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router, ActivatedRoute, ActivationEnd, NavigationStart } from '@angular/router';
import { SEOService } from './core/services/seo.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Paths } from './app-routing.module';
import { AuthService } from './core/services/auth.service';
import { WebsocketService } from './features/messaging/services/websocket.service';
import { MyProfileDataService } from './features/users/services/my-profile-data.service';
import { AnalyticsService } from './core/services/analytics.service';
import { UserListService } from './features/users/services/user-list.service';
import { AppService } from './app.service';
import { SplashScreenService } from './core/services/splash-screen.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, AfterViewInit {

  readonly title = 'RattleStork';
  private subscriptions: Subscription = new Subscription();
  private paths = Paths;
  public showNavigation = signal(true);

  constructor(
    private authService: AuthService,
    private websocketService: WebsocketService,
    private swUpdate: SwUpdate,
    @Inject(PLATFORM_ID) private platformId: string,
    private router: Router,
    private _seoService: SEOService,
    private myProfileDataService: MyProfileDataService,
    private analyticsService: AnalyticsService,
    private userListService: UserListService,
    private zone: NgZone,
    public appService: AppService,
    public splashScreenService: SplashScreenService
  ) {

    this.authService.autoAuthUser();

    this._seoService.startSEOHandler();
    this.styleAndroidStatusBar();
    this.removeTermlyForNativeApps();


    this.startAppMenuHandler();
    this.addDeepLinkRedirect();

    this.myProfileDataService.startProfileService();

  }
  ngAfterViewInit() {

    if (isPlatformBrowser(this.platformId)) {

      // this.analyticsService.initCookieBanner();
      this.checkForNewVersion();

    }

  }
  ngOnDestroy(): void {

    if (this.subscriptions) this.subscriptions.unsubscribe();
    if (this._seoService.subscriptions) this._seoService.subscriptions.unsubscribe();
    this.websocketService.disconnectSocket();
    this.userListService.thumbnails.clearThumbnails();

  }
  private removeTermlyForNativeApps() {

    if (isPlatformBrowser(this.platformId) && Capacitor.getPlatform() !== 'web') {

      const termly = document.getElementById('termly-script');
      if (termly) termly.remove();

      const termlyCode = document.getElementById('termly-code-snippet-support');
      if (termlyCode) termlyCode.remove();

    }

  }
  /**
   * Add a listener for deep links and redirect to the appropriate page.
   * TODO: Remove event listener when not opened as an app
   */
  private addDeepLinkRedirect() {

    if (!isPlatformBrowser(this.platformId)) return;

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split(".app").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });

  }
  /**
   * Remove the menu on certain routes like login and registration
   */
  private startAppMenuHandler() {

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event) => {

      if (isPlatformBrowser(this.platformId) && this.isRouteWithoutMenu(event)) {

        this.showNavigation.set(false);

      } else {

        this.showNavigation.set(true);

      }

    });

  }
  private isRouteWithoutMenu(event: NavigationEnd) {

    let parsedUrl = event.url.replace("/", "");

    return parsedUrl === this.paths.Login || parsedUrl === this.paths.Registration;

  }
  private styleAndroidStatusBar() {

    // Native StatusBar available
    if (isPlatformBrowser(this.platformId) && Capacitor.getPlatform() === 'android' && Capacitor.isPluginAvailable('StatusBar')) {

      // Tint statusbar color
      StatusBar.setBackgroundColor({
        color: "#EAF205"
      });
      StatusBar.setStyle({
        style: Style.Light
      });

    }

  }
  private checkForNewVersion() {

    this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => window.location.reload())
    );
  }
}
