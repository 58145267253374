import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Injectable, ElementRef, OnDestroy, ViewChild, AfterViewInit, PLATFORM_ID, Inject, OnInit, ChangeDetectorRef, signal, Signal } from '@angular/core';
import { DisplayedImage } from '../interfaces/my-profile.interfaces';
import { v4 as uuidv4 } from 'uuid';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Paths } from 'src/app/app-routing.module';
import * as e from 'express';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { validValue } from 'src/app/core/validators/value.validator';
import { AuthService } from 'src/app/core/services/auth.service';
import { ImageCropperComponent } from '../components/my-profile/image-cropper/image-cropper.component';
import { validAge } from 'src/app/core/validators/age.validator';
import { breakpoint } from './user-list.service';
import { PlacePrediction, PlaceSelection } from 'src/app/shared/components/location-select/location-select.component';

const residenceInvalidValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const latitude = control.get('latitude');
  const longitude = control.get('longitude');

  return latitude.invalid || longitude.invalid ? { residenceInvalid: true } : null;
};

@Injectable({
  providedIn: 'root'
})
export class MyProfileDataService implements OnDestroy {

  authSubscription;
  initMyProfileTourStarted = signal(false);
  myProfileDataFetched = signal(false);
  myProfileImageFetched = signal(false);
  triggerImageChangeDetection: Subject<boolean> = new Subject<boolean>();
  triggerProfileImageChangeDetection: Subject<boolean> = new Subject<boolean>();

  canvasWidth: number = null;
  canvasHeight: number = null;

  myProfileForm: FormGroup = new FormGroup({
    'additionalRemarksDiseases': new FormControl(null, Validators.maxLength(350)),
    'allergies': new FormControl(null, [validValue([null, "0", "true", "false"])]),
    'age': new FormControl(null, Validators.maxLength(50)),
    'bloodDonationDocuments': new FormControl(null, [validValue([null, "0", "true", "false"])]),
    'bloodGroup': new FormControl(null, [validValue([null, "-", "A", "AB", "B", "0"])]),
    'childAllowedToMeetDonator': new FormControl(null, [validValue([null, "0", "true", "false"])]),
    'coParenting': new FormControl(null, [validValue([null, "0", "true", "false"])]),
    'contactToDonatorNotWanted': new FormControl(null, [validValue([null, "0", "true", "false"])]),
    'currentTestForSTDs': new FormControl(null, [validValue([null, "0", "yes", "no", "letsDiscussTogether"])]),
    'dateOfBirth': new FormControl(null, [Validators.required, validAge()]),
    'description': new FormControl(null, [Validators.maxLength(4000)]),
    'defaultProfilePicture': new FormControl('assets/icons/placeholder-female.svg', Validators.required),
    'donationForPossibleSibling': new FormControl(null, [validValue([null, "0", "true", "false"])]),
    'estimatedCosts': new FormControl(null, [Validators.pattern('[0-9 ]*'), Validators.maxLength(6)]),
    'experiencedDonator': new FormControl(null, [validValue([null, "0", "yes", "no"])]),
    'eyeColor': new FormControl(null, [validValue([null, "0", "brown", "green", "blue", "grey", "other"])]),
    'glasses': new FormControl(null, [validValue([null, "0", "true", "false"])]),
    'hairColor': new FormControl(null, [validValue([null, "0", "white", "grey", "blond", "red", "brown", "black"])]),
    'height': new FormControl(null/**, [Validators.pattern('[0-9 ]*'), Validators.min(0), Validators.max(272)]**/),
    'hereditaryDiseases': new FormControl(null, [validValue([null, "0", "true", "false"])]),
    'highestDegree': new FormControl(null, [Validators.maxLength(50)]),
    // 'images': new FormControl([]),//, { asyncValidators: [mimeType] }),
    'interestedInDonation': new FormControl("null", [validValue([null, "true", "false"])]),
    'justCupMethod': new FormControl(null, [validValue([null, "0", "true", "false"])]),
    'languages': new FormControl(null, [Validators.maxLength(200)]),
    'latitude': new FormControl(null, Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')),
    'longitude': new FormControl(null, Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')),
    'majorHealthCertificate': new FormControl(null, [validValue([null, "0", "true", "false"])]),
    'methods': new FormControl(null, [validValue([null, "0", "cupMethod", "natural", "discussTogether"])]),
    'name': new FormControl(null, [Validators.maxLength(50)]),
    'numberOfChildrenByDonation': new FormControl(null, [Validators.pattern('[0-9 ]*'), Validators.min(0), Validators.max(999)]),
    'numberOfChildrenWithoutDonation': new FormControl(null, [Validators.pattern('[0-9 ]*'), Validators.min(0), Validators.max(999)]),
    'profession': new FormControl(null, [Validators.maxLength(60)]),
    'residence': new FormControl(null, [Validators.required, Validators.maxLength(200)]),
    'rhesusFactor': new FormControl(null, [validValue([null, "null", "rhd+", "rhd-"])]),
    'rhesusFormula': new FormControl(null, [Validators.maxLength(10)]),
    'role': new FormControl(null, [validValue(["Spender", "Empfaenger", "Co-Parenting"])]),
    'skinType': new FormControl("0", [validValue([null, "0", "skinType_1", "skinType_2", "skinType_3", "skinType_4", "skinType_5", "skinType_6"])]),
    'spermiogram': new FormControl(null, [validValue([null, "notSpecified", "available", "notAvailable", "uponRequest"])]),
    'stepchildAdoptionAllowed': new FormControl(null, [validValue([null, "0", "true", "false"])]),
    'stepchildAdoptionRequired': new FormControl(null, [validValue([null, "0", "true", "false"])]),
    'weight': new FormControl(null, [Validators.pattern('[0-9 ]*'), Validators.min(0), Validators.maxLength(3)])
  }, { validators: residenceInvalidValidator });
  myImagesForm: FormGroup = new FormGroup({
    'images': new FormControl([]),//, { asyncValidators: [mimeType] }),
  });

  // Autocomplete
  userAddress: string = '';
  userLatitude: any = null;
  userLongitude: any = null;

  croppingProfilePicture: boolean = null;
  profileImage = null;
  /**
  * Since the form just saves the name of the image this field is required to save the associated image files. These will fetched
  * asynchronously as soon as the user data, and so the image names, are available.
  */
  images = [];
  displayedImage: DisplayedImage = { name: "empty", image: "" };
  imageToCrop: DisplayedImage = { name: "empty", image: "" };
  waitingForImageUpload: boolean = false;
  $deleteCropperInstance: Subject<boolean> = new Subject();
  $detectChanges: Subject<boolean> = new Subject();
  initialWeight: string = null;

  private subscriptions: Subscription = new Subscription();

  private message1 = $localize`:Error Message@@formInvalidSubmitProfileComponent:Fehler: Bitte prüfen sie ihre Eingaben.`;
  private message2 = $localize`:Error Message@@sthWentWrongSubmitProfileComponent:Etwas ist schief gelaufen. Einfach nochmal probieren.`;
  private message3 = $localize`:Error Message@@savedSubmitProfileComponent:gespeichert`;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router,
    private cookieService: SsrCookieService,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) public document: Document
  ) {

  }
  startProfileService() {

    if (this.authService.getIsAuthenticated()) this.loadProfile();

    if (!this.authSubscription) {

      this.subscriptions.add(this.authService.getAuthStatusListener().subscribe({
        next: isAuthenticated => {

          if (isAuthenticated) this.loadProfile();
          else this.resetProfile();

        }
      }));

    }

  }
  ngOnDestroy() {

    try {

      if (isPlatformBrowser(this.platformId)) {

        this.dialog.closeAll();

        var img = document.getElementById("my-profile-profile-picture");
        if (img) img.setAttribute("src", "");

        // The next two lines are needed to let the input load the same file again and again
        // Note: in this way the onload event gets always triggered!
        let input = document.getElementById("my-profile-image-input") as HTMLInputElement;
        input.onclick = () => { input.value = null };

        this.subscriptions.unsubscribe();

      }

    } catch (error) {

      console.log(error);

    }

  }
  /**
   * Loads all data needed for the myProfile form. Additionally
   * registers an auth status listener so the profile form gets
   * resetted on logout and loaded on a new login. This is needed
   * because the component won´t be destroyed after the initial
   * load (routing back and forth does not trigger the constructor)
   */
  async loadProfile() {

    if (isPlatformBrowser(this.platformId) && this.authService.getIsAuthenticated()) {

      this.http.get(environment.apiUrl + "myProfile", { withCredentials: true })
        .subscribe(response => {

          this.setProfileForm(response);
          this.getImages(this.myImagesForm.value.images);
          this.myProfileImageFetched.set(true);

        });

    }

  }
  private resetProfile(): void {

    this.authSubscription = null;

    this.canvasWidth = null;
    this.canvasHeight = null;

    this.myProfileForm.reset();
    this.myImagesForm.reset();

    this.userAddress = '';
    this.userLatitude = null;
    this.userLongitude = null;

    this.images = [];
    this.displayedImage = { name: "empty", image: "" };
    this.imageToCrop = { name: "empty", image: "" };
    this.waitingForImageUpload = false;

  }
  setProfileForm(formData): void {

    let longitude, latitude;
    let userAge = formData["user"].age + String.fromCharCode(8201) + $localize`:@@ageForeignProfilePage:Jahre`;

    if (formData["user"].location == null || !formData["user"].location.hasOwnProperty('coordinates')) {
      longitude = null;
      latitude = null;
    } else {
      longitude = formData["user"].location.coordinates[0];
      latitude = formData["user"].location.coordinates[1];
    }

    this.myProfileForm.patchValue({
      additionalRemarksDiseases: formData["user"].additionalRemarksDiseases,
      age: userAge,
      allergies: formData["user"].allergies,
      bloodDonationDocuments: formData["user"].bloodDonationDocuments,
      bloodGroup: formData["user"].bloodGroup,
      childAllowedToMeetDonator: formData["user"].childAllowedToMeetDonator,
      coParenting: formData["user"].coParenting == "null" ? "0" : formData["user"].coParenting,
      contactToDonatorNotWanted: formData["user"].contactToDonatorNotWanted,
      currentTestForSTDs: formData["user"].currentTestForSTDs,
      dateOfBirth: new Date(formData["user"].dateOfBirth),
      description: formData["user"].description,
      defaultProfilePicture: formData["user"].defaultProfilePicture,
      donationForPossibleSibling: formData["user"].donationForPossibleSibling,
      estimatedCosts: formData["user"].estimatedCosts,
      experiencedDonator: formData["user"].experiencedDonator,
      eyeColor: formData["user"].eyeColor,
      glasses: formData["user"].glasses,
      hairColor: formData["user"].hairColor,
      height: formData["user"].height,
      hereditaryDiseases: formData["user"].hereditaryDiseases,
      highestDegree: formData["user"].highestDegree,
      interestedInDonation: formData["user"].interestedInDonation,
      justCupMethod: formData["user"].justCupMethod,
      latitude: latitude,
      longitude: longitude,
      languages: formData["user"].languages,
      majorHealthCertificate: formData["user"].majorHealthCertificate,
      methods: formData["user"].methods,
      name: formData["user"].name,
      numberOfChildrenByDonation: formData["user"].numberOfChildrenByDonation,
      numberOfChildrenWithoutDonation: formData["user"].numberOfChildrenWithoutDonation,
      profession: formData["user"].profession,
      residence: formData["user"].residence,
      rhesusFactor: formData["user"].rhesusFactor,
      rhesusFormula: formData["user"].rhesusFormula,
      role: formData["user"].role == null ? "0" : formData["user"].role, // null is not allowed anymore so if a null occurs it will be converted
      skinType: formData["user"].skinType,
      spermiogram: formData["user"].spermiogram,
      stepchildAdoptionAllowed: formData["user"].stepchildAdoptionAllowed,
      stepchildAdoptionRequired: formData["user"].stepchildAdoptionRequired,
      weight: formData["user"].weight,
    })

    this.myImagesForm.setValue({ images: formData["user"].images });
    this.myProfileForm.markAsUntouched();
    this.myProfileForm.updateValueAndValidity();
    this.triggerImageChangeDetection.next(true);
    this.myProfileDataFetched.set(true);

    if (!this.isProfileInitialized()) {

      this.router.navigate(['/' + Paths.InitProfile]);

    }

  }
  getLocationInitValue() {

    if (this.myProfileForm.value.residence && this.myProfileForm.value.latitude && this.myProfileForm.value.longitude) {

      let place = new PlacePrediction(
        this.myProfileForm.value.residence,
        null,
        { main_text: "", secondary_text: "" }
      );

      return place;

    } else return null;


  }
  getErrorMessage(formControlName: string): string {

    if (this.myProfileForm.controls[formControlName].value == null) {

      if (formControlName == "dateOfBirth") return 'Bitte gib dein Geburtsdatum an.';

    } else if (this.myProfileForm.controls[formControlName] && !this.myProfileForm.controls[formControlName].valid) {

      if (formControlName == "dateOfBirth") {

        let today = new Date();
        let date = new Date(this.myProfileForm.controls[formControlName].value);

        if (!date.getTime()) return "Ungültiges Datumsformat."
        else if (today.getTime() - date.getTime() < 568024668000) return "Du musst mindestens 18 Jahre alt sein."
        else if (today.getTime() - date.getTime() > 3155692600000.1) return "Darf man an dieser Stelle ein Lüge unterstellen?"

      }

    }

    return '';

  }
  handleAddressChange(address: PlaceSelection) {

    console.log("Address changed");
    console.log(address);

    if (address.location == undefined || address.location.lat == undefined || address.location.lng == undefined) {

      this.userAddress = null;
      this.userLatitude = null;
      this.userLongitude = null;

    } else {

      this.userAddress = address.description;
      this.userLatitude = address.location.lat;
      this.userLongitude = address.location.lng;

    }

    if (this.validAddress()) {

      this.myProfileForm.patchValue({ residence: this.userAddress });
      this.myProfileForm.get('residence').updateValueAndValidity();
      this.myProfileForm.patchValue({ latitude: this.userLatitude });
      this.myProfileForm.get('latitude').updateValueAndValidity();
      this.myProfileForm.patchValue({ longitude: this.userLongitude });
      this.myProfileForm.get('longitude').updateValueAndValidity();

      this.myProfileForm.markAsDirty();
      this.myProfileForm.updateValueAndValidity();
    }



  }
  validAddress() {

    const validAddress = (this.userAddress != null && typeof this.userAddress == "string" && this.userAddress.length > 0 && this.userAddress.length < 201);
    const validLatitude = (this.userLatitude != null && typeof this.userLatitude == "number" && this.userLatitude >= -90 && this.userLatitude <= 90);
    const validLongitude = (this.userLongitude != null && typeof this.userLongitude == "number" && this.userLongitude >= -180 && this.userLongitude <= 180);

    return validAddress && validLatitude && validLongitude;

  }
  deleteAddress() {

    this.myProfileForm.patchValue({ residence: null });
    this.myProfileForm.get('residence').updateValueAndValidity();
    this.myProfileForm.patchValue({ latitude: null });
    this.myProfileForm.get('latitude').updateValueAndValidity();
    this.myProfileForm.patchValue({ longitude: null });
    this.myProfileForm.get('longitude').updateValueAndValidity();

    setTimeout(() => { }, 1);

  }
  private async getImages(imageReferences: Object[]) {

    let index = 0;
    this.images = [];

    let lastFetchSuccessful: boolean;

    if (imageReferences) {

      for (let reference of imageReferences) {

        if (reference["isProfilePicture"]) {

          await this.getImage(reference, false, true).then((res: boolean) => lastFetchSuccessful = res);

        } else {

          if (index == 0) await this.getImage(reference, true).then((res: boolean) => lastFetchSuccessful = res);
          else if (!lastFetchSuccessful) await this.getImage(reference, true).then((res: boolean) => lastFetchSuccessful = res);
          else lastFetchSuccessful = await this.getImage(reference).then((res: boolean) => lastFetchSuccessful = res);

          index++;

        }

      }

    }

  }
  private async getImage(
    imageReference: Object,
    displayImage: boolean = false,
    isProfilePicture: boolean = false): Promise<boolean> {

    let params: HttpParams = new HttpParams().set("imageReference", JSON.stringify(imageReference));

    if (!isPlatformBrowser(this.platformId)) return new Promise((resolve, reject) => { resolve(false) });
    else return new Promise((resolve, reject) => {

      this.http.get(environment.apiUrl + "getImage", { params, responseType: 'blob', withCredentials: true }).subscribe(
        response => {

          if (isProfilePicture) {

            this.profileImage = { name: imageReference["reference"], image: URL.createObjectURL(response) };
            this.triggerProfileImageChangeDetection.next(true);
            this.$detectChanges.next(true);

          } else {

            const image = { name: imageReference["reference"], image: URL.createObjectURL(response) };

            if (displayImage) {

              this.displayedImage = image;
              this.$detectChanges.next(true);

            }
            /**
             * FIXME: The if clause is needed because the images, when used in the my profile component,
             * are loaded multiple times.
             */
            if (this.images.map(img => img.name).indexOf(image.name) == -1) this.images.push(image);

            this.triggerImageChangeDetection.next(true);

          }

          resolve(true);

        },
        err => {

          resolve(false);

        });

    })

  }
  initImageLoader(myProfileImagesContainer: ElementRef): void {

    let that = this;

    // The following line is needed to be sure the cropper does not reopen after leaving the component
    // and coming back
    document.getElementById("my-profile-profile-picture").setAttribute("src", "");

    function openCropperDialog() {


      let dialogRef = that.dialog.open(ImageCropperComponent, { panelClass: 'cropper-dialog-container' });

      that.subscriptions.add(dialogRef.afterClosed().subscribe(canvas => {

        // Remove overlfowY = "clip" which is added when the cropper opens
        document.getElementById("rs-body").style.overflowY = "";
        that.$deleteCropperInstance.next(true);

        let croppedCanvas = canvas as HTMLCanvasElement;

        if (croppedCanvas) croppedCanvas.toBlob(blob => {

          // the uploads function works with a file so the blob needs to be converted
          let blobFile: any = blob;
          let date = new Date();
          blobFile.lastModifiedDate = date;
          blobFile.name = uuidv4();

          that.uploadImage(blobFile);
        });

      }));

    };

    // The next two lines are needed to let the input load the same file again and again
    // Note: in this way the onload event gets always triggered!
    let input = document.getElementById("my-profile-image-input") as HTMLInputElement;
    input.onclick = () => { input.value = null };

    var img;

    img = document.getElementById("my-profile-profile-picture");

    if (img) img.onload = openCropperDialog;

    this.canvasWidth = myProfileImagesContainer.nativeElement.clientWidth;
    this.canvasHeight = myProfileImagesContainer.nativeElement.clientHeight;

  }
  getDefaultProfilePicture() {

    return this.myProfileForm.controls.defaultProfilePicture.value;

  }
  public async deleteImage() {

    let params: HttpParams = new HttpParams().set("imageReference", JSON.stringify(this.displayedImage));

    this.http.post(environment.apiUrl + "deleteImage", { image: JSON.stringify(this.displayedImage) }, { responseType: 'text', withCredentials: true }).subscribe(response => {

      let currentIndex = 0;

      for (let image of this.images) {

        if (image.name == this.displayedImage.name) {

          this.images.splice(currentIndex, 1);

          if (currentIndex > 0) {
            this.displayedImage = this.images[currentIndex - 1];
            break;
          } else {
            if (this.images.length > 0) this.displayedImage = this.images[currentIndex];
            else this.displayedImage = { name: "empty", image: "" };
            break;
          }
        }

        currentIndex++;

      }

    });

  }
  public onImagePicked(event: Event) {

    const file = (event.target as HTMLInputElement).files[0];

    if (file.size > 5000000) this._snackBar.open("Dateigröße übersteigt Maximum. Bitte reduzieren Sie die Bildgröße.", null, {
      duration: 3000
    });
    else {

      let image = { name: "test", image: URL.createObjectURL(file) };

      /**
       * Do not store the blob after cropping is finished.
       *
       * TODO: Revoke after cropping is finished and not before the
       * next image is loaded.
       */
      if (this.imageToCrop.name != "") {

        let image;

        image = document.getElementById("my-profile-profile-picture") as HTMLImageElement;

        document.getElementById("rs-body").style.overflowY = "clip";

        URL.revokeObjectURL(image.src);

      }

      // set the displayed image so the image is drawn (not visible!) and the onload event gets
      // triggered so the cropper dialog opens
      this.imageToCrop = image;

    }

  }
  public async uploadImage(file: File) {

    const postData = new FormData();
    // Add pp to the name if the image is a profile picture. Needed for backend processing
    postData.append("image", file, this.croppingProfilePicture ? "pp" + file.name : file.name);
    this.waitingForImageUpload = true;

    this.http.post<{ message: string, name: string }>(environment.apiUrl + "addProfilePicture", postData, { withCredentials: true }).subscribe(
      response => {

        this.waitingForImageUpload = false;

        if (response.message != "Image added") {

          this._snackBar.open("Upload fehlgeschlagen.");

          if (this.images.length == 0) this.displayedImage = null;
          else {

            try {

              this.displayedImage = this.images[0];

            } catch (error) {

            }

          }

        } else {

          let images = this.myImagesForm.value.images;
          const image = { name: response.name, image: URL.createObjectURL(file) };

          if (!images) images = [];

          images.push({ reference: response.name, private: false, sharedUsers: [], isProfilePicture: this.croppingProfilePicture });
          this.myImagesForm.patchValue({ images: images });
          this.myImagesForm.get('images').updateValueAndValidity();

          // If the image is a profile picture remove all other profile pictures
          if (this.croppingProfilePicture) {

            this.profileImage = image;
            this.triggerProfileImageChangeDetection.next(true);

          } else {

            this.images.push(image);
            this.displayedImage = image;

          }

        }

        this.$detectChanges.next(true);
        this.triggerImageChangeDetection.next(true);

      })

  }
  submit() {

    if (this.myProfileForm.valid) {

      this.http.post(
        environment.apiUrl + "updateUserData",
        { "form": JSON.stringify(this.myProfileForm.value) },
        { observe: "response", withCredentials: true }
      ).subscribe(res => {

        let verticalPos = 'top';

        if (window.innerWidth > breakpoint.mobile) verticalPos = 'bottom';

        if (res.status == 200) {

          this._snackBar.open(this.message3, null, {
            verticalPosition: verticalPos as any,
            duration: 3000
          });
          this.myProfileForm.markAsPristine()
          this.$detectChanges.next(true);

        } else this._snackBar.open(this.message2, null, {
          verticalPosition: verticalPos as any,
          duration: 3000
        });

      })

    } else {

      this._snackBar.open(this.message1, null, {
        duration: 3000
      });

    }

  }
  submitInitProfile() {

    if (this.isProfileInitialized()) {

      const role = this.myProfileForm.controls.role.value;
      const defaultProfilePicture = this.myProfileForm.controls.defaultProfilePicture.value;
      const residence = this.myProfileForm.controls.residence.value;
      const latitude = this.myProfileForm.controls.latitude.value;
      const longitude = this.myProfileForm.controls.longitude.value;

      this.http.post(
        environment.apiUrl + "initUserData",
        {
          "role": role,
          "defaultProfilePicture": defaultProfilePicture,
          "residence": residence,
          "latitude": latitude,
          "longitude": longitude,
        },
        { observe: "response", withCredentials: true }
      ).subscribe(res => {

        if (res.status == 200) {

          this._snackBar.open(this.message3, null, {
            duration: 3000
          });
          this.myProfileForm.markAsUntouched()
          this.$detectChanges.next(true);

          this.router.navigate(['/' + Paths.MyProfile]);

        } else this._snackBar.open(this.message2, null, {
          duration: 3000
        });

      });

    } else {

      this._snackBar.open(this.message1, null, {
        duration: 3000
      });

    }

  }
  isProfileInitialized(): boolean {

    if (this.myProfileForm.controls.role.valid &&
      this.myProfileForm.controls.defaultProfilePicture.valid &&
      this.myProfileForm.controls.residence.valid &&
      this.myProfileForm.controls.latitude.valid &&
      this.myProfileForm.controls.longitude.valid) return true;

    return false;

  }
  deleteOldProfilePicture() {

    if (this.profileImage) {

      let params: HttpParams = new HttpParams().set("imageReference", JSON.stringify(this.profileImage));

      this.http.post(environment.apiUrl + "deleteImage", { image: JSON.stringify(this.profileImage) }, { responseType: 'text', withCredentials: true }).subscribe(response => {

        this.profileImage = null;

      });

    }

  }
  public getProfileId(): string {

    return this.cookieService.get("rs-my-id");

  }
}
