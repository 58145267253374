<form>
  <mat-form-field style="width: 100%;" floatLabel="always">
    <mat-label i18n="@@locations-select__label">Ortsangabe</mat-label>
    <input #input type="text" [placeholder]="" matInput [formControl]="placesControl" [matAutocomplete]="auto" autoSelectActiveOption="true"
       (keyup)="suggestPlaces($event)">

    <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onOptionSelected($event)">

      @for (suggestion of suggestions; track suggestion) {
      <mat-option [value]="suggestion">
        @if (suggestion.structured_formatting && suggestion.structured_formatting.main_text) {
        <span style="font-weight: 600;">{{suggestion.structured_formatting.main_text}}</span>
        }
        @if (suggestion.structured_formatting &&
        suggestion.structured_formatting.main_text &&
        suggestion.structured_formatting.secondary_text) {
        <span>&thinsp;/&thinsp;</span>
        }
        @if (suggestion.structured_formatting && suggestion.structured_formatting.secondary_text) {
        <small>{{suggestion.structured_formatting.secondary_text}}</small>
        }
        @if (!suggestion.structured_formatting || !suggestion.structured_formatting.main_text) {
        <span>{{suggestion.description}}</span>
        }
      </mat-option>
      }
    </mat-autocomplete>

  </mat-form-field>

</form>
