<div id="splash-screen-container">

  <mat-spinner  style="top: 78px;" diameter="95" strokeWidth="6" ></mat-spinner>
  <mat-icon id="splash-screen-icon" svgIcon="rs-logo-purple" inline="true"></mat-icon>

  <!-- <mat-progress-bar mode="determinate" [value]="loadingProgress()"></mat-progress-bar> -->

  @if(this.infoText){
  <span style="display: flex; flex-direction: row; padding: 20px; align-items: end; margin-bottom: 150px;">
    <h1 style="margin:0 6px 0 0 !important">{{this.infoText}}</h1>
  </span>
  }

</div>
