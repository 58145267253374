<div id="sidenav" [ngClass]="{'fill': fill}">

  <div id="sidenav-heading" routerLink="/">
    <span style="height: 21px;">
      <mat-icon svgIcon="rs-logo-purple" inline="true"></mat-icon>
    </span>
    <span id="sidenav-heading-text">rattlestork</span>
  </div>


  <div id="sidenav-menu-wrapper">
    <div id="sidenav-menu-container">

      <a class="sidenav-button tablet-laptop-item" (click)="onClick()" mat-button [routerLink]="['/' + paths.Home]"
        [class.active]="appService.currentURLPath() == paths.Home">

        <div class="flex-row">
          <mat-icon svgIcon="rs-logo-purple"
            style="height: 36px; width: 15px; position: relative; top: -5px; margin-right: 15px;"
            inline="true"></mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | Home link in the sidenav@@sidenav-link-home">Home</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider class="tablet-laptop-item"></mat-divider>

      <a class="sidenav-button tablet-laptop-item" (click)="onClick()" mat-button [routerLink]="['/' + paths.Users]"
        [class.active]="appService.currentURLPath() == paths.Users">

        <div class="flex-row">
          <mat-icon svgIcon="rs-search-person"
            style="height: 36px; width: 15px; position: relative; top: -5px; margin-right: 15px;"
            inline="true"></mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | Search link in the sidenav@@sidenav-link-search">Suche</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider class="tablet-laptop-item"></mat-divider>

      <a class="sidenav-button tablet-laptop-item" (click)="onClick()" mat-button [routerLink]="['/' + paths.Chat]"
        [class.active]="appService.currentURLPath() == paths.Chat">

        <div class="flex-row">
          <mat-icon svgIcon="rs-icon-mail"
            style="height: 36px; width: 15px; position: relative; top: -5px; margin-right: 15px;"
            inline="true"></mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | Chat link in the sidenav@@sidenav-link-chat">Chat</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider class="tablet-laptop-item"></mat-divider>

      <a class="sidenav-button tablet-laptop-item" (click)="onClick()" mat-button [routerLink]="['/' + paths.MyProfile]"
        [class.active]="appService.currentURLPath() == paths.MyProfile">

        <div class="flex-row">
          <mat-icon svgIcon="rs-icon-person"
            style="height: 36px; width: 15px; position: relative; top: -5px; margin-right: 15px;"
            inline="true"></mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | My profile link in the sidenav@@sidenav-link-my-profile">Profil</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider class="tablet-laptop-item" style="margin-bottom: 45px;"></mat-divider>

      <div class="sidenav-section-label"
        i18n="Heading Sidenav | Heading of the help section in the sidenav@@sidenav-heading-help-section">Hilfe</div>

      <a class="sidenav-button" (click)="onClick()" mat-button [routerLink]="['/' + paths.BlogLandingPage]"
        [class.active]="appService.currentURLPath() == paths.BlogLandingPage">

        <div class="flex-row">
          <mat-icon svgIcon="rs-icon-blog"
            style="height: 36px; width: 15px; position: relative; top: -4px; margin-right: 15px;"
            inline="true"></mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | Blog link in the sidenav@@sidenav-link-blog-landing-page">Blog</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider></mat-divider>

      <a class="sidenav-button" (click)="onClick()" mat-button [routerLink]="['/' + paths.Contact]"
        [class.active]="appService.currentURLPath() == paths.Contact">

        <div class="flex-row">
          <mat-icon svgIcon="rs-icon-kontakt"
            style="height: 36px; width: 15px; position: relative; top: -5px; margin-right: 15px;"
            inline="true"></mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | Contact link in the sidenav@@sidenav-link-contact">Kontakt</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider style="margin-bottom: 45px;"></mat-divider>

      <div class="sidenav-section-label"
        i18n="Sidenav Account | Heading of the account section in the sidenav@@sidenav-heading-account-sectionv2">Konto</div>

      <a class="sidenav-button" (click)="onClick()" mat-button [routerLink]="['/' + paths.Settings]">

        <div class="flex-row">
          <mat-icon svgIcon="rs-icon-settings"
            style="height: 36px; width: 15px; position: relative; top: -4px; margin-right: 15px;"
            inline="true"></mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | Settings link in the sidenav@@sidenav-link-settings">Einstellungen</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider style="margin-bottom: 45px;"></mat-divider>


      <div class="sidenav-section-label"
        i18n="Sidenav Legal | Heading of the legal section in the sidenav@@sidenav-heading-legal-sectionv2">Rechtliches</div>


      <a class="sidenav-button" (click)="onClick()" mat-button [routerLink]="['/' + paths.Impressum]"
        [class.active]="appService.currentURLPath() == paths.Impressum">

        <div class="flex-row">
          <mat-icon svgIcon="rs-icon-impressum"
            style="height: 36px; width: 15px; position: relative; top: -5px; margin-right: 15px;"
            inline="true"></mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | Impressum link in the sidenav@@sidenav-link-impressum">Impressum</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider></mat-divider>

      <a class="sidenav-button" (click)="onClick()" mat-button [routerLink]="['/' + paths.TermsOfUse]"
        [class.active]="appService.currentURLPath() == paths.TermsOfUse">

        <div class="flex-row">
          <mat-icon
            style="transform: scale(0.8); position: relative; left: -4px; margin-right: 5px; top: 1px;">add_task</mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | Privacy link in the sidenav@@sidenav-link-terms-of-use">Terms Of Use</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider></mat-divider>

      <a class="sidenav-button" (click)="onClick()" mat-button [routerLink]="['/' + paths.DataProtection]"
        [class.active]="appService.currentURLPath() == paths.DataProtection">

        <div class="flex-row">
          <mat-icon svgIcon="rs-icon-datenschutz"
            style="height: 36px; width: 15px; position: relative; top: -5px; margin-right: 15px;"
            inline="true"></mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | Privacy link in the sidenav@@sidenav-link-privacy">Datenschutz</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider></mat-divider>

      <a class="sidenav-button" (click)="onClick()" mat-button [routerLink]="['/' + paths.CookiePolicy]"
        [class.active]="appService.currentURLPath() == paths.CookiePolicy">

        <div class="flex-row">
          <mat-icon
            style="transform: scale(0.8); position: relative; left: -4px; margin-right: 5px; top: 1px;">cookie</mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | Cookie policy in the sidenav@@sidenav-link-cookie-policy">Cookie-Richtlinien</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider></mat-divider>

      <a class="sidenav-button" (click)="onClick()" mat-button [routerLink]="['/' + paths.EULA]"
        [class.active]="appService.currentURLPath() == paths.EULA">

        <div class="flex-row">
          <mat-icon
            style="transform: scale(0.8); position: relative; left: -4px; margin-right: 5px; top: 1px;">check</mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | AGB policy in the sidenav@@sidenav-link-agb-policy">EULA</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider></mat-divider>

      <a class="sidenav-button" (click)="onClick()" mat-button [routerLink]="['/' + paths.AcceptableUsePolicy]"
        [class.active]="appService.currentURLPath() == paths.AcceptableUsePolicy">

        <div class="flex-row">
          <mat-icon
            style="transform: scale(0.8); position: relative; left: -4px; margin-right: 5px; top: 1px;">handshake</mat-icon>
          <span class="sidenav-menu-link-label"
            i18n="Sidenav Link | AGB policy in the sidenav@@sidenav-link-agb-policy">Nutzungsbedingungen</span>
        </div>

        <mat-icon svgIcon="rs-icon-arrow-right" inline="true" class="sidenav__menu-entry__arrow-icon"></mat-icon>

      </a>

      <mat-divider style="margin-bottom: 45px;"></mat-divider>

      <!-- <a *ngIf="authService.getIsAuthenticated()" style="margin: 20px 0 0 30px;" class="sidenav-button" (click)="onLogout()"
    mat-button i18n="@@sidenav-7">Abmelden</a> -->

    </div>
  </div>



</div>
