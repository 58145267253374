import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID, Sanitizer, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';
import { get } from 'http';
import { IosStatusBarService } from '../../services/ios-status-bar.service';

@Component({
  selector: 'ios-status-bar',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule, CommonModule
  ],
  templateUrl: './ios-status-bar.component.html',
  styleUrl: './ios-status-bar.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class IosStatusBarComponent {

  heightAppBanner: number = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public iosStatusBarService: IosStatusBarService
  ) {

    if (Capacitor.getPlatform() === 'web') {
      this.showGetTheAppAd();
    }

  }
  showGetTheAppAd() {

    if (isPlatformBrowser(this.platformId) && sessionStorage.getItem('hideAppAds') !== 'true') {

      try {

        this.heightAppBanner = 50;
        const statusBarHeight = Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--height-status-bar-mobile').replace('px', ''));

        document.documentElement.style.setProperty('--height-get-the-app-banner', `${this.heightAppBanner}px`);
        document.documentElement.style.setProperty('--height-safe-area-top', `${this.heightAppBanner + statusBarHeight}px`);

        if(window) window.document.body.scrollTo({ top: 0, behavior: 'instant' });

      } catch (error) {

        console.error('error', error);

      }

    }

  }
  closeAppAdsContainer() {

      if (isPlatformBrowser(this.platformId)) {

        try {

          this.heightAppBanner = null;
          const statusBarHeight = Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--height-status-bar-mobile').replace('px', ''));

          document.documentElement.style.setProperty('--height-get-the-app-banner', `0px`);
          document.documentElement.style.setProperty('--height-safe-area-top', `${statusBarHeight}px`);

          sessionStorage.setItem('hideAppAds', 'true');

        } catch (error) {

          console.error('error', error);

        }

      }
  }
}
