import { AppRoutingModule, Paths } from 'src/app/app-routing.module';
import { Inject, Injectable, LOCALE_ID, PLATFORM_ID, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, Subscription } from 'rxjs';

// TODO: Add more SEO features

/**
 * At https://ogp.me/ is a nice list of some open graph protokol meta tags
 * worth implementing.
 */

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  public subscriptions = new Subscription();

  constructor(
    @Inject(DOCUMENT) public document: Document, // <- this is ssr friendly, see docs for more info
    @Inject(PLATFORM_ID) private platformId: Object,
    private rendererFactory: RendererFactory2,
    private title: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(LOCALE_ID) public locale: string,
    private appRoutingModule: AppRoutingModule,
  ) {

  }
  public startSEOHandler() {

    this.subscriptions.add(this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => {
        return route.data
      })
    )
      .subscribe((event) => {

        this.updateTitle(event['title']);
        this.updateOgUrl(event['ogUrl']);
        this.updateDescription(event['description']);

      }));

  }
  updateTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
  }

  updateOgUrl(url: string) {

    this.meta.updateTag({ property: 'og:url', content: url + this.document.documentElement.lang })

  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  /**
 * Inject the State into the bottom of the <head>
 */
  prepareForSEO() {

    this.addHrefLangLinks();
    // this.addCanonicalLink();

  }
  addHrefLangLinks() {

    try {

      this.removeOldHrefLangLinks();

      const renderer = this.rendererFactory.createRenderer(this.document, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      });

      let link;
      const head = this.document.head;
      const url = this.router.url.replace("/", "");
      const localeVariations = AppRoutingModule.routeRelations[url];

      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }

      /**
       * localeVariations is undefined in development mode
       */
      if (localeVariations === undefined) return;

      localeVariations.forEach((locale: string) => {

        link = renderer.createElement('link');

        renderer.setAttribute(link, 'rel', 'alternate');
        renderer.setAttribute(link, 'hreflang', locale);
        renderer.setAttribute(link, 'href', environment.apiUrl + locale + this.router.url);

        renderer.appendChild(head, link);

      });

      link = renderer.createElement('link');

      renderer.setAttribute(link, 'rel', 'alternate');
      renderer.setAttribute(link, 'hreflang', "x-default");
      renderer.setAttribute(link, 'href', environment.apiUrl + "en/" + Paths.SelectLanguage);

      renderer.appendChild(head, link);

    } catch (e) {
      console.error('Error within linkService : ', e);
    }

  }
  removeOldHrefLangLinks() {

    let hrefLangLinks: NodeList = this.document.querySelectorAll("link[rel='alternate']");

    if (hrefLangLinks.length > 0) {
      hrefLangLinks.forEach((link: HTMLLinkElement) => {

        link.parentElement.removeChild(link);

      });
    }

  }
  addCanonicalLink() {

    let domainName = environment.apiUrl;

    let tag: LinkDefinition = { rel: 'canonical', href: domainName + this.locale + this.router.url };

    try {

      // Check if there are already canonical links, and if so, remove them

      // The if clause is to prevent the error: 'Error within linkService :  ReferenceError: document is not defined'
      // The error just occurs when running the SSR, so we need to check if the document is defined
      // if (isPlatformBrowser(this.platformId)) {

      let canonicalLinks: NodeList = this.document.querySelectorAll("link[rel='canonical']");

      if (canonicalLinks.length > 0) {
        canonicalLinks.forEach((link: HTMLLinkElement) => {

          link.parentElement.removeChild(link);

        });
      }
      // }

      const renderer = this.rendererFactory.createRenderer(this.document, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      });

      const link = renderer.createElement('link');
      const head = this.document.head;

      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }

      Object.keys(tag).forEach((prop: string) => {
        return renderer.setAttribute(link, prop, tag[prop]);
      });

      // [TODO]: get them to update the existing one (if it exists) ?
      renderer.appendChild(head, link);

    } catch (e) {
      console.error('Error within linkService : ', e);
    }
  }
}

export type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};
