import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

export enum ThemeColors {
  color1 = "#A173BF", // violet
  color1Hover = "#76548C",
  color2 = "#EAF205", // yellow
  color2Hover = "#B9BF04",
  color3 = "#F25244", // error color
  color3Hover = "#BF4136",
  color4 = "#0D0D0D", // black
  color5 = "#404040",
  color6 = "#BFBFBF",
  color7 = "#F2F2F2",
  color8 = "#E6E6E6",
  white = "#ffffff"
}

@Injectable({
  providedIn: 'root'
})
export class IosStatusBarService {

  public readonly ThemeColors = ThemeColors;
  public readonly backgroundColorStatusBar = signal<string>(ThemeColors.color2);
  public readonly previousBackgroundColorStatusBar = signal<string>(ThemeColors.color2);

  constructor(@Inject(PLATFORM_ID) public platformId: string,) { }

  changeNativeStatusBarColor(color: string) {

    try {

      this.previousBackgroundColorStatusBar.set(this.backgroundColorStatusBar());
      this.backgroundColorStatusBar.set(color);

      // Native StatusBar available
      if (isPlatformBrowser(this.platformId) && Capacitor.getPlatform() === 'android' && Capacitor.isPluginAvailable('StatusBar')) {

        // Tint statusbar color
        StatusBar.setBackgroundColor({
          color: color
        });
        StatusBar.setStyle({
          style: Style.Light
        });

      }

    } catch (error) {

      console.error(error);

    }

  }
  resetStatusBarColor() {

    try {

      console.log('resetStatusBarColor');

      this.changeNativeStatusBarColor(this.previousBackgroundColorStatusBar());

    } catch (error) {

      console.error(error);

    }

  }
}
