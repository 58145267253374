import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppService } from "./app.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SEOService } from './core/services/seo.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BlockUserService } from './core/services/block-user.service';
import { TopBarComponent } from './core/components/nav-bar/nav-bar.component';
import { SplashScreenComponent } from './core/components/splash-screen/splash-screen.component';
import { IosStatusBarComponent } from './core/components/ios-status-bar/ios-status-bar.component';
import { IconRegistryModule } from './shared/icons/icon-registry.module';
import { WebsocketService } from './features/messaging/services/websocket.service';
import { AnalyticsService } from './core/services/analytics.service';
import { AuthService } from './core/services/auth.service';
import { MyProfileDataService } from './features/users/services/my-profile-data.service';
import { IosStatusBarService } from './core/services/ios-status-bar.service';
import { UserListService } from './features/users/services/user-list.service';
import { ChatService } from './features/messaging/services/chat.service';
import { AuthGuard } from './core/guards/auth.guard';
import { InitProfileGuard } from './features/users/components/init-profile/init-profile.guard';
import { AuthInterceptor } from './core/interceptors/auth-interceptor';
import { SplashScreenService } from './core/services/splash-screen.service';

const config: SocketIoConfig = {
  url: environment.websocketUrl, options: {
    withCredentials: false,
    transports: ['polling'],
  }
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AppRoutingModule,
    TopBarComponent,
    IosStatusBarComponent,
    IconRegistryModule,
    SplashScreenComponent,
    SocketIoModule.forRoot(config),
  ],
  exports: [
    AppRoutingModule,
    HttpClientModule,
    IconRegistryModule,
    CommonModule
  ],
  providers: [
    WebsocketService,
    AnalyticsService,
    AppService,
    AuthService,
    MyProfileDataService,
    IosStatusBarService,
    UserListService,
    SEOService,
    ChatService,
    BlockUserService,
    AuthGuard,
    InitProfileGuard,
    AuthInterceptor,
    SplashScreenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppRoutingModule.addLocalizedRoutes,
      multi: true,
      deps: [Router, LOCALE_ID, PLATFORM_ID],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private SplashScreenService: SplashScreenService) {
  }

}


