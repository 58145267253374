import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, NavigationCancellationCode, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { Paths } from 'src/app/app-routing.module';
import { MyProfileDataService } from "../../services/my-profile-data.service";

@Injectable()
export class InitProfileGuard {

  constructor(private myProfileDataService: MyProfileDataService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    const myProfielDataFetched = this.myProfileDataService.myProfileDataFetched();
    const isInitialized = this.myProfileDataService.isProfileInitialized();

    if (myProfielDataFetched && !isInitialized) {

      this.router.navigate([Paths.InitProfile])

      return false;

    } else return true;

  }

}
