import { inject, InjectionToken, isDevMode, NgModule, } from '@angular/core';
import { Routes, RouterModule, BaseRouteReuseStrategy, ActivatedRouteSnapshot, RouteReuseStrategy, Route, Router, PreloadAllModules, NoPreloading, NavigationError } from '@angular/router';
import { filter } from 'rxjs';
import { AppLanguageSelectGuard } from './shared/components/app-language-select/app-language-select.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { InitProfileGuard } from './features/users/components/init-profile/init-profile.guard';

/**
 * !!!!!!!!! IMPORTANT !!!!!!!!!
 *
 * Wenn die rechte Seite des Ausdrucks geändert wird, muss auch die
 * sitemap.xml angepasst werden.
 */
export enum Paths {
  Home = '',
  Login = 'login',
  Registration = 'registration',
  Users = 'users',
  MyProfile = 'myProfile',
  InitProfile = 'initProfile',
  Profile = 'profile',
  Chat = 'chat',
  Contact = 'contact',
  Impressum = 'impressum',
  Unsubscribe = 'unsubscribe',
  Settings = 'settings',
  ResetPassword = 'reset-password',
  ForgotPassword = 'forgot-password',
  GoPremium = 'go-premium',
  BlogLandingPage = 'blog',
  More = 'more',
  SelectLanguage = 'select-language',
  ReportUser = 'report-user',
  Workbench = 'workbench',
  DataProtection = 'datenschutz',
  TermsOfUse = 'TermsOfUse',
  AcceptableUsePolicy = 'AcceptableUsePolicy',
  CookiePolicy = 'CookiePolicy',
  EULA = 'EULA',
  ReturnPolicy = 'ReturnPolicy',

  BlogBechermethodeHeiminsemination = 'bechermethode',
  BlogSamenspendeLandXYZVersion1 = 'BlogSamenspendeLandXYZVersion1',
  BlogSamenspendeLandXYZVersion2 = 'BlogSamenspendeLandXYZVersion2',
  BlogWerdeSpenderLandXYZVersion1 = 'BlogWerdeSpenderLandXYZVersion1',
  BlogWerdeSpenderLandXYZVersion2 = 'BlogWerdeSpenderLandXYZVersion2',
  BlogKlapper = 'klapperstorch',
  BlogUeberlebenSpermien = 'UeberlebenSpermien',
  BlogSpermCramps = 'spermakrämpfe',
  BlogCoParenting = 'co-parenting',
  BlogPreEjaculate = 'lusttropfen',
  BlogSemenCongestion = 'samenstau',
  BlogSpermiogram = 'spermiogramm',
  BlogPid = 'pid',
  BlogVaccination = 'vaccination',
  BlogGelbkörperschwäche = 'gelbkörperschwäche',
  BlogIVF = 'ivf',
  BlogIcsi = 'icsi',
  BlogIUI = 'iui',
  BlogICI = 'ici',
  BlogArtificialInsemnation = 'artificial-insemnation',
  BlogLineaNigra = 'linea-nigra',
  BlogEinnistung = 'einnistung',
  BlogEizellenspende = 'eizellenspende',
  BlogZervix = 'Zervix',
  BlogOvulation = 'ovulation',
  BlogLhOvu = 'lh',
  BlogBrust = 'brust',
  BlogSurrogacy = 'surrogacy',
  BlogDiseases = 'diseases',
  BlogRefertilisierungMann = 'refertilisierung-mann',
  BlogRefertilisierungFrau = 'refertilisierung-frau',
  BlogKostenAI = 'kostenAI',
 

  BlogCostSpermBank = 'parasite-sperm-bank',
  BlogCostSpermBankCopy = 'parasite-kiwu',
  BlogBuerokratie = 'buerokratie',
  BlogZehnUnfruchtFrauen = 'ZehnUnfruchtFrauen',
  BlogPilleAbsetzen = 'PilleAbsetzen',
  BlogFehlgeburt = 'fehlgeburt',
  BlogFrühgeburt = 'frühgeburt',
  BlogMenstruationDespitePregnancy = 'menstruation-despite-pregnancy',
  BlogMutterpass = 'mutterpass',
  BlogChlamydia = 'chlamydia',
  BlogEmilia = 'emilia',
  BlogMenopause = 'Menopause',
  Blog10TipsAndTricks = '10-tips-and-tricks',
  BlogPCO = 'pco',
  BlogModAbst = 'modernisierung-abstammungsrecht',
  BlogSpermDonation = 'private-samenspende',
  BlogTurkeyBasterPregnancy = 'turkey-baster',
  BlogRisikofaktoren = 'ZehnUnfruchtMann',


}

// https://www.auroria.io/angular-route-reuse-strategy/
export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return (future.routeConfig === curr.routeConfig) || future.data.reuseComponent;
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutingModule.generalRoutes, {
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: NoPreloading,
      enableViewTransitions: true,
      enableTracing: false,
    })
  ],
  exports: [RouterModule],
  providers: [
    AppLanguageSelectGuard,
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy }
  ]
})
export class AppRoutingModule {

  static readonly generalRoutes: Routes = [
    {
      path: Paths.SelectLanguage,
      data: {
        title: $localize`:@@titleSelectLanguageRoutev:Sprache wählen`,
        description:
          $localize`:@@descriptionSelectLanguageRoutev2:Wähle die gewünschte Sprache aus.`,
        ogUrl: 'https://rattlestork.org/',
      },
      loadComponent: () => import('./shared/components/app-language-select/app-language-select.component').then(m => m.AppLanguageSelectComponent),
    },
    {
      path: Paths.More,
      data: {
        title: $localize`:@@titleSidenav:mehr`,
        description:
          $localize`:@@descriptionSidenav:mehr`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/sidenav/app-sidenav/app-sidenav.component').then(m => m.AppSidenavComponent),
    },
    {
      path: Paths.ResetPassword,
      data: {
        title: $localize`:@@titleResetPasswordRoute:Passwort zurücksetzen`,
        description:
          $localize`:@@descriptionResetPasswordRoute:Passwort zurücksetzen`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/reset-pwd/reset-pwd.component').then(m => m.ResetPwdComponent),
    },
    {
      path: Paths.ForgotPassword,
      data: {
        title: $localize`:@@titleForgotPasswordRoute:Passwort vergessen`,
        description:
          $localize`:@@descriptionForgotPasswordRoute:Passwort vergessen`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/forgot-pwd/forgot-pwd.component').then(m => m.ForgotPwdComponent),
    },
    {
      path: Paths.Settings,
      data: {
        title: $localize`:@@routingPathTitleSettingsv2:Einstellungen`,
        description:
          $localize`:@@routingPathDescriptionSettingsv2:Einstellungen`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/settings/settings.component').then(m => m.SettingsComponent),
      runGuardsAndResolvers: "always"
    },
    {
      path: Paths.BlogLandingPage,
      data: {
        title: $localize`:@@titleBlogLandingPageRoutev2:Blog RattleStork - Die Samenspende App`,
        description:
          $localize`:@@descriptionBlogLandingPageRoutev2:Hier findest du den Blog von RattleStork - Die Samenspende App`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blog-landing-page/blog-landing-page.component').then(m => m.BlogLandingPageComponent),
    },
    {
      path: Paths.Login,
      data: {
        title: $localize`:@@titleLoginPageRoutev2:Login RattleStork - Die Samenspende App`,
        description:
          $localize`:@@descriptionLoginPageRoutevsv2:Hier findest du den Login von RattleStork - Die Samenspende App`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/login/login.component').then(m => m.LoginComponent),
    },
    {
      path: Paths.Registration,
      data: {
        title: $localize`:@@titleRegistrationPageRoutev2:Registrierung RattleStork - Die Samenspende App`,
        description:
          $localize`:@@descriptionRegistrationPageRoutev2:Registriere dich jetzt auf RattleStork - Die Samenspende App`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/registration/registration.component').then(m => m.RegistrationComponent),
    },
    {
      path: Paths.Users,
      data: {
        title: $localize`:@@titleUserSearchPageRoute:Suche`,
        description:
          $localize`:@@descriptionUserSearchPageRoute:Suche`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./features/users/components/user-list/user-list.component').then(m => m.UserListComponent),
      canActivate: [AuthGuard, InitProfileGuard],
      runGuardsAndResolvers: "always"
    },
    {
      path: Paths.MyProfile,
      data: {
        title: $localize`:@@titleMyProfilePageRoute:Mein Profil`,
        description:
          $localize`:@@descriptionMyProfilePageRoute:Mein Profil`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./features/users/components/my-profile/myProfile.component').then(m => m.MyProfileComponent),
      canActivate: [AuthGuard, InitProfileGuard],
      runGuardsAndResolvers: "always",
    },
    {
      path: Paths.InitProfile,
      data: {
        title: $localize`:@@titleMyProfilePageRoute:Profil einrichten`,
        description:
          $localize`:@@descriptionMyProfilePageRoute:Profil einrichten`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./features/users/components/init-profile/init-profile.component').then(m => m.InitProfileComponent),
      canActivate: [AuthGuard],
      runGuardsAndResolvers: "always"
    },
    {
      path: Paths.Profile,
      data: {
        title: $localize`:@@titleProfilePageRoute:Profil`,
        description:
          $localize`:@@descriptionProfilePageRoute:Profil`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./features/users/components/foreign-profile/profile.component').then(m => m.ProfileComponent),
      canActivate: [AuthGuard, InitProfileGuard],
      runGuardsAndResolvers: "always"
    },
    {
      path: Paths.Chat,
      data: {
        title: $localize`:@@titleChatPageRoute:Chat`,
        description:
          $localize`:@@descriptionChatPageRoute:Chat`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./features/messaging/components/chat/chat.component').then(m => m.ChatComponent),
      canActivate: [AuthGuard, InitProfileGuard],
      runGuardsAndResolvers: "always"
    },
    {
      path: Paths.ReportUser,
      loadComponent: () => import('./features/users/components/report-user/report-user.component').then(m => m.ReportUserComponent)
    },
    {
      path: Paths.Contact,
      loadChildren: () => import('./core/components/footer/contact-service/contact-service-de.module').then(m => m.ContactServiceModule)
    },
    {
      path: Paths.GoPremium,
      data: {
        title: $localize`:@@titleGoPremiumRoutev2:Premiummitgliedschaft RattleStork - Die Samenspende App`,
        description:
          $localize`:@@descriptionGoPremiumRoutev2:Hier kannst du eine Premiummitgliedschaft abschließen.`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/go-premium/go-premium.component').then(m => m.GoPremiumComponent)
    },
    {
      path: Paths.DataProtection,
      data: {
        title: $localize`:@@titleDataProtectionv2:Datenschutz RattleStork - Die Samenspende App`,
        description:
          $localize`:@@descriptionDataProtectionv2:Hier findest du den Datenschutz von RattleStork - Die Samenspende App.`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/footer/data-protection/en/data-protection-en.component').then(m => m.DataProtectionEnComponent)
    },
    {
      path: Paths.TermsOfUse,
      data: {
        title: $localize`:@@titleTermsOfUsev2:Terms Of Use RattleStork - Die Samenspende App`,
        description:
          $localize`:@@descriptionTermsOfUseEnPagv2e:Hier findest du die Terms Of Use von RattleStork - Die Samenspende App`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/footer/terms-of-use/en/terms-of-use-en.component').then(m => m.TermsOfUseEnComponent)
    },
    {
      path: Paths.AcceptableUsePolicy,
      data: {
        title: $localize`:@@titleAcceptableUsePolicyEnPagev2:Nutzungsbedingungen RattleStork - Die Samenspende App`,
        description:
          $localize`:@@descriptionAcceptableUsePolicyEnPagev2:Hier findest du die Nutzungsbedingungen von RattleStork - Die Samenspende App`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/footer/acceptable-use-policy/en/acceptable-use-policy-en.component').then(m => m.AcceptableUsePolicyEnComponent)
    },
    {
      path: Paths.CookiePolicy,
      data: {
        title: $localize`:@@titleCookiePolicyEnPagev2:Cookie Policy RattleStork - Die Samenspende App`,
        description:
          $localize`:@@descriptionCookiePolicyEnPagv2e:Hier findest du die Cookie Policy von RattleStork RattleStork - Die Samenspende App`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/footer/cookie-policy/en/cookie-policy-en.component').then(m => m.CookiePolicyEnComponent)
    },
    {
      path: Paths.EULA,
      data: {
        title: $localize`:@@titleEULAEnPagev2:EULA RattleStork - Die Samenspende App`,
        description:
          $localize`:@@descriptionEULAEnPagev2:Hier findest du die EULA von RattleStork - Die Samenspende App`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/footer/EULA/en/EULA-en.component').then(m => m.EULAEnComponent)
    },
    {
      path: Paths.ReturnPolicy,
      data: {
        title: $localize`:@@titleReturnPolicyEnPagev2:Rückgaberecht RattleStork - Die Samenspende App`,
        description:
          $localize`:@@descriptionReturnPolicyEnPagev2:Hier findest du das Rückgaberecht von RattleStork - Die Samenspende App`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/footer/return-policy/en/return-policy-en.component').then(m => m.ReturnPolicyEnComponent)
    },
    {
      path: Paths.Home,
      data: {
        title: $localize`:@@titleHomeRoutev2:Die Samenspende App`,
        description:
          $localize`:@@descriptionHomeRoutev2:Du interessierst dich für eine Samenspende? Du möchtest Samenspender werden? Einfach die RattleStork App herunterladen!`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/home/home.component').then(m => m.HomeComponent),
      canActivate: [AppLanguageSelectGuard],
      runGuardsAndResolvers: "always",
    }
  ];
  static readonly germanRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/de/impressum.module').then(m => m.ImpressumModule)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Bechermethode: Schwanger werden ohne Sex - Heiminsemination",
        description: "Erfahren Sie, wie Heiminsemination funktioniert – flexibel, privat und erschwinglich, mit allen wichtigen Infos und Tipps.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Samenspende in Österreich: Rechtliche Grundlagen, Tipps und wie RattleStork helfen kann",
        description: "In Österreich bietet die Samenspende eine wertvolle Möglichkeit, Familienwünsche unterschiedlichster Konstellationen zu erfüllen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion2,
      data: {
        title: "Samenspende in Deutschland: Rechtliche Grundlagen, Tipps und wie RattleStork helfen kann",
        description: "Die Samenspende hat sich in Deutschland als wichtige Möglichkeit etabliert, Menschen mit unterschiedlichen Familienmodellen bei der Erfüllung ihres Kinderwunsches zu unterstützen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/BlogSamenspendeLandXYZVersion2/BlogSamenspendeLandXYZVersion2.component').then(m => m.BlogSamenspendeLandXYZVersion2Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Samenspender werden in Österreich: Alles, was Sie wissen müssen – mit RattleStork",
        description: " In Österreich Samenspender zu werden, ist eine besondere Möglichkeit, anderen Menschen bei der Erfüllung ihres Kinderwunsches zu helfen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion2,
      data: {
        title: "Samenspender werden in Deutschland: Alles, was Sie wissen müssen – mit RattleStork",
        description: "Samenspender zu werden ist in Deutschland eine besondere Möglichkeit, anderen Menschen ihren Traum von einer Familie zu ermöglichen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/BlogWerdeSpenderLandXYZVersion2/BlogWerdeSpenderLandXYZVersion2.component').then(m => m.BlogWerdeSpenderLandXYZVersion2Component)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Spermakrämpfe: Ein Internetmythos ohne medizinische Grundlage",
        description: "Das Internet ist ein Raum, in dem sich Informationen schnell verbreiten – darunter auch Begriffe, die seriös klingen, aber keiner fundierten Überprüfung standhalten.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/spermakrampf/spermakrampf.component').then(m => m.SpermakrampfDeComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: Ein moderner Ansatz für Familie und Erziehung",
        description: "Co-Parenting ist ein innovatives Familienmodell, bei dem zwei oder mehr Erwachsene die Verantwortung für ein Kind teilen – unabhängig von einer romantischen Beziehung.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/co-parenting/co-parenting.component').then(m => m.CoParentingDeComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Eisprung verstehen: Dein Schlüssel zu höherer Fruchtbarkeit",
        description: "Der Eisprung ist der entscheidende Moment im weiblichen Zyklus: Genau hier entscheidet sich, ob eine Befruchtung stattfinden kann.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/ei/ei.component').then(m => m.EiDeComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Eizellenspende in Deutschland: Alles zu Chancen, Risiken und Gesetzen",
        description: "Alles zur Eizellenspende in Deutschland: Chancen, Risiken, rechtliche Rahmenbedingungen und internationale Vergleiche. Erfahren Sie, was Sie wissen müssen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeDeComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Einnistung: Definition, Zeitpunkt und essentielle Informationen zur frühen Schwangerschaft",
        description: "Wann und wie erfolgt die Einnistung? Erfahren Sie, wie lange der Prozess dauert, welche Symptome auftreten können und welche Faktoren den Erfolg beeinflussen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/einnistung/einnistung.component').then(m => m.EinnistungDeComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Ungeimpfte Sperma vs. Geimpfte Sperma: Fakten zur Spermienqualität nach der COVID-19-Impfung",
        description: "Wie beeinflusst die COVID-19-Impfung die Spermienqualität? Unser Artikel liefert wissenschaftliche Erkenntnisse und klärt über Mythen auf.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/impf/impf.component').then(m => m.ImpfDeComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Intrazervikale Insemination (ICI): Die schonende Methode zur Familienplanung",
        description: "Erfahren Sie mehr über die intrazervikale Insemination (ICI) – eine flexible und kostengünstige Methode zur Familienplanung mit vielen Vorteilen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/ici/ici.component').then(m => m.IciDeComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Alles über Intrauterine Insemination (IUI): Ablauf, Kosten und Erfolgsaussichten",
        description: "Informieren Sie sich über die intrauterine Insemination (IUI): Ablauf, Kosten, Voraussetzungen und Erfolgsaussichten dieser verbreiteten Methode.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/iui/iui.component').then(m => m.IuiDeComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Künstliche Befruchtung: Methoden, Erfolgschancen und Kosten im Überblick",
        description: "Alles zur künstlichen Befruchtung: IUI, IVF, ICSI, Kosten, Erfolgschancen und Finanzierungsmöglichkeiten. Ein umfassender Leitfaden für Ihren Kinderwunsch.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/kunstl/kunstl.component').then(m => m.KunstlDeComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: Die Revolution der Reproduktionsmedizin zur Behandlung männlicher Unfruchtbarkeit",
        description: "Wie die Intrazytoplasmatische Spermieninjektion (ICSI) die Reproduktionsmedizin verändert: Chancen für Paare mit Fruchtbarkeitsproblemen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/icsi/icsi.component').then(m => m.IcsiDeComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "In-Vitro-Befruchtung (IVF): Umfassender Leitfaden: Kosten, Schritte und mehr",
        description: "Alles über die In-Vitro-Befruchtung (IVF): Kosten, detaillierter Ablauf und neueste Fortschritte. Ein umfassender Leitfaden zur assistierten Reproduktion.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/ivf/ivf.component').then(m => m.IvfDeComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Übertragbare Krankheiten bei der Samenspende",
        description: "Welche Krankheiten können durch Samenspende übertragen werden? Erfahren Sie alles über Risiken, Schutzmaßnahmen und genetische Tests.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/krankheiten/krankheiten.component').then(m => m.KrankheitenDeComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Leihmutterschaft in Deutschland: Rechtliche Fragen, Kosten und Alternativen",
        description: "Leihmutterschaft kann eine wichtige Option für Menschen sein, die auf natürlichem Wege oder durch andere Reproduktionsverfahren nicht zu ihrem Wunschkind gelangen können.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/leihmut/leihmut.component').then(m => m.LeihmutDeComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Lusttropfen und Schwangerschaft: Kann man durch den Lusttropfen schwanger werden?",
        description: "Der Lusttropfen, auch Präejakulat genannt, stellt für viele Menschen ein Rätsel dar: Kann man davon schwanger werden?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/lusttropfen/lusttropfen.component').then(m => m.LusttropfenDeComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Schwangerschaftsblutungen: Ursachen und Unterschiede zur Menstruation",
        description: "Blutungen in der Schwangerschaft: Was sind die Ursachen, Unterschiede zur Menstruation und wann sollten Sie einen Arzt aufsuchen? Wichtige Infos hier.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/mensschwang/mensschwang.component').then(m => m.MensschwangDeComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Polyzystisches Ovarialsyndrom (PCOS): Ursachen, Symptome und Behandlung im Überblick",
        description: "Erfahren Sie alles über das Polyzystische Ovarialsyndrom (PCOS): Ursachen, Symptome, Diagnose und Behandlungsmöglichkeiten für dieses häufige Syndrom.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/pco/pco.component').then(m => m.PCODeComponent)
    },
    {
      path: Paths.BlogSpermDonation,
      data: {
        title: "Private Samenspende: Eine kostengünstige Alternative zur Samenbank in Deutschland",
        description: "Private Samenspende als kostengünstige Alternative zu Samenbanken. Vergleichen Sie Kosten, Vorteile und erfahren Sie mehr über diese Option zur Familienplanung.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/private-samenspende/private-samenspende.component').then(m => m.PrivateSamenspendeDeComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Wie du schneller schwanger wirst: 10 erprobte Strategien für eine erfolgreiche Schwangerschaft",
        description: "10 Tipps für eine schnellere Schwangerschaft: Berechnung fruchtbarer Tage, Ernährung und mehr. Erhöhen Sie Ihre Chancen auf eine erfolgreiche Schwangerschaft.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/schnellschwang/schnellschwang.component').then(m => m.SchnellSchwangDeComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Samenstau & Blaue Hoden: Was steckt hinter dem Kavaliersschmerz?",
        description: "Alles über Samenstau und Kavaliersschmerzen: Ursachen, Symptome, Behandlung und Vorbeugung dieser unangenehmen Beschwerden.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/samenstau/samenstau.component').then(m => m.SamenstauDeComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Spermiogramm: Alles Wichtige zur Analyse der männlichen Fruchtbarkeit",
        description: "Ein Spermiogramm, auch bekannt als Samenanalyse, ist ein essenzieller Test zur Bewertung der männlichen Fruchtbarkeit. Dieser Artikel bietet eine umfassende Übersicht über den Test, seine Durchführung und die Interpretation der Ergebnisse.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/spermiogramm/spermiogramm.component').then(m => m.SpermiogramDeComponent)
    },
    {
      path: Paths.BlogModAbst,
      data: {
        title: "Reform des Abstammungsrechts 2024: Ein großer Schritt für Regenbogenfamilien",
        description: "Die Reform des Abstammungsrechts 2024: Neue Regelungen und wie sie Regenbogenfamilien, insbesondere lesbischen Paaren, zugutekommen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/modernisierung-abstammungsrecht/modernisierung-abstammungsrecht.component').then(m => m.ModernisierungAbstammungsrechtComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "Die Legende vom Klapperstorch: Warum bringt der Storch die Kinder?",
        description: "Hesch dich scho mal gfrogt, wieso de Storch usgrechnet as Symbol für Chind gil?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/klapper/klapper.component').then(m => m.KlapperComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "LH-Anstieg und Ovulationstests: So erkennst du deine fruchtbaren Tage",
        description: "Was passiert während des LH-Anstiegs? Entdecken Sie die Rolle des luteinisierenden Hormons und seine Bedeutung für den Eisprung und die Fruchtbarkeit.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/lh/lh.component').then(m => m.LhDeComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Deutsche Samenbanken im Vergleich: Kosten, Behandlungen und Vergütungen",
        description: "Vergleichen Sie Kosten, Bedingungen und Wartezeiten der führenden Samenbanken in Deutschland. Finden Sie die beste Option für Ihre Bedürfnisse.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankDeComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Vergleich der Top-Kinderwunschkliniken in Deutschland – Kosten und Leistungen",
        description: "Vergleichen Sie die besten Kinderwunschkliniken in Deutschland: Kosten, Behandlungen und Wartezeiten. Finden Sie die passende Klinik für Ihre Behandlung.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteDeComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Wie Chlamydien-Infektionen Ihre Fruchtbarkeit beeinträchtigen: Symptome, Risiken und Schutzmaßnahmen",
        description: "Chlamydien-Infektionen beeinträchtigen Fruchtbarkeit. Erfahren Sie mehr über Symptome, Risiken und Schutzmaßnahmen zur Vermeidung dieser häufigen Infektion.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/clam/clam.component').then(m => m.ClamDeComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Meine Erfahrungen als Spenderkind in einer LGBTQ+ Familie",
        description: "Erfahren Sie von Emilia, wie es ist, als Spenderkind in einer LGBTQ+ Familie aufzuwachsen. Ein Blick auf Liebe, Herausforderungen und die Bedeutung von Familie.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/emilia/emilia.component').then(m => m.EmiliaDeComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Fehlgeburt: Ursachen, Symptome und wie Sie Hilfe finden können",
        description: "Fehlgeburt: Ursachen, Symptome und Hilfe. Erfahren Sie, wie Sie Unterstützung finden und mit den Herausforderungen umgehen können.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/fehlgeburt/fehlgeburt.component').then(m => m.FehlgeburtDeComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Frühgeburt: Ursachen, Symptome und moderne Behandlungsoptionen im Überblick",
        description: "Frühgeburt: Erfahren Sie mehr über Ursachen, Symptome und moderne Behandlungsoptionen. Wichtige Tipps zur Prävention und Unterstützung für betroffene Eltern.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/fruehgeb/fruehgeb.component').then(m => m.ClamDeComponent)
    },
    {
      path: Paths.BlogMutterpass,
      data: {
        title: "Mutterpass: Ein unverzichtbarer Begleiter durch die Schwangerschaft",
        description: "Erfahren Sie, warum der Mutterpass essenziell für Ihre Schwangerschaft ist und was die neue elektronische Version bietet.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/mutterpass/mutterpass.component').then(m => m.MutterpassComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Präimplantationsdiagnostik (PID) in Deutschland: Verfahren, rechtliche Aspekte und Erfahrungen",
        description: "Erfahren Sie alles über Präimplantationsdiagnostik (PID) in Deutschland: Verfahren, rechtliche Aspekte und aktuelle ethische Diskussionen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/PID/PID.component').then(m => m.PIDDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertilisierung nach Sterilisation: Wie Frauen nach einer Tubenligatur doch noch schwanger werden können",
        description: "Nach einer Sterilisation wieder fruchtbar werden: Ein Überblick über Refertilisierung, Erfolgschancen und mögliche Alternativen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/referti-frau/referti-frau.component').then(m => m.RefertiFrauDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertilisierung nach Vasektomie – Wie Männer ihre Fruchtbarkeit erfolgreich zurückgewinnen können",
        description: "Erfahren Sie alles über Refertilisierung nach Vasektomie: Erfolgschancen, Risiken und alternative Methoden zur Wiederherstellung der Fruchtbarkeit.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/referti-mann/referti-mann.component').then(m => m.RefertiMannDeComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Unfruchtbarkeit bei Männern: Die 10 häufigsten Ursachen und wie du sie erkennst",
        description: "Erfahre alles über Unfruchtbarkeit bei Männern. Die 10 häufigsten Ursachen, wie du sie erkennst und Behandlungsmöglichkeiten.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtDeComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Brustwachstum und Brustschmerzen in der Schwangerschaft: Ursachen, Tipps und ganzheitliche Pflege",
        description: "Entdecken Sie die Ursachen von Brustwachstum und Brustschmerzen während der Schwangerschaft und erhalten Sie hilfreiche Tipps zur Linderung und Pflege.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de//brustbeischwang/brustbeischwang.component').then(m => m.BrustbeischwangDeComponent)
    },
    {
      path: Paths.BlogBuerokratie,
      data: {
        title: "Bürokratie nach der Geburt: Wichtige Schritte für Eltern in Deutschland",
        description: "Erfahren Sie alles Wichtige über die bürokratischen Schritte nach der Geburt in Deutschland: Vaterschaftsanerkennung, Geburtsurkunde, Kindergeld und mehr.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/buerokratie/buerokratie.component').then(m => m.BuerokratieComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Unfruchtbarkeit bei Frauen: Die 10 häufigsten Ursachen und wie du sie erkennst",
        description: "Entdecke die 10 häufigsten Ursachen für weibliche Unfruchtbarkeit und erfahre, wie du sie erkennen und behandeln kannst.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/zehnunfruchtfrauen/zehnunfruchtfrauen.component').then(m => m.ZehnunfruchtfrauenDeComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Wie lange überleben Spermien? – Dauer, Einflussfaktoren und Tipps",
        description: "Wie lange können Spermien tatsächlich überleben, und welche Faktoren beeinflussen ihre Lebensdauer?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/ueberlebenspermien/ueberlebenspermien.component').then(m => m.UeberlebenspermienDeComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Die Pille Absetzen: Gründe, Methoden und Was Danach Passiert",
        description: "Pille absetzen: Gründe, Tipps und was danach passiert. Erfahre, wie du den Übergang meisterst und mögliche Veränderungen deines Körpers.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/pilleabsetzen/pilleabsetzen.component').then(m => m.PilleabsetzenDeComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Kosten für künstliche Befruchtung in Deutschland: Wie teuer wird es?",
        description: "Eine künstliche Befruchtung kann den Traum vom eigenen Kind wahr werden lassen – ist in Deutschland jedoch oft mit erheblichen Kosten verbunden.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/kostenAI/kostenAI.component').then(m => m.KostenaiDEComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Zervixschleim verstehen: So erkennen Sie Ihre fruchtbaren Tage",
        description: "Der weibliche Körper sendet vor und während des Eisprungs subtile Signale, um seine fruchtbaren Tage anzuzeigen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/zervix/zervix.component').then(m => m.ZervixDEComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Natürliche Wege zur Verzögerung der Menopause: Wissenschaftlich fundierte Methoden und Ernährungstipps",
        description: "Erfahren Sie, wie Sie die Menopause durch Ernährung, Lebensstil und natürliche Methoden hinauszögern können. Tipps für ein gesundes Altern.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/menopause/menopause.component').then(m => m.MenopauseDeComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Ursachen und Bedeutung der dunklen Linie während der Schwangerschaft",
        description: "Was ist die Linea Nigra? Erfahren Sie, warum diese dunkle Linie während der Schwangerschaft auftritt und wie Sie ihre Pigmentierung reduzieren können.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/linea_nigra/linea_nigra.component').then(m => m.Linea_nigraDeComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Gelbkörperschwäche: Ursachen, Symptome und wirksame Behandlungsoptionen",
        description: "Erfahren Sie alles über Gelbkörperschwäche: Ursachen, Symptome, Diagnose und wirksame Behandlungsmöglichkeiten zur Unterstützung bei Fruchtbarkeitsproblemen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de/gelbkörperschwäche/gelbkörperschwäche.component').then(m => m.GelbkörperschwächeDeComponent)
    }
  ];
  static readonly swissRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/de/impressum.module').then(m => m.ImpressumModule)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Bechermethode: Schwanger wärde ohni Sex - Heiminsemination",
        description: "Lär, wie d’Haiminsemination funktioniert – flexibl, privat und günschtig, mit allne wichtige Info und Tipps.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Samenspende in de Schwiiz: Rechtligi Grundlagä, Tipps und wie RattleStork dir hälfe cha",
        description: "D’Samenspende isch i de Schwiiz e bewährti Method, um verschiedeni Familieforme bim Erfülle vom Chinderwunsch z’unterstütze.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Samenspender wärde i de Schwiiz: Alles, was du wüsse muesch – mit RattleStork",
        description: "Samenspender z'wärde isch i de Schwiiz e wertvolli Möglichkeit für Männer, andere Lüüt z'unterstütze, ihre Familie z'gründe.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: E moderner Ansatz für Familie und Erziehig i de Schwiiz",
        description: "Co-Parenting isch e innovativs Familienmodell, wo zwei oder meh Erwachsene d Verantwurtig für es Chind teile – unabhängig vo ere romantische Bezihig.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/co-parenting/co-parenting.component').then(m => m.CoParentingChComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Eisprung versteh: Der Schlüss zum Erhöhe vo dr Fruchtbarket",
        description: "Dr Eisprung isch dr entscheided Moment im weibliche Zyklus: Grad do wird entschiede, öb e Befruchtig cha stattfände.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/ei/ei.component').then(m => m.EiChComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Eizellenspende i de Schwiiz: Alles über Chancä, Risikä und Gsetz",
        description: "In dr Schwiiz isch Unfruchtbarkeit für vil Paar e riesigi Herausforderung. Wer sich chind wünscht und uf natürligem Wäg nöd schwanger wärde chan, chunt schnell zum Thema Eizellenspende.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeChComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Einnistung: Definition, Zeitpunkt und wichtigi Infos zur früehen Schwangerschafte",
        description: "Wänn und wie passiert d’Einnistung? Finde heraus, wie lang de Prozess dauert, welchi Symptome chönnt auftauche und welchi Faktor de Erfolg beeinflusse.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/einnistung/einnistung.component').then(m => m.EinnistungChComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Ungimpfts Sperma vs. Gimpfts Sperma: Faktene zur Spermiequalitäet nach de COVID-19-Impfig",
        description: "D’COVID-19-Pandämie het s’Läbe vo vilene Mönsche verändert und au eisige Froge zur menschlige Fortpflanzig uufbrächt. Vor allem d’Diskussion um „ungimpfts Sperma vs. gimpfts Sperma“ geit grad häfig dur d’Medie und s’Internet.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/impf/impf.component').then(m => m.ImpfChComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Intrazervikali Insemination (ICI): D’gschonde Methode zur Familieplanung",
        description: "Lern meh über d’intrazervikali Insemination (ICI) – e flexibli und kostengünstigi Methode zur Familieplanung mit vill Vorteilen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/ici/ici.component').then(m => m.IciChComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Alles über Intrauterini Insemination (IUI): Ablauf, Choscht und Erfolgsraten",
        description: "Informier dich über d’intrauterini Insemination (IUI): Ablauf, Choscht, Vorausstätzig und Erfolgsraten vo dere verbreititi Methode.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/iui/iui.component').then(m => m.IuiChComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Chünstligi Befrüchtig: Methode, Erfolgsraten und Choschte im Überblich",
        description: "Alles über die chünstligi Befrüchtig: IUI, IVF, ICSI, Choscht, Erfolgsraten und Finanzierigsmöglichkeiten. E umfassendi Leitfädä für din Kinderwunsch.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/kunstl/kunstl.component').then(m => m.KunstlChComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: Die Revolutioon in dr Reproduktionsmedizin zur Behandlig männlicher Unfruchtbarkeit",
        description: "Wie die Intrazytoplasmatischi Spermieninjektion (ICSI) d’Reproduktionsmedizin verändert: Chance für Paar mit Fruchtbarkeitsproblem.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/icsi/icsi.component').then(m => m.IcsiChComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "In-Vitro-Befrüchtig (IVF): Umfassender Leitfädä: Choscht, Schritti und meh",
        description: "Alles über d’In-Vitro-Befrüchtig (IVF): Choscht, detaillierte Ablauf und neueste Fortschritt. E umfassendi Leitfädä zur assistierte Reproduktion.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/ivf/ivf.component').then(m => m.IvfChComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Übertragbari Krankheite bi dr Samenspende: Risiko und Schutzmassnahme",
        description: "Welchi Krankheite chönnte dur d’Samenspende übertrag werde? Lerne alles über Risiko, Schutzmassnahme und genetisch Test.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/krankheiten/krankheiten.component').then(m => m.KrankheitenChComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Leihmutterschaft i de Schwiiz: Rechtsfrag, Choschte und Alternativen",
        description: "D’Leihmutterschaft isch für viu Lüüt e wichte Option, falls mer natürlechi oder mittels anderere Reproduktionsverfahren nid z’sym Wünschchind chunt.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/leihmut/leihmut.component').then(m => m.LeihmutChComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Lusttropfe und Schwangerschaft: Chan mer dür de Lusttropfe schwanger werde?",
        description: "De Lusttropfe, au Präejakulat genannt, isch für viel Lüt e Rätsel: Chan mer de vo dem schwanger werde? Wiit chan er Krankheite übertrage?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/lusttropfen/lusttropfen.component').then(m => m.LusttropfenChComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Schwangerschaftsblutige: Ursaache und Unterschid zur Menstruation",
        description: "Blutige in dr Schwangerschaft: Was sind d’Ursaache, Unterschid zur Menstruation und wänn söllsch de Arz aufsuche? Wichtigi Infos da.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/mensschwang/mensschwang.component').then(m => m.MensschwangChComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Polyzystisches Ovarialsyndrom (PCOS): Ursaache, Symptome und Behandlig im Überblich",
        description: "Lerne alles über das Polyzystische Ovarialsyndrom (PCOS): Ursaache, Symptome, Diagnose und Behandligsmöglichkeiten für dieses häufigi Syndrom.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/pco/pco.component').then(m => m.PCOChComponent)
    },
    {
      path: Paths.BlogSpermDonation,
      data: {
        title: "Private Samenspende: E kostengünstigi Alternative zur Samenbank in Düütschland",
        description: "Private Samenspende als kostengünstigi Alternative zu Samenbanken. Vergleiche d’Choscht, Vorteil und lern meh über die Option zur Familieplanung.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/private-samenspende/private-samenspende.component').then(m => m.PrivateSamenspendeChComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Spermakrämpfe: Ursaache, Symptome und Behandlig",
        description: "Spermakrämpfe erkläärt: Ursaache, Symptome und effektivi Behandligsmöglichkeiten. Lern, wie du die Beschwerden behandlsch und verhinderst.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/spermakrampf/spermakrampf.component').then(m => m.SpermakrampfChComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Wie du schnäller schwanger wirsch: 10 erprobti Strategie für e erfolgrichi Schwangerschaft",
        description: "10 Tipps für e schnäller Schwangerschaft: Berechnig vo fruchtbare Tage, Ernährig und meh. Steiger d’Chance für e erfolgrichi Schwangerschaft.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/schnellschwang/schnellschwang.component').then(m => m.SchnellSchwangChComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Samenstau & Blueti Hoden: Was steckt hinter em Kavaliersschmerz?",
        description: "Der Begriff Samenstau mag am Anfang luschtig klinge, aber für vili Männer isch erch eine unangenehmi Realität. In dem Artikel gänd mer de Frag a, was es mit den Blaue Hoden uf sich het und wie mer dem vorbeuge chan.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/samenstau/samenstau.component').then(m => m.SamenstauChComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Spermiogramm: Alles Wichtigi zur Analys vo dä männliche Fruchtbarkeit",
        description: "Alles über das Spermiogramm: Durchführig, Choscht und Interpretation vo de Resultat. E Leitfädä zur Beurteilung vo dr männliche Fruchtbarkeit.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/spermiogramm/spermiogramm.component').then(m => m.SpermiogramChComponent)
    },
    {
      path: Paths.BlogModAbst,
      data: {
        title: "Reform vom Abstammigsrecht 2024: E grossi Schritt für Regenbogenfamilie",
        description: "D’Reform vom Abstammigsrecht 2024: Nei Reglige und wie sie Regenbogenfamilie, insbesonders lesbische Paar, zugutekommt.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/modernisierung-abstammungsrecht/modernisierung-abstammungsrecht.component').then(m => m.ModernisierungAbstammungsrechtComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "D Legände vom Chlapperstorch: Wieso bringt de Storch d Chind?",
        description: "Hesch dich scho mal gfrogt, wieso de Storch usgrechnet as Symbol für Chind gil?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/klapper/klapper.component').then(m => m.KlapperComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "LH-Aanstieg und Ovulationstests: So chasch dini fruchtbari Täg erchöne",
        description: "Willst du dine Körper besser versteh, din Zyklus genau im Auge haa oder planisch e Schwangerig? Denn bisch du da genau richtig. I däm Beitrag lernsch alles über de LH-Aanstieg und sini zentrali Rolle bi de Ovulation.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/lh/lh.component').then(m => m.LhChComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Düütsch Samenbanken im Vergleich: Choscht, Behandlig und Vergütig",
        description: "Vergleiche d’Choscht, Bedingige und Warteziite vo de führende Samenbanken in Düütschland. Find die beschti Option für dini Bedürfnis.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankChComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Vergleich vo de Top-Kinderwunschklinike in Düütschland – Choscht und Leischtige",
        description: "Vergleiche die beschte Kinderwunschklinike in Düütschland: Choscht, Behandlig und Warteziite. Find die passendi Klinik für dini Behandlig.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteChComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Wie Chlamydien-Infektioune dini Fruchtbarkeit beeinträchtige: Symptome, Risiko und Schutzmassnahme",
        description: "Chlamydien-Infektioune beeinträchtige Fruchtbarkeit. Erfahre meh über Symptome, Risiko und Schutzmassnahme zur Verhinderig vo dere häufige Infektioun.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/clam/clam.component').then(m => m.ClamChComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Mäi Erfahrige als Spenderkind in ere LGBTQ+ Familie",
        description: "Erfahre vo Emilia, wie es isch, als Spenderkind in ere LGBTQ+ Familie ufzuwachse. E Blick uf Liebi, Herausforderige und die Bedüetig vo Familie.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/emilia/emilia.component').then(m => m.EmiliaChComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Fehlgeburt: Ursaache, Symptome und wie du Hilf finde chasch",
        description: "Fehlgeburt: Ursaache, Symptome und Hilf. Lern wie du Unterstützig finde chasch und mit de Herausforderige umgah chasch.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/fehlgeburt/fehlgeburt.component').then(m => m.FehlgeburtChComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Präimplantationsdiagnostik (PID): Verfahre, rechtlichi Aschpökt und Erfahrige",
        description: "D’Präimplantationsdiagnostik (PID) isch e Methode, wo mer d’Embryos scho vor em Iisatz i d’Gebärmutter genetisch cha überprüefe.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/PID/PID.component').then(m => m.PIDChComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertilisierung nach Sterilisation: Wie Fraue nach ere Tubenligatur doch no schwanger werde chönd",
        description: "Nach ere Sterilisation wieder fruchtbar werde: E Überblich über Refertilisierung, Erfolgschance und möglechi Alternative.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/referti-frau/referti-frau.component').then(m => m.RefertiFrauChComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertilisierung: Chancen und Risike vo de Umkehrung vo ere Vasektomie",
        description: "Lern alles über Refertilisierung nach Vasektomie: Erfolgschance, Risike und alternative Methode zur Wiederherstellung vo dr Fruchtbarkeit.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/referti-mann/referti-mann.component').then(m => m.RefertiMannChComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Unfruchtbarkeit bi Männer: D’10 häufigste Ursaache und wie du sie erkennsch",
        description: "Lern alles über Unfruchtbarkeit bi Männer. D’10 häufigste Ursaache, wie du sie erkennsch und Behandligsmöglichkeiten.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtChComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Bruschtwachstum und Brustschmerz i de Schwangerschaf: Ursachä, Tippe und ganzheitligi Pfleg",
        description: "D’Schwangerschaf isch e unglaublich spannend Zyt, in de enem din Körper uf vielfältigi Wiis verändret. Vor allem zeigt sich das äusserst tydlech an de Brüschte, wo sich uf d’bevorstehendi Stillzyt vorbereited.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH//brustbeischwang/brustbeischwang.component').then(m => m.BrustbeischwangChComponent)
    },
    {
      path: Paths.BlogBuerokratie,
      data: {
        title: "Bürokratie nach dr Geburt: Wichtigi Schritt für Eltere in Düütschland",
        description: "Lern alles Wichtigi über d’bürokratische Schritt nach dr Geburt in Düütschland: Vaterschaftsanerkenne, Geburtsurkunde, Kindergeld und meh.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/buerokratie/buerokratie.component').then(m => m.BuerokratieComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Unfruchtbarkeit bi Fraue: D’10 häufigste Ursaache und wie du sie erkennsch",
        description: "Entdeck die 10 häufigste Ursaache für weiblichi Unfruchtbarkeit und lern, wie du sie erkennsch und behandlsch chasch.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/zehnunfruchtfrauen/zehnunfruchtfrauen.component').then(m => m.ZehnunfruchtfrauenChComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Wie lang überläbe Spermie? – Dauer, Eiflussfaktore und Tipps",
        description: "Wie lang chönd Spermie effektiv überläbe, und weli Faktor händ Eifluss uf d’Lebensdauer?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/ueberlebenspermien/ueberlebenspermien.component').then(m => m.UeberlebenspermienChComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "D’Pille absetze: Gründ, Methode und was nachher passiert",
        description: "Pille absetze: Gründ, Tipps und was nachher passiert. Lern, wie du de Übergang meisterst und möglechi Veränderige vo dim Körper.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/pilleabsetzen/pilleabsetzen.component').then(m => m.PilleabsetzenChComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Choschte für künstlichi Befruchtung in de Schwiiz: Wie teuer wird's?",
        description: "E künstlichi Befruchtung cha dä Traum vom eigene Chind verwirkliche – isch in de Schwiiz aber oft mit erhebliche Choschte verbunde.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/kostenAI/kostenAI.component').then(m => m.KostenaiChComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Cervixsleim versto: So gseht's us, wenn di fruchtbari Täg chömed",
        description: "De weibligi Körper sendet vor und während em Eisprung subtiili Signal, zum de fruchtbari Täg z’wüsse.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/zervix/zervix.component').then(m => m.ZervixChComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Naturlichi Wäg zur Verzögerig vo dr Menopause: Wisseschafltigi fundierti Methode und Ernährigs-Tipps",
        description: "Lern, wie du d’Menopause durch Ernährig, Lebensstil und naturlichi Methode hindert chasch. Tipps für e gsundi Alterig.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/menopause/menopause.component').then(m => m.MenopauseChComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Ursaache und Bedüetig vo dr dunkle Linie während dr Schwangerschaft",
        description: "Was isch d’Linea Nigra? Lern, warum die dunkle Linie während dr Schwangerschaft uftritt und wie du ihre Pigmentierig reduziere chasch.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/linea_nigra/linea_nigra.component').then(m => m.Linea_nigraChComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Gelbkörperschwäche: Ursaache, Symptome und wirksami Behandligsoptione",
        description: "D’Geburt vo eme Chind isch öpis Wunderbars, aber wenn mer e sogni Gälbkörperschwäch (au Lutealinsuffizienz gnennt) het, cha das medizinisch und emotional ganz schön usfordernd si.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/de-CH/gelbkörperschwäche/gelbkörperschwäche.component').then(m => m.GelbkörperschwächeChComponent)
    }
  ];
  static readonly englishRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Home Insemination: Getting Pregnant Without Sex",
        description: "Home insemination, often referred to as DIY artificial insemination, has become a popular option for individuals and couples in the United States looking to conceive in a private and comfortable setting.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Sperm Donation in the USA: Legal Framework, Tips, and How RattleStork Can Help",
        description: "Sperm donation in the United States is a well-established method to support diverse family structures in fulfilling their desire for children.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Becoming a Sperm Donor in the USA: Everything You Need to Know – with RattleStork",
        description: " Becoming a sperm donor in the United States is a meaningful way for men to help others achieve their dreams of building a family.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },


















    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Egg Donation in the United States: Key Opportunities, Risks, and Laws",
        description: "In the United States, infertility poses a major challenge for many couples. Those who hope to have a child but are unable to conceive naturally often explore the option of egg donation.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeEnComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Male Infertility: The 10 Most Common Causes and How to Identify Them",
        description: "Male Infertility: The 10 Most Common Causes and How to Identify Them",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtEnComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "UK Sperm Banks Compared: Costs, Treatments, and Compensation",
        description: "UK Sperm Banks Compared: Costs, Treatments, and Compensation",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankEnComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Costs of Artificial Insemination in the USA: How Expensive Is It?",
        description: "Artificial insemination can make the dream of having your own child come true – but in the USA, it often comes with significant costs.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/kostenAI/kostenAI.component').then(m => m.KostenaiEnComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Comparison of Top Fertility Clinics in the United Kingdom – Costs and Services",
        description: "Comparison of Top Fertility Clinics in the United Kingdom – Costs and Services",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteEnComponent)
    },
    {
      path: Paths.BlogTurkeyBasterPregnancy,
      data: {
        title: "Turkey Baster Pregnancy - A Home Insemiantion Guide",
        description: "Turkey Baster Pregnancy - A Home Insemiantion Guide",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/turkey-baster-pregnancy/turkey-baster-pregnancy.component').then(m => m.TurkeyBasterPregnancyEnComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Pre-implantation Genetic Diagnosis (PGD) in the U.S.: Procedures, Legal Aspects, and Experiences",
        description: "Pre-implantation Genetic Diagnosis (PGD) in the U.S.: Procedures, Legal Aspects, and Experiences",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/PID/PID.component').then(m => m.PIDEnComponent)
    },
    {
      path: Paths.BlogMutterpass,
      data: {
        title: "Maternity Notes: An Essential Companion Throughout Pregnancy in the United Kingdom",
        description: "Maternity Notes: An Essential Companion Throughout Pregnancy in the United Kingdom",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/mutterpass/mutterpass.component').then(m => m.MutterpassEnComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: A Modern Approach to Family and Parenting",
        description: "Co-Parenting is an innovative family model where two or more adults share the responsibilities of raising a child, independent of a romantic relationship.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/co-parenting-en/co-parenting-en.component').then(m => m.CoParentingEnComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Pre-ejaculate and Pregnancy: Can You Get Pregnant from Pre-cum?",
        description: "Pre-ejaculate and Pregnancy: Can I Get Pregnant from Pre-ejaculate? What Are the Real Risks?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/pre-ejaculate/pre-ejaculate.component').then(m => m.PreEjaculateEnComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Blue Balls, Testicular Pain, and Sperm Retention",
        description: "Blue Balls, Testicular Pain, and Sperm Retention",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/samenstau-en/samenstau-en.component').then(m => m.SamenstauEnComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Sperm Cramps: Debunking a Viral Internet Misconception",
        description: "The internet is a hub for rapid information sharing, but not all of it is reliable.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/sperm-cramps/sperm-cramps.component').then(m => m.SpermCrampsEnComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantation: Definition, Timing, and Essential Information on Early Pregnancy",
        description: "Implantation: Definition, Timing, and Essential Information on Early Pregnancy",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/einnistung-en/einnistung-en.component').then(m => m.EinnistungEnComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "In Vitro Fertilization (IVF): Comprehensive Guide, Costs, Steps, and More",
        description: "In Vitro Fertilization (IVF): Comprehensive Guide, Costs, Steps, and More",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/ivf-en/ivf-en.component').then(m => m.IvfEnComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Surrogacy in the USA: Legal Questions, Costs, and Alternatives",
        description: "Surrogacy can be a vital option for individuals and couples who are unable to have a child through natural conception or other reproductive procedures.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/leihmut-en/leihmut-en.component').then(m => m.LeihmutEnComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Polycystic Ovary Syndrome (PCOS): Causes, Symptoms, and Treatment Overview",
        description: "Polycystic Ovary Syndrome (PCOS): Causes, Symptoms, and Treatment Overview",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/pco-en/pco-en.component').then(m => m.PcoEnComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Sperm Analysis: Everything You Need to Know About Assessing Male Fertility",
        description: "Semen Analysis: Everything You Need to Know About Evaluating Male Fertility",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/spermiogramm-en/spermiogramm-en.component').then(m => m.SpermiogrammEnComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "The Stork Legend Explained: Why Storks Are Said to Bring Babies",
        description: "Have you ever wondered why the stork is seen as a symbol of babies?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/klapper-en/klapper-en.component').then(m => m.KlapperEnComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "How to Get Pregnant Faster: 10 Proven Strategies for Success",
        description: "How to Get Pregnant Faster: 10 Proven Strategies for Success",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/schnell-en/schnell-en.component').then(m => m.SchnellEnComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Transmissible Diseases in Sperm Donation",
        description: "Sperm donation enables many people to achieve their dream of having children – whether it is single women, lesbian couples, or heterosexual couples with male infertility issues.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/krank-en/krank-en.component').then(m => m.KrankEnComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Pregnancy Bleeding: Causes and Differences from Menstruation",
        description: "Pregnancy Bleeding: Causes and Differences from Menstruation",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/mensschw-en/mensschw-en.component').then(m => m.MensschwEnComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Unvaccinated vs. Vaccinated Sperm: Understanding Sperm Quality After COVID-19 Vaccination",
        description: "The COVID-19 pandemic has not only disrupted the daily lives of many but also raised questions about human reproduction. Specifically, the debate around unvaccinated sperm vs. vaccinated sperm has sparked lively discussions.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/impf-en/impf-en.component').then(m => m.ImpfEnComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Understanding Ovulation: Your Key to Higher Fertility",
        description: "Ovulation is the decisive moment in the female cycle: it is at this point that fertilization can take place.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/ei-en/ei-en.component').then(m => m.EiEnComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "All About Intrauterine Insemination (IUI): Procedure, Costs, and Success Rates",
        description: "All About Intrauterine Insemination (IUI): Procedure, Costs, and Success Rates",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/iui-en/iui-en.component').then(m => m.IuiEnComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: The Revolution in Reproductive Medicine for Treating Male Infertility",
        description: "ICSI: The Revolution in Reproductive Medicine for Treating Male Infertility",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/icsi/icsi.component').then(m => m.IcsiEnComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Artificial Insemination",
        description: "Artificial Insemination: Methods, Success Rates, and Costs Overview",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/kunst-en/kunst-en.component').then(m => m.KunstEnComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Intracervical Insemination (ICI): The Gentle Method for Family Planning",
        description: "Intracervical Insemination (ICI): The Gentle Method for Family Planning",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/ici-en/ici-en.component').then(m => m.IciEnComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "LH Surge and Ovulation Tests: How to Identify Your Fertile Days",
        description: "Do you want to better understand your body, keep a closer eye on your cycle, or are you planning a pregnancy? You’re in the right place. In this post, you’ll learn everything about the LH surge and its central role in ovulation.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/lh/lh.component').then(m => m.LhEnComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "How Chlamydia Infections Affect Your Fertility: Symptoms, Risks, and Precautions",
        description: "How Chlamydia Infections Affect Your Fertility: Symptoms, Risks, and Precautions",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/clam-en/clam-en.component').then(m => m.ChlamydiaEnComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Interview: My Experience as a Donor-Conceived Child in an LGBTQ+ Family",
        description: "Interview: My Experience as a Donor-Conceived Child in an LGBTQ+ Family",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/emilia-en/emilia-en.component').then(m => m.EmiliaEnComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Miscarriage: Causes, Symptoms, and How to Find Support",
        description: "Miscarriage: Causes, Symptoms, and How to Find Support",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtEnComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Premature Birth: Overview of Causes, Symptoms, and Modern Treatment Options",
        description: "Premature Birth: Overview of Causes, Symptoms, and Modern Treatment Options",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtEnComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Tubal Reversal After Sterilization: How Women Can Conceive After a Tubal Ligation",
        description: "Life circumstances change – what was considered a definitive decision just a few years ago can be reevaluated today. Women who once opted for sterilization (tubal ligation) may later desire to become pregnant.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauEnComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Vasectomy Reversal – How Men Can Successfully Regain Their Fertility",
        description: "The decision to undergo a vasectomy is often made when family planning is considered complete. However, life is full of surprises and changes – the desire to have children again can quickly arise, for example, through a new partnership or changed life goals.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannEnComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Breast Growth and Tenderness During Pregnancy: Causes, Tips, and Holistic Care",
        description: "Pregnancy is an incredibly exciting time when your body undergoes a wide range of changes. These changes are particularly noticeable in your breasts as they prepare for breastfeeding.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/brust-en/brust-en.component').then(m => m.BrustEnComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Female Infertility: The 10 Most Common Causes and How to Recognize Them",
        description: "Female Infertility: The 10 Most Common Causes and How to Recognize Them",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenEnComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "How Long Do Sperm Live? Insights, Factors, and Practical Tips",
        description: "How long can sperm survive, and what factors influence their lifespan?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienEnComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Stopping the Pill: Reasons, Methods, and What Happens Next",
        description: "Stopping the Pill: Reasons, Methods, and What Happens Next",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenEnComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Understanding Cervical Mucus: How to Identify Your Fertile Days",
        description: "The female body sends subtle signals before and during ovulation to indicate its fertile days.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/zervix-en/zervix-en.component').then(m => m.ZervixEnComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Natural Ways to Delay Menopause: Scientifically Backed Methods and Nutritional Tips",
        description: "Natural Ways to Delay Menopause: Scientifically Backed Methods and Nutritional Tips",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/menopause-en/menopause-en.component').then(m => m.MenopauseEnComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Causes and Significance of the Dark Line During Pregnancy",
        description: "Linea Nigra: Causes and Significance of the Dark Line During Pregnancy",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/linea-en/linea-en.component').then(m => m.LineaNigraEnComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Luteal Phase Deficiency: Causes, Symptoms, and Effective Treatment Options",
        description: "Luteal Phase Deficiency: Causes, Symptoms, and Effective Treatment Options",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächeEnComponent)
    },
  ];
  static readonly chineseRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "家庭人孕：通过家庭人孕实现无性行便可态孕",
        description: "家庭人孕，也称家庭人孕，为情侣或单身人士提供了一种无性行即可态孕的方法。本文将解释该方法的原理，所需用品以及其优势和缺点。同时，我们还将提供实用专业孕分享，以增加成功率。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "中国的精子捐献：法律基础、注意事项以及捐献流程",
        description: "在中国，精子捐献是一项自愿的公益行为，旨在帮助不孕不育的夫妻",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "在中国成为精子捐献者：您需要了解的一切",
        description: "在中国，成为精子捐献者是一个充满意义的方式，能够帮助那些面临生育困境的家庭实现拥有自己家庭的梦想。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },






    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "卵子捐赠在中国、香港和台湾：机会、风险及法律解读",
        description: "卵子捐赠在中国、香港和台湾：机会、风险及法律解读",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeZhComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "男性不育：10个最常见的原因及如何识别",
        description: "男性不育：10个最常见的原因及如何识别",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtZhComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "中国人工受孕费用：费用有多高？",
        description: "人工受孕可以实现拥有自己孩子的梦想，但在中国通常伴随着相当高的费用。本文将介绍你可能面临的各种开支、如何申请经济支持以及降低费用的可能途径。这样，你可以从一开始就清楚了解所有相关费用。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/kostenAI/kostenAI.component').then(m => m.KostenaiZhComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "精子银行对比：费用、治疗和补偿",
        description: "精子银行对比：费用、治疗和补偿",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankZhComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "中国、香港和台湾顶级生育诊所比较 - 费用和服务",
        description: "中国、香港和台湾顶级生育诊所比较 - 费用和服务",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteZhComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "捐精过程中的传染病风险",
        description: "捐精使许多人能够实现成为父母的梦想——无论是单身女性、女同性恋伴侣还是因男性不育问题而无法自然受孕的异性伴侣。为了确保这一过程既安全又负责任，建议及早了解可能的风险，如传染病和遗传因素。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/krank-en/krank-en.component').then(m => m.KrankZhComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "多囊卵巢综合征（PCOS）：原因、症状及治疗概述",
        description: "多囊卵巢综合征（PCOS）：原因、症状及治疗概述",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/pco-en/pco-en.component').then(m => m.PcoZhComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "未接种疫苗精子与已接种疫苗精子：COVID-19疫苗接种后精子质量的事实",
        description: "COVID-19 大流行不仅改变了许多人的日常生活，还引发了关于人类生殖的问题。特别是“未接种疫苗精子 vs. 已接种疫苗精子”的讨论引起了热烈的讨论。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/impf-en/impf-en.component').then(m => m.ImpfZhComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "绝育后的生育恢复：输卵管结扎后女性如何仍能怀孕",
        description: "生活环境会发生变化——几年前被视为最终决定的事情，今天可能会有不同的评估。曾经选择绝育（输卵管结扎）的女性，后来可能会重新产生怀孕的愿望。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauZhComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "输精管结扎术后精子恢复——男性如何成功恢复生育能力",
        description: "选择进行输精管结扎术（输精管结扎）通常是在家庭计划已完成时作出的决定。然而，生活充满了各种惊喜和变化——例如，由于新的伴侣关系或生活目标的改变，重新产生了要孩子的愿望。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannZhComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "停用避孕药：原因、方法及其后的变化",
        description: "停用避孕药：原因、方法及其后的变化",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenZhComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "衣原体感染如何影响您的生育能力：症状、风险和防护措施",
        description: "衣原体感染如何影响您的生育能力：症状、风险和防护措施",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/clam-en/clam-en.component').then(m => m.ChlamydiaZhComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "推迟更年期的自然方法：基于科学的策略与饮食建议",
        description: "推迟更年期的自然方法：基于科学的策略与饮食建议",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/menopause-en/menopause-en.component').then(m => m.MenopauseZhComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "访谈：作为LGBTQ+家庭中的捐赠者子女，我的经历",
        description: "访谈：作为LGBTQ+家庭中的捐赠者子女，我的经历",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/emilia-en/emilia-en.component').then(m => m.EmiliaZhComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "如何更快怀孕：10个成功怀孕的经过验证的策略",
        description: "如何更快怀孕：10个成功怀孕的经过验证的策略",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/schnell-en/schnell-en.component').then(m => m.SchnellZhComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "流产：原因、症状以及如何寻求帮助",
        description: "流产：原因、症状以及如何寻求帮助",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtZhComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "早产：原因、症状及现代治疗方案概述",
        description: "早产：原因、症状及现代治疗方案概述",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtZhComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "怀孕期间的乳房增长与疼痛：原因、建议与整体护理",
        description: "怀孕是一段极为激动人心的时光，你的身体会以多种方式发生变化。尤其是乳房，会明显变化，为即将到来的哺乳期做准备。本文汇总了最重要的信息和建议，让你在这一特殊阶段感到全面呵护。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/brust-en/brust-en.component').then(m => m.BrustZhComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "女性不孕症：最常见的10个原因及如何识别它们",
        description: "女性不孕症：最常见的10个原因及如何识别它们",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenZhComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "LH激增与排卵测试：如何识别你的受孕期",
        description: "你是否希望更好地了解自己的身体，密切关注你的月经周期，或正在计划怀孕？那么你来对地方了。本篇文章将为你详细介绍LH激增及其在排卵中的关键作用。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/lh/lh.component').then(m => m.LhZhComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "怀孕期间出血：原因及与月经的区别",
        description: "怀孕期间出血：原因及与月经的区别",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/mensschw-en/mensschw-en.component').then(m => m.MensschwZhComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "理解宫颈黏液：如何识别您的排卵期",
        description: "女性身体在排卵前及排卵期间会发出微妙信号，提示其高排卵期。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/zervix-en/zervix-en.component').then(m => m.ZervixZhComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "前列腺液与怀孕：通过前列腺液能怀孕吗？",
        description: "前列腺液，也称为预射液，对许多人来说是一个谜：通过它能怀孕吗？疾病传播情况如何？在本文中，你将了解前列腺液为何产生，它如何促进繁殖，以及你应该注意些什么以保护自己和他人。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/pre-ejaculate/pre-ejaculate.component').then(m => m.PreEjaculateZhComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "中国的植入前遗传学诊断：过程、法律规定与实践经验",
        description: "中国的植入前遗传学诊断：过程、法律规定与实践经验",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/PID/PID.component').then(m => m.PIDZhComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "精液分析：男性生育能力分析的所有重要信息",
        description: "当渴望拥有孩子未能实现时，夫妻常常陷入充满疑问和不确定的阶段。精液分析可以帮助找到初步答案，识别导致怀孕未果的可能原因。在本文中，您将了解什么是精液分析，测试的具体过程以及在评估中起作用的数值。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/spermiogramm-en/spermiogramm-en.component').then(m => m.SpermiogrammZhComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "妊娠纹：怀孕期间出现的黑线的原因及意义",
        description: "妊娠纹：怀孕期间出现的黑线的原因及意义",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/linea-en/linea-en.component').then(m => m.LineaNigraZhComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "黄体功能不全：原因、症状和有效的治疗方案",
        description: "黄体功能不全：原因、症状和有效的治疗方案",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächeZhComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "中国的代孕现状：法律规定、费用与其他选择",
        description: "对于无法自然受孕或通过其它辅助生殖技术实现生育的家庭来说，代孕曾被视为一种重要的选择。然而，在中国，代孕不仅存在复杂的医学过程，而且面临着严格的法律和监管要求。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/leihmut-en/leihmut-en.component').then(m => m.LeihmutZhComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "了解排卵：通向更高生育力的关键",
        description: "排卵是女性周期中决定性的时刻：正是在这一刻，受精是否能够发生得到了决定。通过了解你的周期、保持健康的生活方式以及使用现代辅助工具，你可以显著提高怀孕的机会。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/ei-en/ei-en.component').then(m => m.EiZhComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "胚胎着床：定义、时间和早期怀孕的重要信息",
        description: "胚胎着床：定义、时间和早期怀孕的重要信息",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/einnistung-en/einnistung-en.component').then(m => m.EinnistungZhComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "精液痉挛——毫无医学实证的网络虚构概念",
        description: "在信息高度泛滥的互联网时代，一些听上去“专业”的术语会迅速传播，哪怕它们完全缺乏医学依据。“精液痉挛”便是这种现象的典型例子。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/sperm-cramps/sperm-cramps.component').then(m => m.SpermCrampsZhComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "共同育儿：现代家庭和教育的方式",
        description: "共同育儿是一种创新的家庭模式，其中两个或更多成年人共同分担抚养一个孩子的责任——无论是否有浪漫关系。它将孩子的福祉放在首位，并创建了清晰的结构，既适合分居的父母，也适合有意识地以伙伴关系方式组建家庭的人们。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/co-parenting-en/co-parenting-en.component').then(m => m.CoParentingZhComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "精子能存活多长时间？——持续时间、影响因素与实用建议",
        description: "精子究竟能够存活多长时间？有哪些因素会影响它们的寿命？",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienZhComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "体外受精：综合指南——费用、步骤等",
        description: "体外受精：综合指南——费用、步骤等",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/ivf-en/ivf-en.component').then(m => m.IvfZhComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "精液积聚、骑士痛、蓝球症",
        description: "精液积聚、骑士痛、蓝球症",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/samenstau-en/samenstau-en.component').then(m => m.SamenstauZhComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "鸡哥的传奇：为什么鸡哥成为孩子的象征？",
        description: "你最近想过为什么鸡哥会成为孩子的象征吗？这个传说背后有深圳的历史根源。",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/klapper-en/klapper-en.component').then(m => m.KlapperZhComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "关于宫腔内人工授精：过程、费用和成功率",
        description: "关于宫腔内人工授精：过程、费用和成功率",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/iui-en/iui-en.component').then(m => m.IuiZhComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "胞浆内单精子注射：治疗男性不育的生殖医学革命",
        description: "胞浆内单精子注射：治疗男性不育的生殖医学革命",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/icsi/icsi.component').then(m => m.IcsiZhComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "人工授精：方法、成功率和费用概述",
        description: "人工授精：方法、成功率和费用概述",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/kunst-en/kunst-en.component').then(m => m.KunstZhComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "宫颈内人工授精：家庭计划的温和方法",
        description: "宫颈内人工授精：家庭计划的温和方法",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/zh/ici-en/ici-en.component').then(m => m.IciZhComponent)
    },
  ];
  static readonly spanishRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Donación de Esperma en España: Marco Legal, Consejos y Cómo Puede Ayudar RattleStork",
        description: "La donación de esperma en España es una práctica bien establecida que ayuda a diversas configuraciones familiares a cumplir su deseo de tener hijos.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Convertirse en Donante de Esperma en España: Todo lo que Necesitas Saber – con RattleStork",
        description: "Convertirse en donante de esperma en España es una forma valiosa de ayudar a personas y parejas a cumplir su sueño de formar una familia.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "La leyenda de la cigüeña: ¿Por qué se dice que trae a los bebés?",
        description: "¿Alguna vez te has preguntado por qué la cigüeña se asocia con la llegada de los bebés?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/klapper-esp/klapper-esp.component').then(m => m.KlapperEspComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Donación de óvulos en España: Todo sobre oportunidades, riesgos y legislación",
        description: "En España, la infertilidad supone un reto importante para muchas parejas. Quienes desean tener un hijo y no pueden lograrlo de forma natural suelen plantearse la donación de óvulos (ovodonación).",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeEsComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Infertilidad en los hombres: Las 10 causas más comunes y cómo reconocerlas",
        description: "Infertilidad en los hombres: Las 10 causas más comunes y cómo reconocerlas",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtEsComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Costos de la fertilización asistida en España: ¿Cuánto cuesta?",
        description: "La fertilización asistida puede hacer realidad el sueño de tener un hijo propio, pero en España a menudo está asociada con costos significativos.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/kostenAI/kostenAI.component').then(m => m.KostenaiEsComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Comparativa de Bancos de Semen en España: Costos, Tratamientos y Compensaciones",
        description: "Comparativa de Bancos de Semen en España: Costos, Tratamientos y Compensaciones",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankEsComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Comparación de las Mejores Clínicas de Fertilidad en España: Costos y Servicios",
        description: "Comparación de las Mejores Clínicas de Fertilidad en España: Costos y Servicios",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteEsComponent)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Embarazo con Jeringa: Guía Completa de Inseminación Artificial Casera",
        description: "Inseminación artificial casera: guía completa, consejos, herramientas y respuestas a tus preguntas para cumplir tu sueño de ser mamá.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Coparentalidad: Un enfoque contemporáneo para las familias en España",
        description: "La coparentalidad representa un modelo innovador que permite a dos o más adultos compartir la responsabilidad de criar a un hijo, sin necesidad de mantener una relación romántica.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/co-pa-esp/co-pa-esp.component').then(m => m.CoPaEspComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnóstico Genético Preimplantacional (DGP) en España: Procedimientos, Aspectos Legales, Éticos y Experiencias Personales",
        description: "Diagnóstico Genético Preimplantacional (DGP) en España: Procedimientos, Aspectos Legales, Éticos y Experiencias Personales",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/PID/PID.component').then(m => m.PIDEsComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Gota preseminal y embarazo: ¿Puedo quedar embarazada por la gota preseminal? ¿Cuál es realmente el riesgo?",
        description: "Gota preseminal y embarazo: ¿Puedo quedar embarazada por la gota preseminal? ¿Cuál es realmente el riesgo?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/lusttro-esp/lusttro-esp.component').then(m => m.LusttroEspComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Congestión seminal, dolor testicular, testículos azules",
        description: "Congestión seminal, dolor testicular, testículos azules",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/samenstau-esp/samenstau-esp.component').then(m => m.SamenstauEspComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Mitos virales: Desmintiendo los calambres de esperma",
        description: "En la era digital, informaciones sin fundamento se propagan con facilidad y rapidez.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/spermakrampf-esp/spermakrampf-esp.component').then(m => m.SpermakrampfEspComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantación: Definición, Momento y Información Esencial sobre el Embarazo Temprano",
        description: "Implantación: Definición, Momento y Información Esencial sobre el Embarazo Temprano",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/einnistung-esp/einnistung-esp.component').then(m => m.EinnistungEspComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminación Intracervical (ICI)",
        description: "Inseminación Intracervical (ICI)",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/ici-esp/ici-esp.component').then(m => m.IciEspComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Fecundación In Vitro (FIV): Guía Completa: Costos, Pasos y Más",
        description: "Fecundación In Vitro (FIV): Guía Completa: Costos, Pasos y Más",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/ivf-esp/ivf-esp.component').then(m => m.IvfEspComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Gestación Subrogada en España: Cuestiones Legales, Costes y Alternativas",
        description: "La gestación subrogada puede representar una opción importante para personas o parejas que, por medios naturales o mediante otros tratamientos de reproducción asistida, no pueden tener el hijo que desean.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/leihmut-esp/leihmut-esp.component').then(m => m.LeihmutEspComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Síndrome de Ovario Poliquístico (SOP): Causas, Síntomas y Tratamiento en Resumen",
        description: "Síndrome de Ovario Poliquístico (SOP): Causas, Síntomas y Tratamiento en Resumen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/pco-esp/pco-esp.component').then(m => m.PcoEspComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Espermiograma: Todo lo Esencial para Analizar la Fertilidad Masculina",
        description: "Cuando el deseo de tener hijos no se cumple, las parejas suelen entrar en una etapa llena de dudas e incertidumbres. Un espermiograma puede ser una herramienta útil para encontrar respuestas iniciales e identificar posibles causas de la falta de embarazo.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/spermiogramm-esp/spermiogramm-esp.component').then(m => m.SpermiogrammEspComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Cómo quedar embarazada más rápido: 10 estrategias comprobadas para un embarazo exitoso",
        description: "Cómo quedar embarazada más rápido: 10 estrategias comprobadas para un embarazo exitoso",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/schnell-esp/schnell-esp.component').then(m => m.SchnellEspComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Enfermedades Transmisibles en la Donación de Semen",
        description: "La donación de semen permite a muchas personas cumplir su deseo de tener hijos, ya sean mujeres solteras, parejas lesbianas o parejas heterosexuales con infertilidad masculina.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/krank-esp/krank-esp.component').then(m => m.KrankEspComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Fecundación artificial: métodos, probabilidades de éxito y costos en resumen",
        description: "Fecundación artificial: métodos, probabilidades de éxito y costos en resumen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/kunst-esp/kunst-esp.component').then(m => m.KunstEspComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Sangrado durante el embarazo: Causas y diferencias con la menstruación",
        description: "Sangrado durante el embarazo: Causas y diferencias con la menstruación",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/menschschw-esp/menschschw-esp.component').then(m => m.MenschschwEspComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Esperma no vacunado vs. Esperma vacunado: Hechos sobre la calidad del esperma después de la vacunación contra COVID-19",
        description: "La pandemia de COVID-19 no solo ha cambiado la vida cotidiana de muchas personas, sino que también ha planteado preguntas sobre la reproducción humana.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/impf-esp/impf-esp.component').then(m => m.ImpfEspComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Entender la ovulación: Tu clave para una mayor fertilidad",
        description: "La ovulación es el momento decisivo en el ciclo femenino: es precisamente aquí donde se determina si puede ocurrir la fecundación.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/ei-esp/ei-esp.component').then(m => m.EiEspComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Todo sobre la Inseminación Intrauterina (IUI): Proceso, Costos y Probabilidades de Éxito",
        description: "Todo sobre la Inseminación Intrauterina (IUI): Proceso, Costos y Probabilidades de Éxito",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/iui-esp/iui-esp.component').then(m => m.IuiEspComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: La Revolución de la Medicina Reproductiva para el Tratamiento de la Infertilidad Masculina",
        description: "ICSI: La Revolución de la Medicina Reproductiva para el Tratamiento de la Infertilidad Masculina",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/icsi/icsi.component').then(m => m.IcsiEspComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Aumento de LH y Tests de Ovulación: Así Reconoces tus Días Fértiles",
        description: "¿Quieres entender mejor tu cuerpo, llevar un control más preciso de tu ciclo o estás planeando un embarazo?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/lh/lh.component').then(m => m.LhEspComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Cómo las infecciones por clamidia afectan tu fertilidad: síntomas, riesgos y medidas de protección",
        description: "Cómo las infecciones por clamidia afectan tu fertilidad: síntomas, riesgos y medidas de protección",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/clam-esp/clam-esp.component').then(m => m.ClamydiaEspComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Entrevista: Mis experiencias como hijo donante en una familia LGBTQ+",
        description: "Entrevista: Mis experiencias como hijo donante en una familia LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/emilia-esp/emilia-esp.component').then(m => m.EmiliaEspComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Aborto espontáneo: causas, síntomas y cómo encontrar ayuda",
        description: "Aborto espontáneo: causas, síntomas y cómo encontrar ayuda",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/fehlgeburt-esp/fehlgeburt-esp.component').then(m => m.FehlgeburtEspComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Parto prematuro: Causas, síntomas y opciones de tratamiento modernas en perspectiva",
        description: "Parto prematuro: Causas, síntomas y opciones de tratamiento modernas en perspectiva",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/fruehgeburt-esp/fruehgeburt-esp.component').then(m => m.FruehgeburtEspComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertilización después de la Esterilización: Cómo las Mujeres Pueden Quedar Embarazadas Después de una Ligadura de Trompas",
        description: "Las circunstancias de la vida cambian: lo que hace unos años se consideraba una decisión definitiva, hoy puede ser reevaluado. Las mujeres que en su momento optaron por una esterilización (ligadura de trompas) pueden posteriormente sentir el deseo de quedar embarazadas.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/refertifrau-esp/refertifrau-esp.component').then(m => m.RefertifrauEspComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertilización después de una Vasectomía – Cómo los hombres pueden recuperar exitosamente su fertilidad",
        description: "La decisión de someterse a una vasectomía suele tomarse cuando se considera que la planificación familiar está completa. Sin embargo, la vida está llena de sorpresas y cambios; el deseo de tener hijos nuevamente puede surgir rápidamente, por ejemplo, debido a una nueva relación o a objetivos de vida cambiantes.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/refertimann-esp/refertimann-esp.component').then(m => m.RefertimannEspComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Crecimiento de los senos y dolores en el embarazo: causas, consejos y cuidado integral",
        description: "El embarazo es una etapa increíblemente emocionante en la que tu cuerpo cambia de muchas maneras. Estos cambios se evidencian especialmente en los senos, que se preparan para la lactancia que se aproxima.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/brust-esp/brust-esp.component').then(m => m.BrustEspComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Infertilidad en mujeres: Las 10 causas más comunes y cómo identificarlas",
        description: "Infertilidad en mujeres: Las 10 causas más comunes y cómo identificarlas",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/zehnunfruchtfrau-esp/zehnunfruchtfrau-esp.component').then(m => m.ZehnunfruchtfrauEspComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "¿Cuánto tiempo pueden sobrevivir los espermatozoides? Factores clave y consejos prácticos",
        description: "Los espermatozoides son células fundamentales para la reproducción humana, pero su capacidad de supervivencia depende de diversos factores.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/ueberleben-esp/ueberleben-esp.component').then(m => m.UeberlebenEspComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Dejar de Tomar la Píldora: Razones, Métodos y Qué Sucede Después",
        description: "Dejar de Tomar la Píldora: Razones, Métodos y Qué Sucede Después",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/pilleab-esp/pilleab-esp.component').then(m => m.PilleabEspComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Entendiendo el moco cervical: Así identifica sus días fértiles",
        description: "El cuerpo femenino envía señales sutiles antes y durante la ovulación para indicar sus días fértiles.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/zervix-esp/zervix-esp.component').then(m => m.ZervixEspComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Formas Naturales de Retrasar la Menopausia: Métodos Científicos y Consejos de Nutrición",
        description: "Formas Naturales de Retrasar la Menopausia: Métodos Científicos y Consejos de Nutrición",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/meonpause-esp/meonpause-esp.component').then(m => m.MeonpauseEspComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Causas y Significado de la Línea Oscura Durante el Embarazo",
        description: "Linea Nigra: Causas y Significado de la Línea Oscura Durante el Embarazo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/linea-esp/linea-esp.component').then(m => m.LineaEspComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Insuficiencia del cuerpo lúteo: causas, síntomas y opciones de tratamiento efectivas",
        description: "La insuficiencia del cuerpo lúteo es un trastorno funcional del cuerpo lúteo en el ovario",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es/gelbkörper-esp/gelbkörper-esp.component').then(m => m.GelbkörperEspComponent)
    }
  ];
  static readonly frenchRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Don de sperme en France : Cadre juridique, démarches et accompagnement personnalisé",
        description: "En France, le don de sperme est une démarche essentielle pour de nombreuses familles en quête de solutions pour concevoir un enfant.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Devenir Donneur de Sperme en France : Tout ce que Vous Devez Savoir – avec RattleStork",
        description: "Devenir donneur de sperme en France est une opportunité précieuse pour les hommes souhaitant aider d'autres personnes à réaliser leur rêve de fonder une famille.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Don d’ovocytes en France : Tout savoir sur les opportunités, les risques et la législation",
        description: "En France, l’infertilité représente un défi majeur pour de nombreux couples. Les personnes qui désirent avoir un enfant et ne parviennent pas à concevoir naturellement peuvent être amenées à s’intéresser au don d’ovocytes.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeFrComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Infertilité chez les hommes : Les 10 causes les plus fréquentes et comment les reconnaître",
        description: "Infertilité chez les hommes : Les 10 causes les plus fréquentes et comment les reconnaître",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtFrComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Coûts de la procréation artificielle en France : Quel budget prévoir ?",
        description: "La procréation artificielle peut réaliser le rêve d'avoir un enfant, mais en France, elle est souvent associée à des coûts significatifs.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/kostenAI/kostenAI.component').then(m => m.KostenaiFrComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Banques de sperme en France: Coûts, traitements et compensations",
        description: "Banques de sperme en France: Coûts, traitements et compensations",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankFrComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Comparaison des meilleures cliniques de fertilité en France – Coûts et services",
        description: "Comparaison des meilleures cliniques de fertilité en France – Coûts et services",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteFrComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnostic Génétique Préimplantatoire (DPI) en France : Procédures, Aspects Juridiques et Expériences",
        description: "Diagnostic Génétique Préimplantatoire (DPI) en France : Procédures, Aspects Juridiques et Expériences",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/PID/PID.component').then(m => m.PIDFrComponent)
    },
    {
      path: Paths.BlogMutterpass,
      data: {
        title: "Carnet de Santé Maternité : Un compagnon indispensable pendant la grossesse en France",
        description: "Carnet de Santé Maternité : Un compagnon indispensable pendant la grossesse en France",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/mutterpass/mutterpass.component').then(m => m.MutterpassFrComponent)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Insémination artisanale : Une solution pour concevoir sans relation sexuelle",
        description: "Découvrez l'insémination artisanale en France : guide complet, étapes, avantages et conseils pour réussir à concevoir à domicile.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: Une approche moderne pour l'éducation et la famille",
        description: "Le co-parenting est une forme moderne de parentalité où plusieurs adultes, sans nécessairement être en couple, partagent les responsabilités liées à l'éducation d'un enfant.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/co-parenting-fr/co-parenting-fr.component').then(m => m.CoParentingFrComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Pré-éjaculat et Grossesse : Peut-on tomber enceinte avec le pré-éjaculat ?",
        description: "Le pré-éjaculat, également appelé liquide pré-séminal, représente une énigme pour de nombreuses personnes : Peut-on tomber enceinte avec lui ?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/lusttropfen-fr/lusttropfen-fr.component').then(m => m.LusttropfenFrComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Accumulation de sperme, Douleur des testicules, Testicules bleus",
        description: "Accumulation de sperme, Douleur des testicules, Testicules bleus",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/samenstau-fr/samenstau-fr.component').then(m => m.SamenstauFrComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Crampes spermatiques : Comprendre un mythe en ligne",
        description: "À l'ère numérique, de nombreux termes émergent sur Internet, souvent sans fondement scientifique. Parmi eux, l'expression crampes spermatiques.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/spermakrampf-fr/spermakrampf-fr.component').then(m => m.SpermakrampfFrComponent)
    }
    ,
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantation : Définition, moment et informations essentielles sur le début de la grossesse",
        description: "Implantation : Définition, moment et informations essentielles sur le début de la grossesse",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/einnistung-fr/einnistung-fr.component').then(m => m.EinnistungFrComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Insémination intracervicale (ICI) : La méthode douce de planification familiale",
        description: "Insémination intracervicale (ICI) : La méthode douce de planification familiale",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/ici-fr/ici-fr.component').then(m => m.IciFrComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Fécondation In Vitro (FIV) : Guide complet : Coûts, étapes et plus",
        description: "Fécondation In Vitro (FIV) : Guide complet : Coûts, étapes et plus",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/ivf-fr/ivf-fr.component').then(m => m.IvfFrComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Gestation pour autrui en France : enjeux juridiques, coûts et alternatives",
        description: "La gestation pour autrui (GPA) représente une option potentiellement importante pour les personnes ou couples qui, en raison d’obstacles médicaux ou de contraintes personnelles, ne peuvent pas réaliser leur désir de fonder une famille par les moyens naturels ou par d’autres techniques de procréation médicalement assistée.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/leihmut-fr/leihmut-fr.component').then(m => m.LeihmutFrComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Syndrome des ovaires polykystiques (SOPK) : Causes, symptômes et traitement en un coup d'œil",
        description: "Syndrome des ovaires polykystiques (SOPK) : Causes, symptômes et traitement en un coup d'œil",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/pco-fr/pco-fr.component').then(m => m.PcoFrComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Spermiogramme : Tout ce qu'il faut savoir sur l'analyse de la fertilité masculine",
        description: "Un spermiogramme peut aider à trouver les premières réponses et à identifier les raisons possibles de l'absence de grossesse.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/spermiogramm-fr/spermiogramm-fr.component').then(m => m.SpermiogrammFrComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "La légende de la cigogne : une histoire fascinante sur les origines de la vie",
        description: "Avez-vous déjà réfléchi à la raison pour laquelle la cigogne est souvent associée à la naissance des bébés ?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/klapper-fr/klapper-fr.component').then(m => m.KlapperFrComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Comment tomber enceinte plus rapidement : 10 stratégies éprouvées pour une grossesse réussie",
        description: "Comment tomber enceinte plus rapidement : 10 stratégies éprouvées pour une grossesse réussie",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/schnell-fr/schnell-fr.component').then(m => m.SchnellFrComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Maladies transmissibles lors du don de sperme",
        description: "Le don de sperme permet à de nombreuses personnes de réaliser leur désir d’enfant – que ce soit des femmes célibataires, des couples de lesbiennes ou des couples hétérosexuels confrontés à une infertilité masculine.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/krank-fr/krank-fr.component').then(m => m.KrankFrComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Fécondation artificielle : Méthodes, chances de succès et coûts en un coup d'œil",
        description: "Fécondation artificielle : Méthodes, chances de succès et coûts en un coup d'œil",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/kunst-fr/kunst-fr.component').then(m => m.KunstFrComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Saignements pendant la grossesse : causes et différences avec les menstruations",
        description: "Saignements pendant la grossesse : causes et différences avec les menstruations",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/mensschw-fr/mensschw-fr.component').then(m => m.MensschwFrComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Sperme Non Vacciné vs. Sperme Vacciné : Faits sur la Qualité du Sperme après la Vaccination contre la COVID-19",
        description: "La pandémie de COVID-19 a non seulement bouleversé le quotidien de nombreuses personnes, mais a également soulevé des questions sur la reproduction humaine.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/impf-fr/impf-fr.component').then(m => m.ImpfFrComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Comprendre l’ovulation : Votre clé pour une fertilité accrue",
        description: "L’ovulation est le moment décisif du cycle féminin : c’est à cet instant précis qu’il est possible qu’une fécondation intervienne.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/ei-fr/ei-fr.component').then(m => m.EiFrComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Tout sur l'Insémination Intra-utérine (IIU) : Processus, Coût et Taux de Réussite",
        description: "Tout sur l'Insémination Intra-utérine (IIU) : Processus, Coût et Taux de Réussite",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/iui-fr/iui-fr.component').then(m => m.IuiFrComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI : La révolution de la médecine reproductive pour traiter l'infertilité masculine",
        description: "ICSI : La révolution de la médecine reproductive pour traiter l'infertilité masculine",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/icsi/icsi.component').then(m => m.IcsiFrComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Augmentation de LH et tests d’ovulation : Comment reconnaître vos jours fertiles",
        description: "Vous souhaitez mieux comprendre votre corps, suivre votre cycle de près ou planifier une grossesse ? Vous êtes au bon endroit. Dans cet article, vous apprendrez tout sur l’augmentation de LH et son rôle central dans l’ovulation.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/lh/lh.component').then(m => m.LhFrComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Comment les infections à Chlamydia affectent votre fertilité : Symptômes, risques et mesures de protection",
        description: "Comment les infections à Chlamydia affectent votre fertilité : Symptômes, risques et mesures de protection",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/clam-fr/clam-fr.component').then(m => m.ClamydiaFrComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Interview : Mon expérience en tant qu'enfant de donneur dans une famille LGBTQ+",
        description: "Interview : Mon expérience en tant qu'enfant de donneur dans une famille LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/emilia-fr/emilia-fr.component').then(m => m.EmiliaFrComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Fausses couches : Causes, symptômes et comment trouver de l'aide",
        description: "Fausses couches : Causes, symptômes et comment trouver de l'aide",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/fehlgeburt-fr/fehlgeburt-fr.component').then(m => m.FehlgeburtFrComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Prématurité : causes, symptômes et options de traitement modernes en un coup d'œil",
        description: "Prématurité : causes, symptômes et options de traitement modernes en un coup d'œil",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/fruehgeburt-fr/fruehgeburt-fr.component').then(m => m.FruehgeburtFrComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Rétablissement de la fertilité après stérilisation : Comment les femmes peuvent-elles tomber enceinte après une ligature des trompes?",
        description: "Les circonstances de la vie changent – ce qui était il y a quelques années considéré comme une décision définitive peut aujourd'hui être réévalué. Les femmes qui ont autrefois opté pour une stérilisation (ligature des trompes) peuvent ressentir plus tard le désir de devenir enceintes.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/refertifrau-fr/refertifrau-fr.component').then(m => m.RefertifrauFrComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Réfertilisation après vasectomie – Comment les hommes peuvent-ils retrouver avec succès leur fertilité",
        description: "La décision de subir une vasectomie est souvent prise lorsque la planification familiale est considérée comme terminée. Cependant, la vie est pleine de surprises et de changements – un désir d'enfant renouvelé peut rapidement surgir, par exemple à cause d'une nouvelle relation ou de nouveaux objectifs de vie.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/refertimann-fr/refertimann-fr.component').then(m => m.RefertimannFrComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Croissance de la poitrine et douleurs mammaires pendant la grossesse : Causes, conseils et soins holistiques",
        description: "La grossesse est une période incroyablement passionnante durant laquelle ton corps se transforme de multiples façons. Ces changements se manifestent particulièrement au niveau de la poitrine, qui se prépare à l'allaitement imminent.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/brust-fr/brust-fr.component').then(m => m.BrustFrComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Infertilité chez les femmes : Les 10 causes les plus courantes et comment les reconnaître",
        description: "Infertilité chez les femmes : Les 10 causes les plus courantes et comment les reconnaître",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/zehnunfruchtfrau-fr/zehnunfruchtfrau-fr.component').then(m => m.ZehnunfruchtfrauFrComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Durée de vie des spermatozoïdes : Ce que vous devez savoir",
        description: "Savez-vous combien de temps un spermatozoïde peut survivre et quels facteurs influencent cette durée ?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/ueberleben-fr/ueberleben-fr.component').then(m => m.UeberlebenFrComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Arrêter la pilule : raisons, méthodes et ce qui se passe ensuite",
        description: "Arrêter la pilule : raisons, méthodes et ce qui se passe ensuite",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/pilleab-fr/pilleab-fr.component').then(m => m.PilleabFrComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Comprendre la glaire cervicale : Comment repérer vos jours fertiles",
        description: "Le corps féminin envoie, avant et pendant l’ovulation, des signaux subtils pour indiquer ses jours fertiles.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/zervix-fr/zervix-fr.component').then(m => m.ZervixFrComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Moyens naturels pour retarder la ménopause : méthodes scientifiquement prouvées et conseils nutritionnels",
        description: "Moyens naturels pour retarder la ménopause : méthodes scientifiquement prouvées et conseils nutritionnels",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/menopause-fr/menopause-fr.component').then(m => m.MenopauseFrComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra : Causes et Signification de la Ligne Sombre Pendant la Grossesse",
        description: "Linea Nigra : Causes et Signification de la Ligne Sombre Pendant la Grossesse",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/linea-fr/linea-fr.component').then(m => m.LineaFrComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Insuffisance lutéale : causes, symptômes et options de traitement efficaces",
        description: "La naissance d'un enfant est un événement joyeux, mais une insuffisance lutéale (déficit lutéal) peut apporter des défis médicaux et émotionnels. Ce trouble hormonal, caractérisé par une carence en progestérone, peut réduire considérablement les chances d'une grossesse réussie.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/fr/gelbkörper-fr/gelbkörper-fr.component').then(m => m.GelbkörperFrComponent)
    }
  ];
  static readonly hindiRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "भारत में शुक्राणु दान: कानूनी ढांचा, सुझाव, और रैटलस्टॉर्क कैसे मदद कर सकता है",
        description: "भारत में शुक्राणु दान एक स्थापित और सुरक्षित तरीका है",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "भारत में शुक्राणु दाता बनें: जानने योग्य हर चीज़ – RattleStork के साथ",
        description: "भारत में शुक्राणु दान एक ऐसा परोपकारी कार्य है जो व्यक्तियों और दंपतियों को माता-पिता बनने के उनके सपने को साकार करने में मदद करता है। स्पष्ट चिकित्सा दिशानिर्देशों",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "पुरुषों में बांझपन: 10 सबसे सामान्य कारण और उन्हें पहचानने के तरीके",
        description: "पुरुषों में बांझपन: 10 सबसे सामान्य कारण और उन्हें पहचानने के तरीके",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtHiComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "एलएच वृद्धि और ओव्यूलेशन: उर्वरता को समझने और निगरानी के लिए आपका गाइड",
        description: "क्या आप अपने शरीर को बेहतर समझना चाहते हैं, अपने चक्र पर अधिक ध्यान देना चाहते हैं या गर्भधारण की योजना बना रहे हैं?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/lh/lh.component').then(m => m.LhHiComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "महिलाओं में बांझपन: 10 प्रमुख कारण और उन्हें कैसे पहचाने",
        description: "महिलाओं में बांझपन: 10 प्रमुख कारण और उन्हें कैसे पहचाने",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenHiComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "भारत में कृत्रिम प्रजनन की लागत: यह कितना महंगा है?",
        description: "कृत्रिम प्रजनन आपके अपने बच्चे का सपना साकार कर सकता है – लेकिन भारत में यह अक्सर काफी महंगी प्रक्रिया होती है। इस लेख में, आप जानेंगे कि आपके सामने कौन-कौन से खर्चे आएंगे",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/kostenAI/kostenAI.component').then(m => m.KostenaiHiComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "अवैक्सीनित शुक्राणु बनाम वैक्सीनित शुक्राणु: COVID-19 टीके के बाद शुक्राणु गुणवत्ता पर तथ्य",
        description: "COVID-19 महामारी ने न केवल कई लोगों के दैनिक जीवन को बदल दिया है",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/impf-en/impf-en.component').then(m => m.ImpfHiComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "स्टेरिलाइजेशन के बाद पुनःप्रजनन: ट्यूब लिगेशन के बाद महिलाएं कैसे गर्भवती हो सकती हैं",
        description: "जीवन परिस्थितियाँ बदलती हैं – कुछ साल पहले जो अंतिम निर्णय माना जाता था, उसे आज अलग तरीके से देखा जा सकता है।",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauHiComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "पॉलीसिस्टिक ओवरी सिंड्रोम (PCOS): कारण, लक्षण और उपचार का अवलोकन",
        description: "पॉलीसिस्टिक ओवरी सिंड्रोम (PCOS): कारण, लक्षण और उपचार का अवलोकन",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/pco-en/pco-en.component').then(m => m.PcoHiComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Why and how you should stop taking the pill",
        description: "Why and how you should stop taking the pill",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenHiComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "वीरथ दान में स्थानांतरित होने वाले रोग",
        description: "वीरथ दान कई लोगों को उनके परिवार की चाह पूरी करने का अवसर प्रदान करता है – चाहे वे अकेली महिलाएं हों, समलैंगिक जोड़े हों या पुरुष बांझपन के कारण हेटेरोसेक्शुअल जोड़े।",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/krank-en/krank-en.component').then(m => m.KrankHiComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "कैसे क्लैमाइडिया संक्रमण आपकी प्रजनन क्षमता को प्रभावित करते हैं: लक्षण, जोखिम और बचाव के उपाय",
        description: "कैसे क्लैमाइडिया संक्रमण आपकी प्रजनन क्षमता को प्रभावित करते हैं: लक्षण, जोखिम और बचाव के उपाय",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/clam-en/clam-en.component').then(m => m.ChlamydiaHiComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "वेसेक्टोमी के बाद पुनःप्रजनन – पुरुष अपनी प्रजनन क्षमता को सफलतापूर्वक कैसे पुनः प्राप्त कर सकते हैं",
        description: "वेसेक्टोमी का निर्णय अक्सर तब लिया जाता है जब परिवार नियोजन को समाप्त मान लिया जाता है। लेकिन जीवन अप्रत्याशित घटनाओं और परिवर्तनों से भरा होता है",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannHiComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "रजोनिवृत्ति को देरी से लाने के प्राकृतिक तरीके: वैज्ञानिक रूप से प्रमाणित विधियाँ और आहार सुझाव",
        description: "रजोनिवृत्ति को देरी से लाने के प्राकृतिक तरीके: वैज्ञानिक रूप से प्रमाणित विधियाँ और आहार सुझाव",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/menopause-en/menopause-en.component').then(m => m.MenopauseHiComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "साक्षात्कार: एक LGBTQ+ परिवार में डोनरकिड होने के मेरे अनुभव",
        description: "साक्षात्कार: एक LGBTQ+ परिवार में डोनरकिड होने के मेरे अनुभव",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/emilia-en/emilia-en.component').then(m => m.EmiliaHiComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "गर्भपात: कारण, लक्षण और सहायता कैसे प्राप्त करें",
        description: "गर्भपात: कारण, लक्षण और सहायता कैसे प्राप्त करें",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtHiComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "समय से पूर्व प्रसव: कारण, लक्षण और आधुनिक उपचार विकल्पों का अवलोकन",
        description: "समय से पूर्व प्रसव: कारण, लक्षण और आधुनिक उपचार विकल्पों का अवलोकन",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtHiComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "गर्भावस्था के दौरान रक्तस्राव: कारण और मासिक धर्म से अंतर",
        description: "गर्भावस्था के दौरान रक्तस्राव: कारण और मासिक धर्म से अंतर",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/mensschw-en/mensschw-en.component').then(m => m.MensschwHiComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "तेज़ी से गर्भवती होने के 10 आज़माए हुए तरीके: सफल गर्भधारण के लिए रणनीतियाँ",
        description: "तेज़ी से गर्भवती होने के 10 आज़माए हुए तरीके: सफल गर्भधारण के लिए रणनीतियाँ",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/schnell-en/schnell-en.component').then(m => m.SchnellHiComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "गर्भावस्था में स्तन का विकास और स्तन दर्द: कारण, सुझाव और समग्र देखभाल",
        description: "गर्भावस्था एक अत्यंत रोमांचक समय होता है, जिसमें आपका शरीर कई तरह से परिवर्तन का अनुभव करता है। विशेष रूप से स्तनों में यह परिवर्तन स्पष्ट रूप से दिखाई देते हैं, जो आने वाले स्तनपान के लिए तैयार किए जा रहे होते हैं।",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/brust-en/brust-en.component').then(m => m.BrustHiComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "गर्भाशय के ग्रीवा द्रव को समझना: अपनी उपजाऊ दिनों की पहचान कैसे करें",
        description: "महिला शरीर ओव्यूलेशन से पहले और दौरान सूक्ष्म संकेत भेजता है ताकि उसके उपजाऊ दिन दिख सकें।",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/zervix-en/zervix-en.component').then(m => m.ZervixHiComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "भारत में अंडा दान: प्रमुख अवसर, जोखिम और नियम",
        description: "भारत में बांझपन से संबंधित चुनौतियाँ हर साल कई जोड़ों को प्रभावित करती हैं। जिन जोड़ों का सपना है कि वे बच्चा प्राप्त करें, लेकिन प्राकृतिक तरीके से गर्भधारण में कठिनाई का सामना कर रहे हैं,",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeHiComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "भारत में शुक्राणु बैंक की तुलना: लागत, उपचार और प्रतिफल",
        description: "भारत में शुक्राणु बैंक की तुलना: लागत, उपचार और प्रतिफल",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankHiComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "भारत के शीर्ष आईवीएफ क्लीनिकों की तुलना – खर्चे और सेवाएं",
        description: "भारत के शीर्ष आईवीएफ क्लीनिकों की तुलना – खर्चे और सेवाएं",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteHiComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "लस्टट्रॉपफेन और गर्भावस्था: क्या लस्टट्रॉपफेन से गर्भधारण संभव है?",
        description: "लस्टट्रॉपफेन, जिसे प्री-इजाकुलेट भी कहा जाता है, कई लोगों के लिए एक रहस्य बना हुआ है: क्या इससे गर्भधारण हो सकता है?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/pre-ejaculate/pre-ejaculate.component').then(m => m.PreEjaculateHiComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "को-पैरेंटिंग: परिवार और पालन-पोषण के लिए एक नया दृष्टिकोण",
        description: "को-पैरेंटिंग एक अभिनव और लचीला परिवारिक मॉडल है, जिसमें दो या दो से अधिक वयस्क बच्चे की देखभाल और जिम्मेदारी साझा करते हैं,",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/co-parenting-en/co-parenting-en.component').then(m => m.CoParentingHiComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "ल्यूटियल फेज डिफिशिएंसी: कारण, लक्षण और प्रभावी उपचार विकल्प",
        description: "ल्यूटियल फेज डिफिशिएंसी: कारण, लक्षण और प्रभावी उपचार विकल्प",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächeHiComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "लिनिया निग्रा: गर्भावस्था के दौरान गहरी रेखा के कारण और महत्व",
        description: "लिनिया निग्रा: गर्भावस्था के दौरान गहरी रेखा के कारण और महत्व",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/linea-en/linea-en.component').then(m => m.LineaNigraHiComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "भारत में सरोगेसी: कानूनी सवाल, खर्च और वैकल्पिक रास्ते",
        description: "सरोगेसी उन लोगों के लिए एक महत्वपूर्ण विकल्प हो सकता है, जिनके लिए प्राकृतिक या अन्य प्रजनन विधियों से अपना इच्छित संतान प्राप्त करना कठिन हो रहा है।",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/leihmut-en/leihmut-en.component').then(m => m.LeihmutHiComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "ओव्यूलेशन को समझना: आपकी उच्च उत्पादकता की चाबी",
        description: "ओव्यूलेशन महिला के चक्र का निर्णायक क्षण होता है: यहीं तय होता है कि निषेचन संभव है या नहीं। अपने चक्र के सही ज्ञान, स्वस्थ जीवनशैली और आधुनिक उपकरणों के साथ, आप गर्भधारण की संभावनाओं को काफी बेहतर बना सकती हैं।",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/ei-en/ei-en.component').then(m => m.EiHiComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "गर्भधारण: परिभाषा, समय और प्रारंभिक गर्भावस्था की महत्वपूर्ण जानकारी",
        description: "गर्भधारण: परिभाषा, समय और प्रारंभिक गर्भावस्था की महत्वपूर्ण जानकारी",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/einnistung-en/einnistung-en.component').then(m => m.EinnistungHiComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "भारत में प्रीइम्प्लांटेशन जेनेटिक डायग्नोसिस: प्रक्रिया, कानूनी पहलू और अनुभव",
        description: "भारत में प्रीइम्प्लांटेशन जेनेटिक डायग्नोसिस: प्रक्रिया, कानूनी पहलू और अनुभव",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/PID/PID.component').then(m => m.PIDHiComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "स्पर्मियोग्राम: पुरुष प्रजनन क्षमता के विश्लेषण के बारे में सब कुछ",
        description: "इंटरनेट ऐसी जगह है जहाँ जानकारी अत्यंत तीव्रता से फैलती है। यहाँ आप वैज्ञानिक तथ्यों से लेकर पूर्णतया मनगढ़ंत अवधारणाओं तक, सब कुछ पा सकते हैं।",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/spermiogramm-en/spermiogramm-en.component').then(m => m.SpermiogrammHiComponent)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "घरेलू गर्भाधान: सरल और सुरक्षित तरीका",
        description: "घरेलू गर्भाधान के लिए आसान और किफायती विधियों, आवश्यक उपकरणों और सफलता बढ़ाने के टिप्स की विस्तृत जानकारी पाएं।",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "स्पर्म क्रैम्प: कारण, लक्षण और उपचार",
        description: "स्पर्म क्रैम्प: कारण, लक्षण और उपचार",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/sperm-cramps/sperm-cramps.component').then(m => m.SpermCrampsHiComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "शुक्राणु संचय, अंडकोष का दर्द, नीले अंडकोष",
        description: "शुक्राणु संचय, अंडकोष का दर्द, नीले अंडकोष",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/samenstau-en/samenstau-en.component').then(m => m.SamenstauHiComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "स्पर्म कितने दिन जीवित रहते हैं? – अवधि, प्रभाव कारक और सुझाव",
        description: "स्पर्म वास्तव में कितने समय तक जीवित रह सकते हैं, और कौन से कारक उनकी जीवन अवधि को प्रभावित करते हैं?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienHiComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "क्लैपरस्टॉर्क की कथा: क्यों स्टॉर्क बच्चों को लाता है?",
        description: "क्या आपने कभी सोचा है कि स्टॉर्क को बच्चों का प्रतीक क्यों माना जाता है?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/klapper-en/klapper-en.component').then(m => m.KlapperHiComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "इन-विट्रो-निषेचन: व्यापक गाइड: लागत, चरण और अधिक",
        description: "इन-विट्रो-निषेचन: व्यापक गाइड: लागत, चरण और अधिक",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/ivf-en/ivf-en.component').then(m => m.IvfHiComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "इंट्रायूटेराइन इंसमिनेशन: प्रक्रिया, लागत और सफलता की संभावनाएं",
        description: "इंट्रायूटेराइन इंसमिनेशन: प्रक्रिया, लागत और सफलता की संभावनाएं",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/iui-en/iui-en.component').then(m => m.IuiHiComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "आईसीएसआई: पुरुष बांझपन के इलाज के लिए प्रजनन चिकित्सा में क्रांति",
        description: "आईसीएसआई: पुरुष बांझपन के इलाज के लिए प्रजनन चिकित्सा में क्रांति",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/icsi/icsi.component').then(m => m.IcsiHiComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "कृत्रिम गर्भाधान: तरीके, सफलता दर और लागत पर एक नजर",
        description: "कृत्रिम गर्भाधान: तरीके, सफलता दर और लागत पर एक नजर",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/kunst-en/kunst-en.component').then(m => m.KunstHiComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "इंट्रासर्विकल इंसैमिनेशन (आईसीआई): परिवार नियोजन की कोमल विधि",
        description: "इंट्रासर्विकल इंसैमिनेशन (आईसीआई): परिवार नियोजन की कोमल विधि",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/hi/ici-en/ici-en.component').then(m => m.IciHiComponent)
    },
  ];
  static readonly malayRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Derma Sperma di Malaysia: Larangan Undang-Undang, Pilihan Rawatan Kesuburan, dan Alternatif",
        description: "Di Malaysia, derma sperma merupakan topik yang kompleks dan sensitif, dipengaruhi oleh peraturan undang-undang, norma budaya, dan pandangan agama.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Menjadi Penderma Sperma di Malaysia: Apa yang Perlu Anda Ketahui – Bersama RattleStork",
        description: "Menjadi penderma sperma di Malaysia adalah langkah yang berharga untuk membantu individu dan pasangan yang menghadapi cabaran kesuburan.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Pemindahan Sel Telur di Malaysia: Peluang, Risiko dan Peraturan Terkini",
        description: "Di Malaysia, masalah ketidaksuburan merupakan cabaran besar bagi banyak pasangan yang ingin memiliki anak. Bagi mereka yang tidak dapat mengandung secara semula jadi, pemindahan sel telur sering menjadi salah satu pilihan.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeMsComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Kos untuk Perubatan Pembiakan Bantuan di Malaysia: Berapa Mahal Ia?",
        description: "Perubatan pembiakan bantuan boleh menjadikan impian memiliki anak menjadi kenyataan – namun di Malaysia, ia sering kali dikaitkan dengan kos yang signifikan.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/kostenAI/kostenAI.component').then(m => m.KostenaiMsComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Sindrom Ovari Polisistik (PCOS): Punca, Gejala dan Rawatan dalam Gambaran Keseluruhan",
        description: "Sindrom Ovari Polisistik (PCOS): Punca, Gejala dan Rawatan dalam Gambaran Keseluruhan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/pco-en/pco-en.component').then(m => m.PcoMsComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Sperma Tidak Divaksin vs. Sperma Divaksin: Fakta Mengenai Kualiti Sperma Selepas Vaksin COVID-19",
        description: "Pandemi COVID-19 tidak hanya mengubah kehidupan sehari-hari banyak orang, tetapi juga menimbulkan pertanyaan tentang reproduksi manusia.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/impf-en/impf-en.component').then(m => m.ImpfMsComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Cara Semula Jadi untuk Melambatkan Menopaus: Kaedah dan Petua Pemakanan Berasaskan Sains",
        description: "Cara Semula Jadi untuk Melambatkan Menopaus: Kaedah dan Petua Pemakanan Berasaskan Sains",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/menopause-en/menopause-en.component').then(m => m.MenopauseMsComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Ketidaksuburan pada Lelaki: 10 Punca Paling Umum dan Cara Mengenalinya",
        description: "Ketidaksuburan pada Lelaki: 10 Punca Paling Umum dan Cara Mengenalinya",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtMsComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Penyakit Berjangkit dalam Derma Sperma",
        description: "Derma sperma membolehkan ramai orang untuk merealisasikan hasrat memiliki anak – sama ada wanita bujang, pasangan lesbian atau pasangan heteroseksual dengan masalah kemandulan lelaki.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/krank-en/krank-en.component').then(m => m.KrankMsComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertilisasi Selepas Vasektomi – Bagaimana Lelaki Boleh Memulihkan Kesuburan Mereka dengan Berjaya",
        description: "Keputusan untuk menjalani vasektomi sering dibuat apabila perancangan keluarga dianggap selesai. Namun, kehidupan penuh dengan kejutan dan perubahan – keinginan untuk mempunyai anak semula boleh timbul dengan cepat, contohnya melalui hubungan baru atau perubahan matlamat hidup.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannMsComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Berhenti Mengambil Pil: Sebab, Kaedah, dan Apa Yang Berlaku Selepas Itu",
        description: "Berhenti Mengambil Pil: Sebab, Kaedah, dan Apa Yang Berlaku Selepas Itu",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenMsComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Pemulihan Kesuburan selepas Sterilisasi: Bagaimana Wanita Boleh Hamil Selepas Ligasi Tuba",
        description: "Keadaan hidup berubah – apa yang beberapa tahun yang lalu dianggap sebagai keputusan akhir, hari ini mungkin dinilai secara berbeza. Wanita yang pernah memilih untuk menjalani sterilisasi (ligasi tuba) mungkin kemudian merasakan keinginan untuk hamil kembali.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauMsComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Bagaimana Jangkitan Chlamydia Mempengaruhi Kesuburan Anda: Gejala, Risiko dan Langkah-langkah Perlindungan",
        description: "Bagaimana Jangkitan Chlamydia Mempengaruhi Kesuburan Anda: Gejala, Risiko dan Langkah-langkah Perlindungan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/clam-en/clam-en.component').then(m => m.ChlamydiaMsComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Bagaimana Menjadi Hamil Lebih Cepat: 10 Strategi Teruji untuk Kehamilan yang Berhasil",
        description: "Bagaimana Menjadi Hamil Lebih Cepat: 10 Strategi Teruji untuk Kehamilan yang Berhasil",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/schnell-en/schnell-en.component').then(m => m.SchnellMsComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Keguguran: Punca, Gejala, dan Bagaimana Anda Boleh Mendapatkan Bantuan",
        description: "Keguguran: Punca, Gejala, dan Bagaimana Anda Boleh Mendapatkan Bantuan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtMsComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Kelahiran Pra-matang: Punca, Gejala, dan Gambaran Keseluruhan Pilihan Rawatan Moden",
        description: "Kelahiran Pra-matang: Punca, Gejala, dan Gambaran Keseluruhan Pilihan Rawatan Moden",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtMsComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Memahami Lendir Serviks: Cara Mengenal Hari Subur Anda",
        description: "Tubuh wanita menghantar isyarat-isyarat halus sebelum dan semasa ovulasi untuk menunjukkan hari-hari subur.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/zervix-en/zervix-en.component').then(m => m.ZervixMsComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Temu Bual: Pengalaman Saya Sebagai Anak Penderma dalam Keluarga LGBTQ+",
        description: "Temu Bual: Pengalaman Saya Sebagai Anak Penderma dalam Keluarga LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/emilia-en/emilia-en.component').then(m => m.EmiliaMsComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Pertumbuhan Payudara dan Sakit Payudara Semasa Kehamilan: Punca, Petua dan Penjagaan Menyeluruh",
        description: "Kehamilan adalah satu masa yang sangat menarik, di mana badan anda mengalami pelbagai perubahan. Perubahan ini amat ketara pada payudara yang sedang dipersiapkan untuk tempoh penyusuan yang akan datang.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/brust-en/brust-en.component').then(m => m.BrustMsComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Pendarahan Semasa Kehamilan: Punca dan Perbezaan dengan Haid",
        description: "Pendarahan Semasa Kehamilan: Punca dan Perbezaan dengan Haid",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/mensschw-en/mensschw-en.component').then(m => m.MensschwMsComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Ketidaksuburan pada Wanita: 10 Punca Utama dan Cara Mengesannya",
        description: "Ketidaksuburan pada Wanita: 10 Punca Utama dan Cara Mengesannya",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenMsComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Cairan Pra-Ejakulasi dan Kehamilan: Bolehkah Anda Hamil Melalui Cairan Pra-Ejakulasi?",
        description: "Cairan pra-ejakulasi, juga dikenali sebagai cairan kesukaan, merupakan teka-teki bagi banyak orang: Bolehkah Anda hamil darinya?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/pre-ejaculate/pre-ejaculate.component').then(m => m.PreEjaculateMsComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Perbandingan Bank Sperma di Malaysia dan Indonesia: Kos, Rawatan, dan Pampasan",
        description: "Perbandingan Bank Sperma di Malaysia dan Indonesia: Kos, Rawatan, dan Pampasan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankMsComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Perbandingan Klinik Kesuburan Terbaik di Malaysia dan Indonesia – Kos dan Perkhidmatan",
        description: "Perbandingan Klinik Kesuburan Terbaik di Malaysia dan Indonesia – Kos dan Perkhidmatan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteMsComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Kenaikan LH dan Ujian Ovulasi: Cara Mengenali Hari Subur Anda",
        description: "Anda ingin memahami badan anda dengan lebih baik, memantau kitaran anda dengan tepat atau merancang kehamilan? Maka anda berada di tempat yang betul. Dalam entri ini, anda akan mengetahui segala-galanya mengenai kenaikan LH dan peranannya yang utama dalam ovulasi.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/lh/lh.component').then(m => m.LhMsComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Memahami Ovulasi: Kunci Anda kepada Kesuburan yang Lebih Tinggi",
        description: "Ovulasi adalah detik penting dalam kitaran wanita: Di sinilah keputusan sama ada persenyawaan boleh berlaku.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/ei-en/ei-en.component').then(m => m.EiMsComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantasi: Definisi, Waktu, dan Informasi Penting tentang Kehamilan Dini",
        description: "Implantasi: Definisi, Waktu, dan Informasi Penting tentang Kehamilan Dini",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/einnistung-en/einnistung-en.component').then(m => m.EinnistungMsComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnosis Praimplantasi (PGD) di Indonesia: Prosedur, Aspek Hukum, dan Pengalaman",
        description: "Diagnosis Praimplantasi (PGD) di Indonesia: Prosedur, Aspek Hukum, dan Pengalaman",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/PID/PID.component').then(m => m.PIDMsComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Spermiogram: Semua yang Perlu Diketahui Mengenai Analisis Kesuburan Lelaki",
        description: "Spermiogram boleh membantu mencari jawapan awal dan mengenal pasti kemungkinan sebab keguguran kehamilan. Dalam artikel ini, anda akan mengetahui apa itu spermiogram, bagaimana proses ujian dijalankan dengan tepat, dan nilai-nilai apa yang memainkan peranan dalam penilaian.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/spermiogramm-en/spermiogramm-en.component').then(m => m.SpermiogrammMsComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Punca dan Makna Garisan Gelap Semasa Kehamilan",
        description: "Linea Nigra: Punca dan Makna Garisan Gelap Semasa Kehamilan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/linea-en/linea-en.component').then(m => m.LineaNigraMsComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Berapa Lama Sperma Boleh Hidup? – Panduan Lengkap dan Faktor Penentu",
        description: "Adakah anda tertanya-tanya berapa lama sperma boleh bertahan? Sama ada anda merancang kehamilan atau sekadar ingin mengetahui fakta saintifik, artikel ini akan menerangkan segalanya.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienMsSComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Persenyawaan In Vitro (IVF): Panduan Lengkap: Kos, Langkah, dan Lain-lain",
        description: "Persenyawaan In Vitro (IVF): Panduan Lengkap: Kos, Langkah, dan Lain-lain",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/ivf-en/ivf-en.component').then(m => m.IvfMsComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Kelemahan Badan Kuning: Punca, Gejala dan Pilihan Rawatan Berkesan",
        description: "Kelahiran seorang anak adalah peristiwa yang menggembirakan, namun kelemahan badan kuning (Insufisiensi Luteal) boleh membawa cabaran perubatan dan emosi.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächeMsComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Surrogacy di Malaysia: Persoalan Undang-undang, Kos dan Alternatif",
        description: "Surrogacy boleh menjadi satu pilihan penting bagi mereka yang tidak dapat memiliki anak secara semula jadi atau melalui kaedah pembiakan lain.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/leihmut-en/leihmut-en.component').then(m => m.LeihmutMsComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: Pendekatan Moden untuk Keluarga dan Pendidikan",
        description: "Co-Parenting adalah model keluarga yang moden di mana dua atau lebih orang dewasa berkongsi tanggungjawab dalam membesarkan anak tanpa melibatkan hubungan romantik.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/co-parenting-en/co-parenting-en.component').then(m => m.CoParentingMsComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Kekejangan Sperma: Memahami Realiti di Sebalik Mitos Internet",
        description: "Dunia internet sering menjadi tempat berkembangnya maklumat, baik yang benar mahupun tidak.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/sperm-cramps/sperm-cramps.component').then(m => m.SpermCrampsMsComponent)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Kaedah Inseminasi Sendiri: Hamil Tanpa Hubungan Seksual",
        description: "Kaedah inseminasi sendiri, atau inseminasi buatan di rumah, adalah pilihan alternatif bagi pasangan atau individu yang ingin hamil tanpa hubungan seksual.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Kesesakan Air Mani, Kesakitan Kavalier, Testis Sakit",
        description: "Kesesakan Air Mani, Kesakitan Kavalier, Testis Sakit",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/samenstau-en/samenstau-en.component').then(m => m.SamenstauMsComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "Legenda Bangau: Mengapa Bangau Dianggap Membawa Bayi?",
        description: "Pernahkah anda tertanya-tanya mengapa bangau sering dikaitkan dengan bayi?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/klapper-en/klapper-en.component').then(m => m.KlapperMsComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Semua Mengenai Inseminasi Intrauterin (IUI): Proses, Kos, dan Kadar Kejayaan",
        description: "Semua Mengenai Inseminasi Intrauterin (IUI): Proses, Kos, dan Kadar Kejayaan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/iui-en/iui-en.component').then(m => m.IuiMsComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: Revolusi Perubatan Reproduktif untuk Merawat Kemandulan Lelaki",
        description: "ICSI: Revolusi Perubatan Reproduktif untuk Merawat Kemandulan Lelaki",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/icsi/icsi.component').then(m => m.IcsiMsComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Persenyawaan Buatan: Kaedah, Peluang Kejayaan, dan Kos",
        description: "Persenyawaan Buatan: Kaedah, Peluang Kejayaan, dan Kos",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/kunst-en/kunst-en.component').then(m => m.KunstMsComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminasi Intracervikal (ICI): Kaedah Lembut untuk Perancangan Keluarga",
        description: "Inseminasi Intracervikal (ICI): Kaedah Lembut untuk Perancangan Keluarga",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ms/ici-en/ici-en.component').then(m => m.IciMsComponent)
    },
  ];
  static readonly portugueseRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Doação de Esperma em Portugal: Aspectos Legais, Dicas e como a RattleStork pode ajudar",
        description: "A doação de esperma tornou-se uma prática cada vez mais comum em Portugal, ajudando diversas configurações familiares a realizar o sonho da maternidade e paternidade.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Tornar-se Doador de Esperma em Portugal: Tudo o que Precisa Saber – com RattleStork",
        description: "Tornar-se doador de esperma em Portugal é uma forma especial de ajudar outras pessoas a realizarem o sonho de formar uma família.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Doação de óvulos em Portugal: Tudo sobre oportunidades, riscos e enquadramento legal",
        description: "Em Portugal, a infertilidade representa um desafio para muitos casais e pessoas que desejam engravidar. Vários fatores – desde problemas de ovulação até questões genéticas – podem dificultar ou impedir a conceção de forma natural.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/eizellenspende/eizellenspende.component').then(m => m.EizellenspendePtComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Infertilidade Masculina: As 10 causas mais comuns e como identificá-las",
        description: "Infertilidade Masculina: As 10 causas mais comuns e como identificá-las",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtPtComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Fertilidade Assistida: Custos em Clínicas de Fertilidade, Bancos de Esperma e Apoio Financeiro em Portugal",
        description: "Fertilidade Assistida: Custos em Clínicas de Fertilidade, Bancos de Esperma e Apoio Financeiro em Portugal",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/kostenAI/kostenAI.component').then(m => m.KostenaiPtComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Custos da Fertilização Artificial em Portugal: Quanto Custa?",
        description: "A fertilização artificial pode transformar o sonho de ter um filho em realidade – mas em Portugal, muitas vezes está associada a custos significativos.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankPtComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Comparação das Principais Clínicas de Fertilidade em Portugal – Custos e Serviços",
        description: "Comparação das Principais Clínicas de Fertilidade em Portugal – Custos e Serviços",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasitePtComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnóstico Genético Pré-implantação (DGP) em Portugal: Procedimentos, Aspetos Legais e Experiências",
        description: "Diagnóstico Genético Pré-implantação (DGP) em Portugal: Procedimentos, Aspetos Legais e Experiências",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/PID/PID.component').then(m => m.PIDPortuComponent)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Inseminação Caseira: Engravidar sem sexo",
        description: "A Inseminação Caseira, é uma opção popular para casais ou indivíduos que desejam engravidar sem relações sexuais.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: Uma abordagem inovadora para criar famílias e educar filhos",
        description: "O Co-Parenting é um modelo de família inovador onde dois ou mais adultos compartilham a responsabilidade pela criação de uma criança, sem que haja necessidade de um vínculo romântico entre eles.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/co-paren-portu/co-paren-portu.component').then(m => m.CoParenPortuComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Pré-ejaculado e Gravidez: É possível engravidar com o pré-ejaculado?",
        description: "O pré-ejaculado, também conhecido como pré-sêmen, representa um mistério para muitas pessoas: é possível engravidar com ele?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/lusttropf-portu/lusttropf-portu.component').then(m => m.LusttropfPorComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Congestão de Esperma, Dor do Cavalheiro, Testículos Azuis",
        description: "Congestão de Esperma, Dor do Cavalheiro, Testículos Azuis",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/samenstau-portu/samenstau-portu.component').then(m => m.SamenstauPorComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Mito das Cãibras de Esperma: Desvendando Falsas Informações da Internet",
        description: "A Internet é uma ferramenta poderosa para disseminar informações, mas também é um campo fértil para a propagação de mitos.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/spermakrampf-portu/spermakrampf-portu.component').then(m => m.SpermakrampfPorComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantação: Definição, Momento e Informações Essenciais sobre o Início da Gravidez",
        description: "Implantação: Definição, Momento e Informações Essenciais sobre o Início da Gravidez",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/einnistung-portu/einnistung-portu.component').then(m => m.EinnistungPortuComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminação Intracervical (ICI): O Método Suave para Planejamento Familiar",
        description: "Inseminação Intracervical (ICI): O Método Suave para Planejamento Familiar",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/ici-portu/ici-portu.component').then(m => m.IciPortuComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Fertilização In Vitro (FIV): Guia Completo: Custos, Etapas e Mais",
        description: "Fertilização In Vitro (FIV): Guia Completo: Custos, Etapas e Mais",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/ivf-portu/ivf-portu.component').then(m => m.IvfPortuComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Barriga de Aluguer em Portugal: Desafios Legais, Custos e Alternativas",
        description: "A barriga de aluguer pode ser uma opção importante para pessoas que, por meios naturais ou através de outros métodos de reprodução assistida, não conseguem ter o filho desejado.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/leihmut-portu/leihmut-portu.component').then(m => m.LeihmutPortuComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Síndrome dos Ovários Policísticos (SOP): Causas, Sintomas e Tratamentos em Resumo",
        description: "Síndrome dos Ovários Policísticos (SOP): Causas, Sintomas e Tratamentos em Resumo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/pco-portu/pco-portu.component').then(m => m.PcoPortuComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Espermiograma: Tudo o que Você Precisa Saber sobre a Análise da Fertilidade Masculina",
        description: "Um espermiograma pode ajudar a encontrar as primeiras respostas e identificar possíveis razões para a ausência de gravidez. Neste artigo, você descobrirá o que é um espermiograma, como o teste é realizado e quais valores são importantes na análise.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/spermiogramm-portu/spermiogramm-portu.component').then(m => m.SpermiogrammPortuComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "A Fascinante Lenda da Cegonha: Mitos e Simbolismos por Trás dessa História",
        description: "A lenda da cegonha como mensageira dos bebês é um dos mitos mais encantadores e duradouros da cultura popular.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/klapper-portu/klapper-portu.component').then(m => m.KlapperPortuComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Como engravidar mais rápido: 10 estratégias comprovadas para uma gravidez bem-sucedida",
        description: "Como engravidar mais rápido: 10 estratégias comprovadas para uma gravidez bem-sucedida",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/schnell-portu/schnell-portu.component').then(m => m.SchnellPortuComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Doenças Transmissíveis na Doação de Esperma",
        description: "A doação de esperma possibilita que muitas pessoas realizem o sonho de ter um filho – seja para mulheres solteiras, casais de lésbicas ou casais heterossexuais com infertilidade masculina.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/krank-portu/krank-portu.component').then(m => m.KrankPortuComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Fertilização Artificial: Métodos, Taxas de Sucesso e Custos em Perspectiva",
        description: "Fertilização Artificial: Métodos, Taxas de Sucesso e Custos em Perspectiva",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/kunst-portu/kunst-portu.component').then(m => m.KunstPortuComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Sangramentos na gravidez: Causas e diferenças para a menstruação",
        description: "Sangramentos na gravidez: Causas e diferenças para a menstruação",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/mensschw-portu/mensschw-portu.component').then(m => m.MensschwPortuComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Esperma não vacinado vs. Esperma vacinado: Fatos sobre a qualidade do esperma após a vacinação contra a COVID-19",
        description: "A pandemia de COVID-19 não apenas alterou a rotina de muitas pessoas, mas também levantou questões sobre a reprodução humana.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/impf-portu/impf-portu.component').then(m => m.ImpfPortuComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Entendendo a Ovulação: Sua Chave para uma Fertilidade Maior",
        description: "A ovulação é o momento decisivo no ciclo feminino: é exatamente nela que se decide se a fertilização pode ocorrer.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/ei-portu/ei-portu.component').then(m => m.EiPortuComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Tudo sobre Inseminação Intrauterina (IIU): Procedimento, Custos e Taxas de Sucesso",
        description: "Tudo sobre Inseminação Intrauterina (IIU): Procedimento, Custos e Taxas de Sucesso",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/iui-portu/iui-portu.component').then(m => m.IuiPortuComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: A Revolução da Medicina Reprodutiva no Tratamento da Infertilidade Masculina",
        description: "ICSI: A Revolução da Medicina Reprodutiva no Tratamento da Infertilidade Masculina",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/icsi/icsi.component').then(m => m.IcsiPortuComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Aumento de LH e Testes de Ovulação: Como Identificar Seus Dias Férteis",
        description: "Você quer entender melhor o seu corpo, acompanhar o seu ciclo de forma mais precisa ou está planejando uma gravidez? Então você está no lugar certo. Neste artigo, você aprenderá tudo sobre o aumento do LH e seu papel central na ovulação.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/lh/lh.component').then(m => m.LhPortuComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Como as Infecções por Clamídia Afetam Sua Fertilidade: Sintomas, Riscos e Medidas de Proteção",
        description: "Como as Infecções por Clamídia Afetam Sua Fertilidade: Sintomas, Riscos e Medidas de Proteção",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/clam-en/clam-en.component').then(m => m.ChlamydiaPortuComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Entrevista: Minhas experiências como filho de doador em uma família LGBTQ+",
        description: "Entrevista: Minhas experiências como filho de doador em uma família LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/emilia-en/emilia-en.component').then(m => m.EmiliaPortuComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Aborto Espontâneo: Causas, Sintomas e Como Encontrar Ajuda",
        description: "Aborto Espontâneo: Causas, Sintomas e Como Encontrar Ajuda",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtPortuComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Parto prematuro: causas, sintomas e uma visão geral das opções modernas de tratamento",
        description: "Parto prematuro: causas, sintomas e uma visão geral das opções modernas de tratamento",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtPortuComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertilização após Esterilização: Como Mulheres Podem Engravidar Após uma Ligadura de Trompas",
        description: "As circunstâncias de vida mudam – o que há poucos anos era considerado uma decisão definitiva, hoje pode ser avaliado de forma diferente. Mulheres que anteriormente optaram por uma esterilização (ligadura de trompas) podem posteriormente desejar engravidar novamente.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauPortuComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Reverter a Vasectomia – Como os Homens Podem Recuperar com Sucesso a Sua Fertilidade",
        description: "A decisão de realizar uma vasectomia é frequentemente tomada quando o planeamento familiar é considerado concluído. No entanto, a vida está cheia de surpresas e mudanças – um novo desejo de ter filhos pode surgir rapidamente, por exemplo, devido a um novo relacionamento ou a objetivos de vida alterados.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannPortuComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Crescimento das Mamas e Dores nos Seios durante a Gravidez: Causas, Dicas e Cuidados Holísticos",
        description: "Crescimento dos Seios e Dores Durante a Gravidez: Causas e Dicas",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/brust-en/brust-en.component').then(m => m.BrustPortuComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Infertilidade nas Mulheres: As 10 Causas Mais Comuns e Como Reconhecê-las",
        description: "Infertilidade nas Mulheres: As 10 Causas Mais Comuns e Como Reconhecê-las",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenPortuComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Quanto tempo os espermatozoides sobrevivem? Fatores cruciais e dicas essenciais",
        description: "Você já se perguntou quanto tempo os espermatozoides podem sobreviver e o que influencia sua durabilidade?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienPortuComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Parar de Tomar a Pílula: Motivos, Métodos e o que Acontece Depois",
        description: "Parar de Tomar a Pílula: Motivos, Métodos e o que Acontece Depois",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenPortuComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Entendendo o Muco Cervical: Como Identificar Seus Dias Férteis",
        description: "O corpo feminino envia sinais sutis antes e durante a ovulação para indicar seus dias férteis.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/zervix-en/zervix-en.component').then(m => m.ZervixPortuComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Métodos Naturais para Atrasar a Menopausa: Métodos Baseados em Evidências e Dicas Nutricionais",
        description: "Métodos Naturais para Atrasar a Menopausa: Métodos Baseados em Evidências e Dicas Nutricionais",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/menopause-en/menopause-en.component').then(m => m.MenopausePortuComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linha Negra: Causas e Significado da Linha Escura Durante a Gravidez",
        description: "Linha Negra: Causas e Significado da Linha Escura Durante a Gravidez",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/linea-en/linea-en.component').then(m => m.LineaNigraPortuComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Insuficiência Luteínica: Causas, Sintomas e Opções de Tratamento Eficazes",
        description: "O nascimento de uma criança é um evento alegre, mas a insuficiência luteínica (insuficiência luteínica) pode trazer desafios médicos e emocionais.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächePortuComponent)
    },
  ];
  static readonly russianRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Донорство спермы в Австрии: правовые аспекты, советы и как может помочь RattleStork",
        description: "Донорство спермы в Австрии стало важной и устоявшейся практикой, которая помогает семьям воплотить мечту о рождении ребёнка.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Как стать донором спермы в России: руководство от RattleStork",
        description: "Донорство спермы в России — это уникальный шанс для мужчин помочь другим людям обрести счастье материнства и отцовства.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Как хламидийные инфекции влияют на вашу фертильность: симптомы, риски и меры предосторожности",
        description: "Как хламидийные инфекции влияют на вашу фертильность: симптомы, риски и меры предосторожности",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/clam-en/clam-en.component').then(m => m.ChlamydiaRuComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Донорство яйцеклеток в России: Все о возможностях, рисках и законодательстве",
        description: "В России проблема бесплодия – серьезный вызов для многих пар и одиноких людей, желающих стать родителями. Различные медицинские, генетические и возрастные факторы могут мешать наступлению естественной беременности.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeRuComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Бесплодие у мужчин: 10 самых частых причин и как их распознать",
        description: "Бесплодие у мужчин: 10 самых частых причин и как их распознать",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtRuComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Стоимость искусственного оплодотворения в России: Сколько это будет стоить?",
        description: "Искусственное оплодотворение может помочь осуществить мечту о собственном ребенке, однако в России этот процесс часто связан с существенными расходами.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/kostenAI/kostenAI.component').then(m => m.KostenaiRuComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Рефертилитация после вазэктомии – как мужчины успешно восстанавливают свою фертильность",
        description: "Решение о вазэктомии часто принимается, когда вопрос планирования семьи считается закрытым. Однако жизнь полна сюрпризов и изменений – желание иметь детей может возникнуть вновь, например, из-за нового партнёрства или изменившихся жизненных целей.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannRuComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Сравнение российских банков спермы: стоимость, процедуры и компенсации",
        description: "Сравнение российских банков спермы: стоимость, процедуры и компенсации",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankRuComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Сравнение лучших клиник по лечению бесплодия в России – стоимость и услуги",
        description: "Сравнение лучших клиник по лечению бесплодия в России – стоимость и услуги",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteRuComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Восстановление фертильности после стерилизации: Как женщины могут забеременеть после перевязки маточных труб",
        description: "Жизненные обстоятельства меняются – то, что несколько лет назад считалось окончательным решением, сегодня может оцениваться иначе. Женщины, которые когда-то решили пройти стерилизацию (перевязку маточных труб), могут позже снова захотеть забеременеть.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauRuComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Бесплодие у женщин: 10 самых частых причин и как их распознать",
        description: "Бесплодие у женщин: 10 самых частых причин и как их распознать",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenRuComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Отмена противозачаточных таблеток: причины, методы и что происходит после этого",
        description: "Отмена противозачаточных таблеток: причины, методы и что происходит после этого",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenRuComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Выкидыш: причины, симптомы и как найти помощь",
        description: "Выкидыш: причины, симптомы и как найти помощь",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtRuComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Рост груди и боли в груди во время беременности: причины, советы и комплексный уход",
        description: "Беременность — это невероятно волнующее время, когда ваше тело претерпевает множество изменений. Особенно заметны эти изменения в области груди, которая готовится к предстоящему грудному вскармливанию.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/brust-en/brust-en.component').then(m => m.BrustRuComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Предимплантационное генетическое тестирование (ПГТ) в России: Процедуры, юридические аспекты и текущие реалии",
        description: "Предимплантационное генетическое тестирование (ПГТ) в России: Процедуры, юридические аспекты и текущие реалии",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/PID/PID.component').then(m => m.PIDRuComponent)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Домашнее оплодотворение: забеременеть без секса",
        description: "Домашнее оплодотворение — это удобный способ для пар и одиноких женщин забеременеть без необходимости медицинского вмешательства.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Совместное воспитание: Современный и гибкий подход к созданию семьи",
        description: "Совместное воспитание представляет собой новую и более гибкую модель семейных отношений, при которой несколько взрослых (чаще всего два или больше) делят ответственность за воспитание ребенка.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/co-pa-ru/co-pa-ru.component').then(m => m.CoPaRuComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Предэякулят и беременность: Можно ли забеременеть от предэякулята?",
        description: "Предэякулят, также называемый преэякулятом, является загадкой для многих людей: Можно ли забеременеть от него?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/lusttropfen-ru/lusttropfen-ru.component').then(m => m.LusttropfenRuComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Преждевременные роды: причины, симптомы и обзор современных методов лечения",
        description: "Преждевременные роды: причины, симптомы и обзор современных методов лечения",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtRuComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Сперматостаз, кавалерская боль, синий яичек",
        description: "Сперматостаз, кавалерская боль, синий яичек",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/samenstau-ru/samenstau-ru.component').then(m => m.SamenstauRuComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Спермакрэмпфе: несуществующий термин и интернет-миф без медицинских оснований",
        description: "Интернет — среда, в которой информация распространяется стремительно, зачастую без критического осмысления.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/spermakrampf-ru/spermakrampf-ru.component').then(m => m.SpermakrampfRuComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Черная линия: Причины и значение тёмной линии во время беременности",
        description: "Черная линия: Причины и значение тёмной линии во время беременности",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/linea-en/linea-en.component').then(m => m.LineaNigraRuComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Лютеиновая недостаточность: причины, симптомы и эффективные методы лечения",
        description: "Лютеиновая недостаточность: причины, симптомы и эффективные методы лечения",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächeRuComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Имплантация: Определение, сроки и важная информация о ранней беременности",
        description: "Имплантация: Определение, сроки и важная информация о ранней беременности",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/einnistung-ru/einnistung-ru.component').then(m => m.EinnistungRuComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Интрацервикальная инсеминация (ИЦИ): щадящий метод планирования семьи",
        description: "Интрацервикальная инсеминация (ИЦИ): щадящий метод планирования семьи",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/ici-ru/ici-ru.component').then(m => m.IciRuComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Экстракорпоральное оплодотворение (ЭКО): Полное руководство: стоимость, этапы и многое другое",
        description: "Экстракорпоральное оплодотворение (ЭКО): Полное руководство: стоимость, этапы и многое другое",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/ivf-ru/ivf-ru.component').then(m => m.IvfRuComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Суррогатное материнство в России: правовые вопросы, расходы и альтернативы",
        description: "Суррогатное материнство может стать важным решением для людей, которые по естественным причинам или в результате иных репродуктивных проблем не могут стать родителями.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/leihmut-ru/leihmut-ru.component').then(m => m.LeihmutRuComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Интервью: Мой опыт как ребенка донора в семье LGBTQ+",
        description: "Интервью: Мой опыт как ребенка донора в семье LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/emilia-en/emilia-en.component').then(m => m.EmiliaRuComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Синдром поликистозных яичников (СПКЯ): Причины, симптомы и лечение",
        description: "Синдром поликистозных яичников (СПКЯ): Причины, симптомы и лечение",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/pco-ru/pco-ru.component').then(m => m.PcoRuComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Спермиограмма: Всё важное об анализе мужской фертильности",
        description: "Спермиограмма может помочь найти первые ответы и определить возможные причины отсутствия беременности. В этой статье вы узнаете, что такое спермиограмма, как проводится тест и какие показатели важны при анализе.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/spermiogramm-ru/spermiogramm-ru.component').then(m => m.SpermiogrammRuComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "Легенда об аисте: почему именно аист приносит детей?",
        description: "Вы когда-нибудь задумывались, почему аист стал символом, связанным с рождением детей?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/klapper-ru/klapper-ru.component').then(m => m.KlapperRuComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Как быстрее забеременеть: 10 проверенных стратегий для успешной беременности",
        description: "Как быстрее забеременеть: 10 проверенных стратегий для успешной беременности",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/schnell-ru/schnell-ru.component').then(m => m.SchnellRuComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Инфекционные заболевания при донорстве спермы",
        description: "Донорство спермы даёт возможность многим людям осуществить желание завести ребёнка – будь то одинокие женщины, лесбийские пары или гетеросексуальные пары, у которых мужчина страдает бесплодием.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/krank-ru/krank-ru.component').then(m => m.KrankRuComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Искусственное оплодотворение: методы, шансы на успех и затраты",
        description: "Искусственное оплодотворение: методы, шансы на успех и затраты",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/kunst-ru/kunst-ru.component').then(m => m.KunstRuComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Как долго живут сперматозоиды? – Продолжительность, влияющие факторы и советы",
        description: "Как долго сперматозоиды могут выживать и какие факторы влияют на их жизнеспособность?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienRuComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Кровотечения при беременности: причины и отличия от менструации",
        description: "Кровотечения при беременности: причины и отличия от менструации",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/mensschw-ru/mensschw-ru.component').then(m => m.MensschwRuComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Невакцинированная сперма vs. Вакцинированная сперма: Факты о качестве спермы после вакцинации против COVID-19",
        description: "Пандемия COVID-19 изменила не только повседневную жизнь многих людей, но и вызвала вопросы о человеческом воспроизводстве.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/impf-ru/impf-ru.component').then(m => m.ImpfRuComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Понимание овуляции: Ваш ключ к повышенной фертильности",
        description: "Овуляция — это решающий момент в женском цикле: именно в этот момент решается, может ли произойти оплодотворение.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/ei-ru/ei-ru.component').then(m => m.EiRuComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Все о внутриматочной инсеминации (ВМИ): процесс, стоимость и шансы на успех",
        description: "Все о внутриматочной инсеминации (ВМИ): процесс, стоимость и шансы на успех",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/iui-ru/iui-ru.component').then(m => m.IuiRuComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ИКСИ: Революция в репродуктивной медицине для лечения мужского бесплодия",
        description: "ИКСИ: Революция в репродуктивной медицине для лечения мужского бесплодия",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/icsi/icsi.component').then(m => m.IcsiRuComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Понимание цервикальной слизи: как определить свои фертильные дни",
        description: "Женское тело посылает тонкие сигналы до и во время овуляции, чтобы указать на фертильные дни. Одним из самых надёжных показателей является цервикальная слизь.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/zervix-en/zervix-en.component').then(m => m.ZervixRuComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Естественные способы задержки менопаузы: Научно обоснованные методы и советы по питанию",
        description: "Естественные способы задержки менопаузы: Научно обоснованные методы и советы по питанию",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/menopause-en/menopause-en.component').then(m => m.MenopauseRuComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Подъём ЛГ и тесты на овуляцию: как распознать фертильные дни",
        description: "Хочешь лучше понять своё тело, следить за циклом или планируешь беременность? Тогда ты попал по адресу. В этой статье ты узнаешь всё о подъёме ЛГ и его центральной роли в овуляции.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/ru/lh/lh.component').then(m => m.LhRuComponent)
    }
  ];
  static readonly italianRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Donazione di Sperma in Italia: Normative Legali, Processi e Come RattleStork Può Aiutare",
        description: "In Italia, la donazione di sperma è una pratica regolata rigorosamente per garantire sicurezza, etica e trasparenza per tutti i soggetti coinvolti.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Diventare Donatore di Sperma in Italia: Guida Completa",
        description: "Donare sperma in Italia è un atto di generosità che consente di aiutare coppie e individui a superare le difficoltà di infertilità.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Donazione di Ovociti in Italia: Tutto su Opportunità, Rischi e Normative",
        description: "In Italia, l’infertilità rappresenta per molte coppie una sfida importante. Chi desidera avere un figlio e non riesce a concepire naturalmente può considerare la donazione di ovociti.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeItComponent)
    },
    {
      path: Paths.BlogMutterpass,
      data: {
        title: "Libretto di Gravidanza: Un compagno indispensabile durante la gravidanza",
        description: "Libretto di Gravidanza: Un compagno indispensabile durante la gravidanza",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/mutterpass/mutterpass.component').then(m => m.MutterpassItComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Infertilità maschile: Le 10 cause più comuni e come riconoscerle",
        description: "Infertilità maschile: Le 10 cause più comuni e come riconoscerle",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtItComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Costi per la fecondazione artificiale in Italia: Quanto costa?",
        description: "La fecondazione artificiale può trasformare il sogno di avere un figlio in realtà, ma in Italia è spesso associata a costi significativi.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/kostenAI/kostenAI.component').then(m => m.KostenaiItComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Banche del Seme Italiane a Confronto: Costi, Trattamenti e Compensi",
        description: "Banche del Seme Italiane a Confronto: Costi, Trattamenti e Compensi",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/kostenaufwandbank copy/kostenaufwandbank.component').then(m => m.KostenaufwandbankItComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Confronto delle migliori cliniche per la fertilità in Italia – Costi e servizi",
        description: "Confronto delle migliori cliniche per la fertilità in Italia – Costi e servizi",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteItComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnosi Genetica Preimpianto (PGD) in Italia: Procedure, Aspetti Legali ed Esperienze",
        description: "Diagnosi Genetica Preimpianto (PGD) in Italia: Procedure, Aspetti Legali ed Esperienze",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/PID/PID.component').then(m => m.PIDItComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: Un Nuovo Approccio alla Famiglia e all'Educazione",
        description: "Il Co-Parenting è un approccio innovativo alla genitorialità in cui due o più adulti condividono la responsabilità di crescere un bambino, senza la necessità di una relazione romantica.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/co-parenting/co-parenting.component').then(m => m.CoParentingItComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Capire l'ovulazione: La tua chiave per una maggiore fertilità",
        description: "L'ovulazione è il momento decisivo nel ciclo femminile: è in questo momento che si decide se la fecondazione può avvenire.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/ei/ei.component').then(m => m.EiItComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Annidamento: Definizione, tempistiche e informazioni essenziali sulla gravidanza precoce",
        description: "Annidamento: Definizione, tempistiche e informazioni essenziali sulla gravidanza precoce?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/einnistung/einnistung.component').then(m => m.EinnistungItComponent)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Inseminazione Artificiale Domestica: Come Rimanere Incinta a Casa",
        description: "L'inseminazione artificiale domestica, nota anche come inseminazione artificiale fatta in casa, offre una soluzione a coppie o individui che desiderano rimanere incinta senza dover ricorrere a interventi clinici complessi.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminazione Intracervicale (ICI): Il Metodo Delicato per la Pianificazione Familiare",
        description: "Inseminazione Intracervicale (ICI): Il Metodo Delicato per la Pianificazione Familiare",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/ici/ici.component').then(m => m.IciItComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Sperma Non Vaccinato vs. Sperma Vaccinato: Fatti sulla Qualità dello Sperma dopo la Vaccinazione COVID-19",
        description: "La pandemia di COVID-19 non ha solo cambiato la vita quotidiana di molte persone, ma ha anche sollevato questioni sulla riproduzione umana.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/impf/impf.component').then(m => m.ImpfItComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Tutto sull'Inseminazione Intrauterina (IUI): Procedura, Costi e Probabilità di Successo",
        description: "Tutto sull'Inseminazione Intrauterina (IUI): Procedura, Costi e Probabilità di Successo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/iui/iui.component').then(m => m.IuiItComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Fecondazione In Vitro (FIV): Guida Completa: Costi, Fasi e Altro",
        description: "Fecondazione In Vitro (FIV): Guida Completa: Costi, Fasi e Altro",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/ivf/ivf.component').then(m => m.IvfItComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Malattie trasmissibili nella donazione di sperma",
        description: "La donazione di sperma permette a molte persone di realizzare il desiderio di avere un figlio – sia a donne single, coppie lesbiche o coppie eterosessuali con infertilità maschile.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/krankheiten/krankheiten.component').then(m => m.KrankheitenItComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Fecondazione artificiale: Metodi, probabilità di successo e costi in sintesi",
        description: "Fecondazione artificiale: Metodi, probabilità di successo e costi in sintesi",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/kunstl/kunstl.component').then(m => m.KunstlItComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Surrogacy in Italia: Questioni Legali, Costi e Alternative",
        description: "La maternità surrogata può rappresentare un’opzione importante per coloro che, per vie naturali o tramite altri procedimenti riproduttivi, non riescono a concepire il bambino desiderato.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/leihmut/leihmut.component').then(m => m.LeihmutItComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Pre-eiaculato e Gravidanza: È Possibile Rimanere Incinta dal Pre-eiaculato?",
        description: "Il pre-eiaculato, noto anche come liquido pre-eiaculatorio, rappresenta un mistero per molte persone: È possibile rimanere incinta da esso?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/lusttropfen/lusttropfen.component').then(m => m.LusttropfenItComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Sanguinamenti in gravidanza: cause e differenze con le mestruazioni",
        description: "Sanguinamenti in gravidanza: cause e differenze con le mestruazioni",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/mensschwang/mensschwang.component').then(m => m.MensschwangItComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Sindrome dell'Ovaio Policistico (PCOS): Cause, Sintomi e Trattamenti in Sintesi",
        description: "Sindrome dell'Ovaio Policistico (PCOS): Cause, Sintomi e Trattamenti in Sintesi",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/pco/pco.component').then(m => m.PCOItComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Crampi spermatici: smascherare un mito diffuso sul web",
        description: "Internet offre un flusso costante di informazioni, ma non tutte sono affidabili. Tra queste, termini come “Crampi spermatici” (“Sperm cramps” in inglese) catturano l’attenzione nonostante la mancanza di basi mediche.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/spermakrampf/spermakrampf.component').then(m => m.SpermakrampfItComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Come rimanere incinta più velocemente: 10 strategie comprovate per una gravidanza di successo",
        description: "Come rimanere incinta più velocemente: 10 strategie comprovate per una gravidanza di successo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/schnellschwang/schnellschwang.component').then(m => m.SchnellSchwangItComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Congestione Seminale, Dolore Testicolare, Testicoli Blu",
        description: "Congestione Seminale, Dolore Testicolare, Testicoli Blu",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/samenstau/samenstau.component').then(m => m.SamenstauItComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Spermiogramma: Tutto ciò che c'è da sapere sull'analisi della fertilità maschile",
        description: "Uno spermiogramma può aiutare a trovare le prime risposte e a identificare possibili ragioni per la mancata gravidanza. In questo articolo scoprirai cos'è uno spermiogramma, come viene eseguito il test e quali valori sono importanti nell'analisi.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/spermiogramm/spermiogramm.component').then(m => m.SpermiogramItComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "La magica leggenda della cicogna: un viaggio tra miti e simboli",
        description: "Perché proprio la cicogna è considerata il simbolo universale dei neonati?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/klapper/klapper.component').then(m => m.KlapperItComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: La Rivoluzione della Medicina Riproduttiva per il Trattamento dell'Infertilità Maschile",
        description: "ICSI: La Rivoluzione della Medicina Riproduttiva per il Trattamento dell'Infertilità Maschile",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/icsi/icsi.component').then(m => m.IcsiItComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Aumento di LH e test di ovulazione: come riconoscere i tuoi giorni fertili",
        description: "Vuoi capire meglio il tuo corpo, seguire il tuo ciclo in modo più accurato o stai pianificando una gravidanza? Allora sei nel posto giusto. In questo articolo scoprirai tutto sull’aumento di LH e il suo ruolo centrale nell’ovulazione.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/lh/lh.component').then(m => m.LhItComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Come le infezioni da clamidia influenzano la tua fertilità: sintomi, rischi e misure di protezione",
        description: "Come le infezioni da clamidia influenzano la tua fertilità: sintomi, rischi e misure di protezione",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/clam/clam.component').then(m => m.ClamItComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Intervista: La mia esperienza come figlia di donatore in una famiglia LGBTQ+",
        description: "Intervista: La mia esperienza come figlia di donatore in una famiglia LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/emilia/emilia.component').then(m => m.EmiliaItComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Aborto spontaneo: cause, sintomi e come trovare aiuto",
        description: "Aborto spontaneo: cause, sintomi e come trovare aiuto",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/fehlgeburt/fehlgeburt.component').then(m => m.FehlgeburtItComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Parto prematuro: cause, sintomi e panoramica delle moderne opzioni di trattamento",
        description: "Parto prematuro: cause, sintomi e panoramica delle moderne opzioni di trattamento",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/fruehgeb/fruehgeb.component').then(m => m.ClamItComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertilizzazione dopo Sterilizzazione: Come le Donne Possono Rimanere Incinte Dopo una Legatura delle Tube",
        description: "Le circostanze della vita cambiano – ciò che pochi anni fa era considerato una decisione definitiva può oggi essere rivalutato. Le donne che una volta hanno scelto la sterilizzazione (legatura delle tube) possono successivamente desiderare di rimanere incinte.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/referti-frau/referti-frau.component').then(m => m.RefertiFrauItComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertilizzazione dopo la Vasectomia – Come gli uomini possono ripristinare con successo la loro fertilità",
        description: "La decisione di sottoporsi a una vasectomia viene spesso presa quando si ritiene conclusa la pianificazione familiare. Tuttavia, la vita è piena di sorprese e cambiamenti – il desiderio di avere figli può risorgere rapidamente, ad esempio a causa di una nuova relazione o di obiettivi di vita modificati.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/referti-mann/referti-mann.component').then(m => m.RefertiMannItComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Crescita del seno e dolori al seno in gravidanza: cause, consigli e cura olistica",
        description: "La gravidanza è un periodo estremamente affascinante, in cui il tuo corpo cambia in molti modi. Questi cambiamenti si notano in particolare sul seno, che si prepara per l’allattamento imminente.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it//brustbeischwang/brustbeischwang.component').then(m => m.BrustbeischwangItComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Infertilità femminile: Le 10 cause più comuni e come riconoscerle",
        description: "Infertilità femminile: Le 10 cause più comuni e come riconoscerle",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/zehnunfruchtfrauen/zehnunfruchtfrauen.component').then(m => m.ZehnunfruchtfrauenItComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Quanto Tempo Vivono gli Spermatozoi? Una Guida Completa e Consigli Utili",
        description: "Ti sei mai chiesto quanto tempo possano sopravvivere gli spermatozoi e quali fattori influiscono sulla loro durata?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/ueberlebenspermien/ueberlebenspermien.component').then(m => m.UeberlebenspermienItComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Smettere di Prendere la Pillola: Motivi, Metodi e Cosa Succede Dopo",
        description: "Smettere di Prendere la Pillola: Motivi, Metodi e Cosa Succede Dopo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/pilleabsetzen/pilleabsetzen.component').then(m => m.PilleabsetzenItComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Comprendere il muco cervicale: come riconoscere i giorni fertili",
        description: "Il corpo femminile invia, prima e durante l'ovulazione, segnali sottili per indicare i giorni fertili.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/zervix/zervix.component').then(m => m.ZervixItComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Metodi naturali per ritardare la menopausa: Approcci scientifici e consigli nutrizionali",
        description: "Metodi naturali per ritardare la menopausa: Approcci scientifici e consigli nutrizionali",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/menopause/menopause.component').then(m => m.MenopauseItComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Cause e significato della linea scura durante la gravidanza",
        description: "Linea Nigra: Cause e significato della linea scura durante la gravidanza",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/linea_nigra/linea_nigra.component').then(m => m.Linea_nigraItComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Insufficienza del corpo luteo: cause, sintomi e opzioni di trattamento efficaci",
        description: "Insufficienza del corpo luteo: cause, sintomi e opzioni di trattamento efficaci",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/it/gelbkörperschwäche/gelbkörperschwäche.component').then(m => m.GelbkörperschwächeItComponent)
    },
  ];
  static readonly dutchRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Spermadonatie in Nederland: Wettelijke Regels, Tips en Hoe RattleStork Kan Helpen",
        description: "Spermadonatie is in Nederland een belangrijke en goed gereguleerde praktijk die zowel individuen als stellen helpt hun kinderwens te vervullen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Spermadonor worden in Nederland: Alles wat je moet weten – met RattleStork",
        description: "Spermadonor worden in Nederland is een betekenisvolle manier om anderen te helpen hun droom van een gezin te realiseren.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogMutterpass,
      data: {
        title: "Zwangerschapsdossier: Een Essentieel Document Voor Jouw Zwangerschap in Nederland",
        description: "Zwangerschapsdossier: Een Essentieel Document Voor Jouw Zwangerschap in Nederland",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/mutterpass/mutterpass.component').then(m => m.MutterpassNlComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Onvruchtbaarheid bij mannen: De 10 meest voorkomende oorzaken en hoe je ze kunt herkennen",
        description: "Onvruchtbaarheid bij mannen: De 10 meest voorkomende oorzaken en hoe je ze kunt herkennen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtNlComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Eiceldonatie in Nederland: Alles over kansen, risico’s en wetgeving",
        description: "In Nederland hebben veel wensouders te maken met vruchtbaarheidsproblemen. Wanneer een natuurlijke zwangerschap niet tot stand komt, kan eiceldonatie een mogelijke oplossing zijn.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeNlComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Nederlandse Spermabanken in Vergelijking: Kosten, Behandelingen en Vergoedingen",
        description: "Nederlandse Spermabanken in Vergelijking: Kosten, Behandelingen en Vergoedingen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/kostenaufwandbank copy/kostenaufwandbank.component').then(m => m.KostenaufwandbankNlComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Vergelijking van de beste vruchtbaarheidsklinieken in Nederland – Kosten en Diensten",
        description: "Vergelijking van de beste vruchtbaarheidsklinieken in Nederland – Kosten en Diensten",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteNlComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Pre-implantatie Genetische Diagnostiek (PGD) in Nederland: Procedures, Juridische Aspecten en Ervaringen",
        description: "Pre-implantatie Genetische Diagnostiek (PGD) in Nederland: Procedures, Juridische Aspecten en Ervaringen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/PID/PID.component').then(m => m.PIDNlComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: Een vernieuwende benadering van gezinsleven en opvoeding",
        description: "Co-Parenting is een innovatief gezinsmodel waarbij twee of meer volwassenen gezamenlijk verantwoordelijk zijn voor de opvoeding van een kind, zonder dat een romantische relatie vereist is.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/co-parenting/co-parenting.component').then(m => m.CoParentingNlComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "De ovulatie begrijpen: jouw sleutel tot een hogere vruchtbaarheid",
        description: "De ovulatie is het beslissende moment in de vrouwelijke cyclus: precies op dit punt wordt bepaald of bevruchting kan plaatsvinden.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/ei/ei.component').then(m => m.EiNlComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Innesteling: Definitie, Tijdstip en Essentiële Informatie over Vroege Zwangerschap",
        description: "Innesteling: Definitie, Tijdstip en Essentiële Informatie over Vroege Zwangerschap",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/einnistung/einnistung.component').then(m => m.EinnistungNlComponent)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Zelfinseminatie: Zwanger worden zonder seks",
        description: "Zelfinseminatie, ook wel kunstmatige inseminatie thuis genoemd, is een populaire methode voor stellen of individuen die zwanger willen worden zonder gemeenschap.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Intracervicale inseminatie (ICI): De zachte methode voor gezinsplanning",
        description: "Intracervicale inseminatie (ICI): De zachte methode voor gezinsplanning",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/ici/ici.component').then(m => m.IciNlComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Ongevaccineerd Sperma vs. Gevaccineerd Sperma: Feiten over Spermakwaliteit na de COVID-19-vaccinatie",
        description: "De COVID-19-pandemie heeft niet alleen het dagelijks leven van veel mensen veranderd, maar ook vragen opgeworpen over menselijke voortplanting.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/impf/impf.component').then(m => m.ImpfNlComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Alles over Intra-uteriene Inseminatie (IUI): Proces, Kosten en Slaagkansen",
        description: "Alles over Intra-uteriene Inseminatie (IUI): Proces, Kosten en Slaagkansen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/iui/iui.component').then(m => m.IuiNlComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "In-vitrofertilisatie (IVF): Uitgebreide gids: Kosten, stappen en meer",
        description: "In-vitrofertilisatie (IVF): Uitgebreide gids: Kosten, stappen en meer",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/ivf/ivf.component').then(m => m.IvfNlComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Besmettelijke aandoeningen bij de zaaddonatie",
        description: "Zaaddonatie maakt het voor veel mensen mogelijk om hun kinderwens te vervullen – of het nu gaat om alleenstaande vrouwen, lesbische stellen of heteroseksuele stellen met mannelijke onvruchtbaarheid.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/krankheiten/krankheiten.component').then(m => m.KrankheitenNlComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Overdraagbare ziekten bij spermadonatie: risico's en beschermingsmaatregelen",
        description: "Overdraagbare ziekten bij spermadonatie: risico's en beschermingsmaatregelen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/kunstl/kunstl.component').then(m => m.KunstlNlComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Draagmoederschap in Nederland: Juridische Kwesties, Kosten en Alternatieven",
        description: "Draagmoederschap kan een belangrijke optie zijn voor mensen die via natuurlijke bevruchting of andere voortplantingstechnieken niet tot een gezinsuitbreiding kunnen komen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/leihmut/leihmut.component').then(m => m.LeihmutNlComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Pre-ejaculaat en zwangerschap: Kan je zwanger worden door voorvocht?",
        description: "Pre-ejaculaat, ook wel bekend als voorvocht, vormt voor veel mensen een raadsel: Kan je daardoor zwanger worden?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/lusttropfen/lusttropfen.component').then(m => m.LusttropfenNlComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Zwangerschapsbloedingen: Oorzaken en Verschillen met Menstruatie",
        description: "Zwangerschapsbloedingen: Oorzaken en Verschillen met Menstruatie",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/mensschwang/mensschwang.component').then(m => m.MensschwangNlComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Polycysteus Ovarium Syndroom (PCOS): Oorzaken, Symptomen en Behandeling in Overzicht",
        description: "Polycysteus Ovarium Syndroom (PCOS): Oorzaken, Symptomen en Behandeling in Overzicht",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/pco/pco.component').then(m => m.PCONlComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Spermakrampen: Een internetmythe zonder medische grondslag",
        description: "Het internet is een bron van eindeloze informatie, maar niet alles wat u leest is betrouwbaar.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/spermakrampf/spermakrampf.component').then(m => m.SpermakrampfNlComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Hoe je sneller zwanger wordt: 10 bewezen strategieën voor een succesvolle zwangerschap",
        description: "Hoe je sneller zwanger wordt: 10 bewezen strategieën voor een succesvolle zwangerschap",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/schnellschwang/schnellschwang.component').then(m => m.SchnellSchwangNlComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Zaadstuwing, Testikelpijn, Blauwe Ballen",
        description: "Zaadstuwing, Testikelpijn, Blauwe Ballen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/samenstau/samenstau.component').then(m => m.SamenstauNlComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Spermiogram: Alles Belangrijke over de Analyse van de Mannelijke Vruchtbaarheid",
        description: "Een spermiogram kan helpen om eerste antwoorden te vinden en mogelijke oorzaken voor de uitblijvende zwangerschap te identificeren. In dit artikel leest u wat een spermiogram is, hoe de test precies verloopt en welke waarden een rol spelen bij de uitwerking.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/spermiogramm/spermiogramm.component').then(m => m.SpermiogramNlComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "De magie van de ooievaar: Waarom brengt dit dier kinderen?",
        description: "Heb je je ooit afgevraagd waarom de ooievaar symbool staat voor baby's?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/klapper/klapper.component').then(m => m.KlapperNlComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: De Revolutie van de Reproductieve Geneeskunde voor de Behandeling van Mannelijke Onvruchtbaarheid",
        description: "ICSI: De Revolutie van de Reproductieve Geneeskunde voor de Behandeling van Mannelijke Onvruchtbaarheid",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/icsi/icsi.component').then(m => m.IcsiNlComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "LH-stijging en ovulatietests: Zo herken je jouw vruchtbare dagen",
        description: "Wil je je lichaam beter leren begrijpen, je cyclus nauwkeuriger in de gaten houden of plan je een zwangerschap? Dan ben je hier aan het juiste adres. In dit artikel lees je alles over de LH-stijging en de centrale rol die het speelt bij de ovulatie.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/lh/lh.component').then(m => m.LhNlComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Hoe Chlamydia-infecties uw vruchtbaarheid beïnvloeden: Symptomen, risico's en beschermende maatregelen",
        description: "Hoe Chlamydia-infecties uw vruchtbaarheid beïnvloeden: Symptomen, risico's en beschermende maatregelen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/clam/clam.component').then(m => m.ClamNlComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Interview: Mijn ervaringen als donorkind in een LGBTQ+-gezin",
        description: "Interview: Mijn ervaringen als donorkind in een LGBTQ+-gezin",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/emilia/emilia.component').then(m => m.EmiliaNlComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Miskraam: Oorzaken, Symptomen en hoe u hulp kunt vinden",
        description: "Miskraam: Oorzaken, Symptomen en hoe u hulp kunt vinden",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/fehlgeburt/fehlgeburt.component').then(m => m.FehlgeburtNlComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Vroeggeboorte: oorzaken, symptomen en overzicht van moderne behandelingsopties",
        description: "Vroeggeboorte: oorzaken, symptomen en overzicht van moderne behandelingsopties",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/fruehgeb/fruehgeb.component').then(m => m.ClamNlComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertiliteit na Sterilisatie: Hoe Vrouwen Na een Tubal Ligatie Toch Zwanger Kunnen Worden",
        description: "Levensomstandigheden veranderen – wat enkele jaren geleden nog als een definitieve beslissing werd beschouwd, kan vandaag de dag anders worden beoordeeld. Vrouwen die ooit voor sterilisatie (tubal ligatie) hebben gekozen, kunnen later weer de wens voelen om zwanger te worden.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/referti-frau/referti-frau.component').then(m => m.RefertiFrauNlComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertiliteit na Vasectomie – Hoe Mannen Hun Vruchtbaarheid Succesvol Kunnen Herstellen",
        description: "De beslissing voor een vasectomie wordt vaak genomen wanneer gezinsplanning als afgerond wordt beschouwd. Maar het leven zit vol verrassingen en veranderingen – een hernieuwde wens om kinderen te krijgen kan snel ontstaan, bijvoorbeeld door een nieuwe relatie of veranderde levensdoelen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/referti-mann/referti-mann.component').then(m => m.RefertiMannNlComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Borstgroei en borstkanker tijdens de zwangerschap: Oorzaken, tips en holistische verzorging",
        description: "De zwangerschap is een ongelooflijk spannende periode waarin je lichaam op vele manieren verandert. Deze veranderingen zijn vooral duidelijk zichtbaar aan de borsten, die worden voorbereid op het komende geven van borstvoeding.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl//brustbeischwang/brustbeischwang.component').then(m => m.BrustbeischwangNlComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Onvruchtbaarheid bij vrouwen: De 10 meest voorkomende oorzaken en hoe je ze herkent",
        description: "Onvruchtbaarheid bij vrouwen: De 10 meest voorkomende oorzaken en hoe je ze herkent",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/zehnunfruchtfrauen/zehnunfruchtfrauen.component').then(m => m.ZehnunfruchtfrauenNlComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Hoe lang blijven zaadcellen leven? - Belangrijke factoren en praktische tips",
        description: "Zaadcellen spelen een cruciale rol in de voortplanting, maar hoe lang blijven ze eigenlijk in leven?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/ueberlebenspermien/ueberlebenspermien.component').then(m => m.UeberlebenspermienNlComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Stoppen met de Pil: Redenen, Methoden en Wat Daarna Gebeurt",
        description: "Stoppen met de Pil: Redenen, Methoden en Wat Daarna Gebeurt",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/pilleabsetzen/pilleabsetzen.component').then(m => m.PilleabsetzenNlComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Kosten voor kunstmatige voortplanting in Nederland: Hoe duur wordt het?",
        description: "Kunstmatige voortplanting kan de droom van een eigen kind werkelijkheid maken – maar in Nederland gaat dit vaak gepaard met aanzienlijke kosten.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/kostenAI/kostenAI.component').then(m => m.KostenaiNlComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Cervixslijm begrijpen: Zo herkent u uw vruchtbare dagen",
        description: "Het vrouwelijke lichaam zendt voor en tijdens de eisprong subtiele signalen uit om zijn vruchtbare dagen aan te geven.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/zervix/zervix.component').then(m => m.ZervixNlComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Natuurlijke Manieren om de Menopauze te Vertragen: Wetenschappelijk Onderbouwde Methoden en Voedingsadviezen",
        description: "Natuurlijke Manieren om de Menopauze te Vertragen: Wetenschappelijk Onderbouwde Methoden en Voedingsadviezen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/menopause/menopause.component').then(m => m.MenopauseNlComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Oorzaken en Betekenis van de Donkere Lijn tijdens de Zwangerschap",
        description: "Linea Nigra: Oorzaken en Betekenis van de Donkere Lijn tijdens de Zwangerschap",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/linea_nigra/linea_nigra.component').then(m => m.Linea_nigraNlComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Luteale Fase Deficiëntie: Oorzaken, Symptomen en Effectieve Behandelingsopties",
        description: "Luteale Fase Deficiëntie: Oorzaken, Symptomen en Effectieve Behandelingsopties",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/nl/gelbkörperschwäche/gelbkörperschwäche.component').then(m => m.GelbkörperschwächeNlComponent)
    }
  ];
  static readonly polishRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Dawstwo nasienia w Polsce: Przepisy prawne, wskazówki i jak RattleStork może pomóc",
        description: "Dawstwo nasienia w Polsce to coraz popularniejsza opcja dla osób i par marzących o posiadaniu dziecka.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Zostań dawcą nasienia w Polsce: Kompletny przewodnik",
        description: "Dawstwo nasienia w Polsce to wyjątkowa możliwość, aby pomóc innym w realizacji ich marzeń o rodzicielstwie.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Dawstwo komórek jajowych w Polsce: Szanse, ryzyko i przepisy prawne",
        description: "Niepłodność stanowi poważne wyzwanie dla wielu par w Polsce. Osoby, które pragną dziecka, lecz nie mogą zajść w ciążę w sposób naturalny, często rozważają różne metody wspomaganego rozrodu, w tym dawstwo komórek jajowych (zwane też eizellenspende).",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/eizellenspende/eizellenspende.component').then(m => m.EizellenspendePlComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Koszty sztucznego zapłodnienia w Polsce: ile to kosztuje?",
        description: "Sztuczne zapłodnienie może spełnić marzenie o posiadaniu własnego dziecka, jednak w Polsce często wiąże się z wysokimi kosztami.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/kostenAI/kostenAI.component').then(m => m.KostenaiNlComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Niepłodność u mężczyzn: 10 najczęstszych przyczyn i jak je rozpoznać",
        description: "Niepłodność u mężczyzn: 10 najczęstszych przyczyn i jak je rozpoznać",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtPlComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Polskie Banki Spermy w Porównaniu: Koszty, Zabiegi i Wynagrodzenia",
        description: "Polskie Banki Spermy w Porównaniu: Koszty, Zabiegi i Wynagrodzenia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankPlComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Porównanie najlepszych klinik leczenia niepłodności w Polsce – Koszty i usługi",
        description: "Porównanie najlepszych klinik leczenia niepłodności w Polsce – Koszty i usługi",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasitePlComponent)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Metoda Kubeczkowa: Zajście w ciążę bez stosunku - Inseminacja Domowa",
        description: "Metoda Kubeczkowa, znana również jako Inseminacja Domowa, to popularna opcja dla par i osób indywidualnych, które chcą zajść w ciążę bez współżycia.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnostyka Genetyczna Preimplantacyjna (PGD) w Polsce: Procedury, Aspekty Prawne i Doświadczenia",
        description: "Diagnostyka Genetyczna Preimplantacyjna (PGD) w Polsce: Procedury, Aspekty Prawne i Doświadczenia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/PID/PID.component').then(m => m.PIDPlComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: Nowoczesne podejście do rodzicielstwa i budowania rodziny",
        description: "Co-Parenting to nowoczesny i elastyczny model wychowania dziecka, który polega na dzieleniu odpowiedzialności za dziecko przez dwóch lub więcej dorosłych, niezależnie od tego, czy są w romantycznej relacji.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/co-parenting-en/co-parenting-en.component').then(m => m.CoParentingPlComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Preejakulat i ciąża: Czy można zajść w ciążę przez preejakulat?",
        description: "Preejakulat, znany również jako płyn przedekulacyjny, stanowi zagadkę dla wielu osób: Czy można zajść w ciążę z jego udziałem?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/pre-ejaculate/pre-ejaculate.component').then(m => m.PreEjaculatePlComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Zastój spermy, ból jąder i niebieskie jądra: Co warto wiedzieć?",
        description: "Dowiedz się, czym jest zastój nasienia, co to są 'niebieskie jądra' i jak można skutecznie zapobiegać bólowi kawalerskiemu. Praktyczne porady i informacje na temat zdrowia seksualnego.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/samenstau-en/samenstau-en.component').then(m => m.SamenstauPlComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Mit o skurczach nasienia: internetowy fenomen bez podstaw naukowych",
        description: "Internet jest przestrzenią, w której informacje rozprzestrzeniają się błyskawicznie – zarówno te wartościowe, jak i całkowicie nieprawdziwe.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/sperm-cramps/sperm-cramps.component').then(m => m.SpermCrampsPlComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantacja: definicja, czas i istotne informacje dotyczące wczesnej ciąży",
        description: "Implantacja: definicja, czas i istotne informacje dotyczące wczesnej ciąży",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/einnistung-en/einnistung-en.component').then(m => m.EinnistungPlComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminacja domaciczna (ICI): Delikatna metoda planowania rodziny",
        description: "Inseminacja domaciczna (ICI): Delikatna metoda planowania rodziny",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/ici-en/ici-en.component').then(m => m.IciPlComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Zapłodnienie in vitro (IVF): Kompleksowy przewodnik: Koszty, kroki i więcej",
        description: "Zapłodnienie in vitro (IVF): Kompleksowy przewodnik: Koszty, kroki i więcej",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/ivf-en/ivf-en.component').then(m => m.IvfPlComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Macierzyństwo zastępcze w Polsce: Prawne zagadnienia, koszty i alternatywy",
        description: "Macierzyństwo zastępcze może być ważną opcją dla osób, które nie mogą mieć dziecka w tradycyjny sposób lub przy użyciu innych metod wspomaganego rozrodu.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/leihmut-en/leihmut-en.component').then(m => m.LeihmutPlComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Zespół policystycznych jajników (PCOS): Przyczyny, objawy i leczenie w skrócie",
        description: "Zespół policystycznych jajników (PCOS): Przyczyny, objawy i leczenie w skrócie",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/pco-en/pco-en.component').then(m => m.PcoPlComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Analiza nasienia: Wszystko, co ważne w analizie męskiej płodności",
        description: "Analiza nasienia może pomóc znaleźć pierwsze odpowiedzi i zidentyfikować możliwe przyczyny braku ciąży. W tym artykule dowiesz się, czym jest spermiogram, jak dokładnie przebiega test oraz jakie wartości odgrywają rolę przy jego ocenie.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/spermiogramm-en/spermiogramm-en.component').then(m => m.SpermiogrammPlComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "Legenda o bocianie: Dlaczego ten ptak przynosi dzieci?",
        description: "Od wieków bocian jest symbolem narodzin, ale skąd właściwie wzięła się ta tradycja?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/klapper-en/klapper-en.component').then(m => m.KlapperPlComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Jak szybciej zajść w ciążę: 10 sprawdzonych strategii na udaną ciążę",
        description: "Jak szybciej zajść w ciążę: 10 sprawdzonych strategii na udaną ciążę",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/schnell-en/schnell-en.component').then(m => m.SchnellPlComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Choroby przenoszone przy dawaniu nasienia",
        description: "Dawanie nasienia umożliwia wielu osobom spełnienie marzenia o posiadaniu dzieci – zarówno samotnym kobietom, jak i parom lesbijek czy parom heteroseksualnym, u których mężczyzna cierpi na niepłodność.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/krank-en/krank-en.component').then(m => m.KrankPlComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Sztuczne zapłodnienie: Metody, szanse powodzenia i koszty",
        description: "Sztuczne zapłodnienie: Metody, szanse powodzenia i koszty",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/kunst-en/kunst-en.component').then(m => m.KunstPlComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Krwawienia w ciąży: przyczyny i różnice w porównaniu z menstruacją",
        description: "Krwawienia w ciąży: przyczyny i różnice w porównaniu z menstruacją",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/mensschw-en/mensschw-en.component').then(m => m.MensschwPlComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Niezaszczepione Spermy vs. Zaszczepione Spermy: Fakty na temat Jakości Spermy po Szczepieniu Przeciw COVID-19",
        description: "Pandemia COVID-19 nie tylko zmieniła codzienne życie wielu osób, ale również wywołała pytania dotyczące ludzkiego rozrodu.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/impf-en/impf-en.component').then(m => m.ImpfPlComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Zrozumienie owulacji: Twój klucz do większej płodności",
        description: "Owulacja to decydujący moment w cyklu kobiecym: to właśnie wtedy decyduje się, czy dojdzie do zapłodnienia.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/ei-en/ei-en.component').then(m => m.EiPlComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Wszystko o inseminacji wewnątrzmacicznej (IUI): procedura, koszty i skuteczność",
        description: "Wszystko o inseminacji wewnątrzmacicznej (IUI): procedura, koszty i skuteczność",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/iui-en/iui-en.component').then(m => m.IuiPlComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: Rewolucja w medycynie reprodukcyjnej w leczeniu męskiej niepłodności",
        description: "ICSI: Rewolucja w medycynie reprodukcyjnej w leczeniu męskiej niepłodności",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/icsi/icsi.component').then(m => m.IcsiPlComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Wzrost LH i testy owulacyjne: Jak rozpoznać swoje dni płodne",
        description: "Chcesz lepiej poznać swoje ciało, dokładniej monitorować cykl lub planujesz ciążę? Jesteś we właściwym miejscu",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/lh/lh.component').then(m => m.LhPlComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Jak infekcje Chlamydia wpływają na twoją płodność: Objawy, ryzyka i środki zapobiegawcze",
        description: "Jak infekcje Chlamydia wpływają na twoją płodność: Objawy, ryzyka i środki zapobiegawcze",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/clam-en/clam-en.component').then(m => m.ChlamydiaPlComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Wywiad: Moje doświadczenia jako dziecko dawcy w rodzinie LGBTQ+",
        description: "Wywiad: Moje doświadczenia jako dziecko dawcy w rodzinie LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/emilia-en/emilia-en.component').then(m => m.EmiliaPlComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Poronienie: Przyczyny, objawy i jak znaleźć pomoc",
        description: "Poronienie: Przyczyny, objawy i jak znaleźć pomoc",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtPlComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Przedwczesny poród: przyczyny, objawy i przegląd nowoczesnych opcji leczenia",
        description: "Przedwczesny poród: przyczyny, objawy i przegląd nowoczesnych opcji leczenia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtPlComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertylizacja po Sterylizacji: Jak Kobiety Mogą Ponownie Zachodzić w Ciążę po Ligaturze Jajowodów",
        description: "Warunki życiowe się zmieniają – to, co kilka lat temu uważano za ostateczną decyzję, dziś może być oceniane inaczej. Kobiety, które kiedyś zdecydowały się na sterylizację (ligaturę jajowodów), mogą później odczuwać pragnienie ponownego zajścia w ciążę.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauPlComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertylizacja po wazektomii – Jak mężczyźni mogą skutecznie odzyskać płodność",
        description: "Decyzja o wazektomii często zapada, gdy planowanie rodziny jest uznawane za zakończone. Jednak życie pełne jest niespodzianek i zmian – ponowna chęć posiadania dzieci może szybko się pojawić, na przykład w wyniku nowego związku lub zmienionych celów życiowych.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannPlComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Rozrost piersi i ból piersi w ciąży: Przyczyny, porady i holistyczna pielęgnacja",
        description: "Ciąża to niesamowicie ekscytujący czas, w którym twoje ciało zmienia się na wiele sposobów. Zmiany te są szczególnie widoczne w piersiach, które przygotowywane są na nadchodzący okres karmienia piersią.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/brust-en/brust-en.component').then(m => m.BrustPlComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Niepłodność u kobiet: 10 najczęstszych przyczyn i jak je rozpoznać",
        description: "Niepłodność u kobiet: 10 najczęstszych przyczyn i jak je rozpoznać",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenPlComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Jak długo żyją plemniki? Kluczowe czynniki i praktyczne porady",
        description: "Czy kiedykolwiek zastanawiałeś się, jak długo plemniki mogą przetrwać w różnych warunkach? W tym artykule znajdziesz szczegółowe informacje, które rozwieją wszelkie wątpliwości.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienPlComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Odstawienie Pigułki: Powody, Metody i Co Dzieje Się Później",
        description: "Odstawienie Pigułki: Powody, Metody i Co Dzieje Się Później",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenPlComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Zrozumieć śluz szyjkowy: Jak rozpoznać dni płodne",
        description: "Kobiece ciało wysyła przed owulacją i w jej trakcie subtelne sygnały, aby zaznaczyć dni płodne.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/zervix-en/zervix-en.component').then(m => m.ZervixPlComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Naturalne sposoby na opóźnienie menopauzy: Metody naukowe i porady żywieniowe",
        description: "Naturalne sposoby na opóźnienie menopauzy: Metody naukowe i porady żywieniowe",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/menopause-en/menopause-en.component').then(m => m.MenopausePlComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Przyczyny i znaczenie ciemnej linii podczas ciąży",
        description: "Linea Nigra: Przyczyny i znaczenie ciemnej linii podczas ciąży",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/linea-en/linea-en.component').then(m => m.LineaNigraPlComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Niedoczynność ciałka żółtego: przyczyny, objawy i skuteczne opcje leczenia",
        description: "Niedoczynność ciałka żółtego: przyczyny, objawy i skuteczne opcje leczenia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pl/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächePlComponent)
    },
  ];
  static readonly turkeyRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Türkiye'de Sperm Bağışı: Hukuki Temeller, İpuçları ve RattleStork'un Yardımcı Olabileceği Konular",
        description: "Türkiye'de sperm bağışı, yasal düzenlemeler ve toplumsal algılar nedeniyle oldukça kısıtlı bir şekilde ele alınmaktadır.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Türkiye’de Sperm Bağışçısı Olmak: Bilmeniz Gereken Her Şey – RattleStork ile",
        description: "Türkiye’de sperm bağışçısı olmak yasal olarak mümkün değildir. Türk yasaları, yalnızca evli çiftlerin kendi yumurtaları ve spermleriyle tüp bebek tedavisi yapmalarına izin vermektedir.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Türkiye’de Yumurtalık Bağışı: Fırsatlar, Riskler ve Hukuki Düzenlemeler",
        description: "Türkiye’de kısırlık, birçok çift ve birey için önemli bir sağlık sorunu oluşturmaktadır. Doğal yoldan gebelik gerçekleşemediğinde, yumurtalık bağışı (ovum donation) önemli bir alternatif olarak gündeme gelmektedir.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeTrComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Türkiye'deki Sperm Bankalarının Karşılaştırması: Maliyetler, Tedaviler ve Ücretler",
        description: "Türkiye'deki Sperm Bankalarının Karşılaştırması: Maliyetler, Tedaviler ve Ücretler",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankTrComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Türkiye'deki En İyi Tüp Bebek Merkezlerinin Karşılaştırması – Maliyetler ve Hizmetler",
        description: "Türkiye'deki En İyi Tüp Bebek Merkezlerinin Karşılaştırması – Maliyetler ve Hizmetler",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteTrComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Erkeklerde Kısırlık: En Yaygın 10 Sebep ve Nasıl Tanıyabilirsin",
        description: "Erkeklerde Kısırlık: En Yaygın 10 Sebep ve Nasıl Tanıyabilirsin",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtTrComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Polikistik Over Sendromu (PCOS): Nedenler, Belirtiler ve Tedavi Yöntemleri",
        description: "Polikistik Over Sendromu (PCOS): Nedenler, Belirtiler ve Tedavi Yöntemleri",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/pco-en/pco-en.component').then(m => m.PcoTrComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Türkiye'de Yapay Döllenme Maliyetleri: Maliyetler Ne Kadar?",
        description: "Yapay döllenme, kendi çocuğunu sahibi olma hayalini gerçekleştirebilir – ancak Türkiye'de bu süreç genellikle önemli maliyetlerle ilişkilidir.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/kostenAI/kostenAI.component').then(m => m.KostenaiTrComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "İmplantasyon Öncesi Genetik Tanı (PGD) Türkiye'de: Prosedürler, Hukuki Boyutlar ve Deneyimler",
        description: "İmplantasyon Öncesi Genetik Tanı (PGD) Türkiye'de: Prosedürler, Hukuki Boyutlar ve Deneyimler",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/PID/PID.component').then(m => m.PIDTrComponent)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Evde İnseminasyon: Seks Olmadan Hamile Kalmak - Aşılama Yöntemi",
        description: "Evde inseminasyon, çiftlere veya bireylere cinsel ilişki olmadan hamile kalmak için popüler bir yöntem sunar.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: Aile ve Ebeveynliğin Yeni Yolu",
        description: "Co-Parenting, romantik bir ilişki olmadan, iki veya daha fazla yetişkinin birlikte bir çocuk yetiştirdiği bir aile modelidir.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/co-parenting-en/co-parenting-en.component').then(m => m.CoParentingTrComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Ön Meni ve Gebelik: Ön Meni Yoluyla Gebelik Mümkün mü?",
        description: "Ön meni, diğer adıyla pre-ejakülat, birçok insan için bir muammadır: Ondan gebelik mümkün müdür? Hastalıkların bulaşması konusunda ne durum söz konusudur?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/pre-ejaculate/pre-ejaculate.component').then(m => m.PreEjaculateTrComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Önsevişme Sıvısı ve Gebelik: Önsevişme Sıvısı ile Hamile Kalınır mı? Gerçek Risk Nedir?",
        description: "Önsevişme Sıvısı ve Gebelik: Önsevişme Sıvısı ile Hamile Kalınır mı? Gerçek Risk Nedir?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/samenstau-en/samenstau-en.component').then(m => m.SamenstauTrComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Sperm Krampı: Tıbbi Gerçek mi, Yoksa İnternet Efsanesi mi?",
        description: "İnternet, bilgi paylaşımının hızla gerçekleştiği sınırsız bir dünya sunuyor. Ancak bu dünyada, bazı kavramlar gerçeklikten uzak olmasına rağmen hızla yayılabiliyor.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/sperm-cramps/sperm-cramps.component').then(m => m.SpermCrampsTrComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Yerleşme: Tanım, Zamanlama ve Erken Gebelikle İlgili Temel Bilgiler",
        description: "Yerleşme: Tanım, Zamanlama ve Erken Gebelikle İlgili Temel Bilgiler",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/einnistung-en/einnistung-en.component').then(m => m.EinnistungTrComponent)
    },

    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Sperm Analizi: Erkek Doğurganlığının Analizine İlişkin Tüm Önemli Bilgiler",
        description: "Bir spermiyogram, ilk yanıtları bulmaya ve gebeliğin gerçekleşmemesinin olası nedenlerini belirlemeye yardımcı olabilir. Bu makalede, spermiyogramın ne olduğunu, testin nasıl yapıldığını ve değerlendirmede hangi değerlerin önemli olduğunu öğreneceksiniz.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/spermiogramm-en/spermiogramm-en.component').then(m => m.SpermiogrammTrComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "Leylek Efsanesi: Bebekleri Neden Leylek Getirir?",
        description: "Leyleklerin neden bebek sembolü olduğunu hiç merak ettiniz mi? Bu efsanenin kökenleri, sandığınızdan daha eskiye dayanır.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/klapper-en/klapper-en.component').then(m => m.KlapperTrComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Daha Hızlı Hamile Kalmanın Yolları: Başarılı Bir Hamilelik İçin 10 Kanıtlanmış Strateji",
        description: "Daha Hızlı Hamile Kalmanın Yolları: Başarılı Bir Hamilelik İçin 10 Kanıtlanmış Strateji",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/schnell-en/schnell-en.component').then(m => m.SchnellTrComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Sperm Bağışında Bulaşıcı Hastalıklar: Riskler ve Korunma Yöntemleri",
        description: "Sperm Bağışında Bulaşıcı Hastalıklar: Riskler ve Korunma Yöntemleri",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/krank-en/krank-en.component').then(m => m.KrankTrComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Sperm Bağışı Sırasında Bulaşıcı Hastalıklar",
        description: "Sperm bağışı, bekar kadınlar, lezbiyen çiftler veya erkek infertilitesi yaşayan heteroseksüel çiftler de dahil olmak üzere birçok kişinin çocuk sahibi olma hayalini gerçekleştirmesine olanak tanır.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/kunst-en/kunst-en.component').then(m => m.KunstTrComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Hamilelikte Kanama: Nedenleri ve Adetle Farkları",
        description: "Hamilelikte Kanama: Nedenleri ve Adetle Farkları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/mensschw-en/mensschw-en.component').then(m => m.MensschwTrComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Aşısız Spermler vs. Aşılı Spermler: COVID-19 Aşısı Sonrası Sperm Kalitesi Hakkındaki Gerçekler",
        description: "COVID-19 pandemisi, birçok insanın günlük yaşamını değiştirmekle kalmadı, aynı zamanda insan üremesiyle ilgili soruları da gündeme getirdi.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/impf-en/impf-en.component').then(m => m.ImpfTrComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Ovülasyonu Anlamak: Daha Yüksek Doğurganlık İçin Anahtarınız",
        description: "Ovülasyon, kadın döngüsündeki belirleyici andır: Tam da bu noktada döllenmenin gerçekleşip gerçekleşemeyeceğine karar verilir.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/ei-en/ei-en.component').then(m => m.EiTrComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "İntrauterin İnseminasyon (IUI) Hakkında Her Şey: Süreç, Maliyetler ve Başarı Oranları",
        description: "İntrauterin İnseminasyon (IUI) Hakkında Her Şey: Süreç, Maliyetler ve Başarı Oranları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/iui-en/iui-en.component').then(m => m.IuiTrComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: Erkek Kısırlığının Tedavisi İçin Üreme Tıbbında Devrim",
        description: "ICSI: Erkek Kısırlığının Tedavisi İçin Üreme Tıbbında Devrim",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/icsi/icsi.component').then(m => m.IcsiTrComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "İntraservikal İnseminasyon (ICI): Aile Planlamasında Hassas Yöntem",
        description: "İntraservikal İnseminasyon (ICI): Aile Planlamasında Hassas Yöntem",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/ici-en/ici-en.component').then(m => m.IciTrComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Tüp Bebek (IVF): Kapsamlı Rehber: Maliyetler, Adımlar ve Daha Fazlası",
        description: "Tüp Bebek (IVF): Kapsamlı Rehber: Maliyetler, Adımlar ve Daha Fazlası",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/ivf-en/ivf-en.component').then(m => m.IvfTrComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "LH Artışı ve Ovülasyon Testleri: Verimli Günlerini Nasıl Tanırsın",
        description: "Vücudunu daha iyi tanımak, döngünü daha yakından izlemek veya hamile kalmayı mı planlıyorsun? O zaman doğru yerdesin. Bu yazıda, LH artışı ve ovülasyondaki merkezi rolü hakkında her şeyi öğreneceksin",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/lh/lh.component').then(m => m.LhTrComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Türkiye’de Taşıyıcı Anneliğin Durumu: Hukuki Sorunlar, Maliyetler ve Alternatifler",
        description: "Taşıyıcı annelik, bazı bireyler veya çiftler için doğal yollarla ya da diğer üreme yöntemleriyle arzuladıkları çocuğa ulaşamadıkları durumlarda önemli bir seçenek olarak görülmektedir.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/leihmut-en/leihmut-en.component').then(m => m.LeihmutTrComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Klamidya Enfeksiyonlarının Doğurganlığınızı Nasıl Etkilediği: Belirtiler, Riskler ve Korunma Yolları",
        description: "Klamidya Enfeksiyonlarının Doğurganlığınızı Nasıl Etkilediği: Belirtiler, Riskler ve Korunma Yolları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/clam-en/clam-en.component').then(m => m.ChlamydiaTrComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Röportaj: LGBTQ+ Bir Ailede Bağışçı Çocuk Olarak Deneyimlerim",
        description: "Röportaj: LGBTQ+ Bir Ailede Bağışçı Çocuk Olarak Deneyimlerim",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/emilia-en/emilia-en.component').then(m => m.EmiliaTrComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Düşük: Nedenler, Belirtiler ve Yardım Bulmanın Yolları",
        description: "Düşük: Nedenler, Belirtiler ve Yardım Bulmanın Yolları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtTrComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Erken Doğum: Nedenler, Belirtiler ve Modern Tedavi Seçeneklerine Genel Bakış",
        description: "Erken Doğum: Nedenler, Belirtiler ve Modern Tedavi Seçeneklerine Genel Bakış",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtTrComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Sterilizasyondan Sonra Refertilizasyon: Fallop Tüpleri Bağlanmış Kadınlar Nasıl Yine Gebe Kalabilir?",
        description: "Yaşam koşulları değişiyor – birkaç yıl önce kesin bir karar olarak görülen şeyler bugün farklı değerlendirilebiliyor. Bir zamanlar sterilizasyon (fallop tüplerinin bağlanması) yoluna giden kadınlar, daha sonra yine hamile kalma arzusunu hissedebilirler.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauTrComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Vazektomi Sonrası Refertilizasyon – Erkekler Doğurganlıklarını Nasıl Başarıyla Geri Kazanabilirler",
        description: "Vazektomi kararı genellikle aile planlamasının tamamlandığı düşünüldüğünde verilir. Ancak yaşam sürprizler ve değişikliklerle doludur – yeni bir çocuk isteği, yeni bir ilişki veya değişen yaşam hedefleri nedeniyle hızla ortaya çıkabilir.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannTrComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Gebelikte Göğüs Büyümesi ve Göğüs Ağrıları: Nedenler, İpuçları ve Bütünsel Bakım",
        description: "Gebelik, bedeninizin pek çok yönden değiştiği inanılmaz derecede heyecan verici bir dönemdir. Bu değişiklikler özellikle, emzirme dönemine hazırlık olarak öne çıkan göğüslerde belirgin şekilde görülür.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/brust-en/brust-en.component').then(m => m.BrustTrComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Kadınlarda Kısırlık: En Yaygın 10 Neden ve Nasıl Tanınır?",
        description: "Kadınlarda Kısırlık: En Yaygın 10 Neden ve Nasıl Tanınır?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenTrComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Spermler Ne Kadar Süre Hayatta Kalır? Bilimsel Veriler ve Pratik Bilgiler",
        description: "Spermlerin yaşam süresi, çevresel faktörlere ve bulundukları ortamın koşullarına bağlıdır.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienTrComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Doğum Kontrol Hapını Bırakmak: Nedenler, Yöntemler ve Sonrasında Ne Olur",
        description: "Doğum Kontrol Hapını Bırakmak: Nedenler, Yöntemler ve Sonrasında Ne Olur",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenTrComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Servikal Mukus Anlamak: Doğurgan Günlerinizi Nasıl Belirlersiniz",
        description: "Kadın bedeni, yumurtlamadan önce ve sırasında doğurgan günlerini göstermek için ince sinyaller gönderir.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/zervix-en/zervix-en.component').then(m => m.ZervixTrComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Menopozu Geciktirmenin Doğal Yolları: Bilimsel Yöntemler ve Beslenme Tavsiyeleri",
        description: "Menopozu Geciktirmenin Doğal Yolları: Bilimsel Yöntemler ve Beslenme Tavsiyeleri",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/menopause-en/menopause-en.component').then(m => m.MenopauseTrComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Hamilelik Sırasında Oluşan Koyu Çizginin Nedenleri ve Anlamı",
        description: "Linea Nigra: Hamilelik Sırasında Oluşan Koyu Çizginin Nedenleri ve Anlamı",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/linea-en/linea-en.component').then(m => m.LineaNigraTrComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Luteal Faz Yetmezliği: Nedenleri, Belirtileri ve Etkili Tedavi Seçenekleri",
        description: "Luteal Faz Yetmezliği: Nedenleri, Belirtileri ve Etkili Tedavi Seçenekleri",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/tr/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächeTrComponent)
    },
  ];
  static readonly GBRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Home Insemination: A Private Way to Conceive Without Sex",
        description: "Home insemination, often referred to as DIY artificial insemination, is a popular option in the UK for individuals and couples seeking a private and cost-effective way to conceive without sexual intercourse.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Sperm Donation in the UK: Legal Framework, Tips, and How RattleStork Can Help",
        description: "Sperm donation in the United Kingdom is a well-established method to assist various family structures in achieving their desire for children.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Becoming a Sperm Donor in the UK: Everything You Need to Know – with RattleStork",
        description: "Becoming a sperm donor in the United Kingdom is a meaningful way for men to help others achieve their dreams of starting a family.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "The Stork Legend Explained: Why Storks Are Said to Bring Babies",
        description: "Have you ever wondered why the stork is seen as a symbol of babies?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/klapper/klapper.component').then(m => m.KlapperComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "How Long Do Sperm Live? Insights, Factors, and Practical Tips",
        description: "How long can sperm survive, and what factors influence their lifespan?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/ueberlebenspermien/ueberlebenspermien.component').then(m => m.UeberlebenspermienDeComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Debunking the Myth of Sperm Cramps: Fact vs Fiction",
        description: "The digital world is filled with rapidly spreading information, some of which can be misleading or outright fictitious.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/spermakrampf/spermakrampf.component').then(m => m.SpermakrampfDeComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: A Modern Approach to Family and Parenting",
        description: "Co-Parenting is an innovative family model where two or more adults share responsibility for raising a child, regardless of whether they are in a romantic relationship.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/co-parenting/co-parenting.component').then(m => m.CoParentingDeComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Blue Balls, Testicular Pain, and Sperm Retention",
        description: "Blue Balls, Testicular Pain, and Sperm Retention",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/samenstau/samenstau.component').then(m => m.SamenstauDeComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Pre-ejaculate and Pregnancy: Can You Get Pregnant from Pre-cum?",
        description: "Pre-ejaculate, also known as pre-cum, is often a topic of curiosity: Can it cause pregnancy?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/lusttropfen/lusttropfen.component').then(m => m.LusttropfenDeComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Sperm Analysis: Everything You Need to Know About Assessing Male Fertility",
        description: "Semen Analysis: Everything You Need to Know About Evaluating Male Fertility",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/spermiogramm/spermiogramm.component').then(m => m.SpermiogramDeComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Preimplantation Genetic Diagnosis in UK: A Hope for a Healthy Child?",
        description: "Preimplantation Genetic Diagnosis (PGD) is a medical procedure used to screen embryos for genetic abnormalities before implantation.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/PID/PID.component').then(m => m.PIDDeComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Luteal Phase Deficiency: Causes, Symptoms, and Effective Treatment Options",
        description: "Welcoming a new baby is a joyous milestone, but luteal phase deficiency (LPD) can bring both medical and emotional hurdles.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/gelbkörperschwäche/gelbkörperschwäche.component').then(m => m.GelbkörperschwächeDeComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Unvaccinated Sperm vs. Vaccinated Sperm: Facts About Sperm Quality After the COVID-19 Vaccine",
        description: "The COVID-19 pandemic has not only disrupted the daily lives of many but also raised questions about human reproduction. Specifically, the debate around unvaccinated sperm vs. vaccinated sperm has sparked lively discussions.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/impf/impf.component').then(m => m.ImpfDeComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "In Vitro Fertilization (IVF): Comprehensive Guide, Costs, Steps, and More",
        description: "In Vitro Fertilization (IVF): Comprehensive Guide, Costs, Steps, and More",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/ivf/ivf.component').then(m => m.IvfDeComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: The Revolution in Reproductive Medicine for Treating Male Infertility",
        description: "ICSI: The Revolution in Reproductive Medicine for Treating Male Infertility",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/icsi/icsi.component').then(m => m.IcsiDeComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "All About Intrauterine Insemination (IUI): Procedure, Costs, and Success Rates",
        description: "All About Intrauterine Insemination (IUI): Procedure, Costs, and Success Rates",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/iui/iui.component').then(m => m.IuiDeComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Intracervical Insemination (ICI): The Gentle Method for Family Planning",
        description: "Intracervical Insemination (ICI): The Gentle Method for Family Planning",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/ici/ici.component').then(m => m.IciDeComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Artificial Insemination",
        description: "Artificial Insemination: Methods, Success Rates, and Costs Overview",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/kunstl/kunstl.component').then(m => m.KunstlDeComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Causes and Significance of the Dark Line During Pregnancy",
        description: "Linea Nigra: Causes and Significance of the Dark Line During Pregnancy",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/linea_nigra/linea_nigra.component').then(m => m.Linea_nigraDeComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantation: Definition, Timing, and Essential Information on Early Pregnancy",
        description: "Implantation: Definition, Timing, and Essential Information on Early Pregnancy",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/einnistung/einnistung.component').then(m => m.EinnistungDeComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Egg Donation in the United Kingdom: Key Opportunities, Risks, and Legislation",
        description: "In the United Kingdom, infertility remains a significant challenge for many couples and individuals. For those who aspire to become parents but struggle with natural conception, egg donation can offer a valuable alternative.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeDeComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Understanding Cervical Mucus: How to Identify Your Fertile Days",
        description: "The female body sends subtle signals before and during ovulation to indicate its fertile days.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/zervix/zervix.component').then(m => m.ZervixDEComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Understanding Ovulation: Your Key to Higher Fertility",
        description: "Ovulation is the decisive moment in the female cycle: it is at this point that fertilization can take place.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/ei/ei.component').then(m => m.EiDeComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "LH Surge and Ovulation Tests: How to Identify Your Fertile Days",
        description: "Do you want to better understand your body, keep a closer eye on your cycle, or are you planning a pregnancy? You’re in the right place. In this post, you’ll learn everything about the LH surge and its central role in ovulation.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/lh/lh.component').then(m => m.LhDeComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Breast Growth and Tenderness During Pregnancy: Causes, Tips, and Holistic Care",
        description: "Pregnancy is an incredibly exciting time when your body undergoes a wide range of changes. These changes are particularly noticeable in your breasts as they prepare for breastfeeding.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB//brustbeischwang/brustbeischwang.component').then(m => m.BrustbeischwangDeComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Surrogacy in the UK: Legal Considerations, Costs and Alternatives",
        description: "Surrogacy can be a vital option for individuals and couples who are unable to have a child through natural conception or other reproductive procedures.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/leihmut/leihmut.component').then(m => m.LeihmutDeComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Transmittable Diseases in Sperm Donation",
        description: "Sperm donation enables many people to realise their desire to have a child – be it single women, lesbian couples, or heterosexual couples facing male infertility.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/krankheiten/krankheiten.component').then(m => m.KrankheitenDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Vasectomy Reversal – How Men Can Successfully Regain Their Fertility",
        description: "The decision to undergo a vasectomy is often made when family planning is considered complete. However, life is full of surprises and changes – the desire to have children again can quickly arise, for example, through a new partnership or changed life goals.",
      ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/referti-mann/referti-mann.component').then(m => m.RefertiMannDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Tubal Reversal After Sterilization: How Women Can Conceive After a Tubal Ligation",
        description: "Life circumstances change – what was considered a definitive decision just a few years ago can be reevaluated today. Women who once opted for sterilization (tubal ligation) may later desire to become pregnant.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/referti-frau/referti-frau.component').then(m => m.RefertiFrauDeComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Costs of Artificial Fertilisation in the UK: How Expensive Is It?",
        description: "Artificial fertilisation can make the dream of having your own child come true – however, in the UK, it is often associated with significant costs.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-GB/kostenAI/kostenAI.component').then(m => m.KostenaiDEComponent)
    },


  ];
  static readonly IndianEnglishRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Home Insemination: A Private Way to Conceive Without Sex",
        description: "Home insemination, also referred to as DIY artificial insemination, is gaining popularity in India among individuals and couples seeking a private and affordable way to conceive without sexual intercourse.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Sperm Donation in India: Legal Framework, Guidelines, and How RattleStork Can Assist ",
        description: "Sperm donation in India is a well-established practice that aids various family structures in fulfilling their desire for children.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Becoming a Sperm Donor in India: Everything You Need to Know – with RattleStork",
        description: "Becoming a sperm donor in India is a noble act that allows individuals to help others achieve their dream of parenthood.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "The Stork Legend Explained: Why Storks Are Said to Bring Babies",
        description: "Have you ever wondered why the stork is seen as a symbol of babies?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/klapper/klapper.component').then(m => m.KlapperComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "How Long Do Sperm Live? Insights, Factors, and Practical Tips",
        description: "How long can sperm survive, and what factors influence their lifespan?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/ueberlebenspermien/ueberlebenspermien.component').then(m => m.UeberlebenspermienDeComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Sperm Cramps: Debunking an Internet Myth",
        description: "The internet is a vast repository of information, but not everything you come across is accurate or credible.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/spermakrampf/spermakrampf.component').then(m => m.SpermakrampfDeComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: A Modern Approach to Family and Parenting in India",
        description: "Co-Parenting is an innovative family concept where two or more adults share the responsibility of raising a child, regardless of whether they are romantically involved.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/co-parenting/co-parenting.component').then(m => m.CoParentingDeComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Blue Balls, Testicular Pain, and Sperm Retention",
        description: "Blue Balls, Testicular Pain, and Sperm Retention",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/samenstau/samenstau.component').then(m => m.SamenstauDeComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Pre-ejaculate and Pregnancy: Can You Get Pregnant from Pre-cum?",
        description: "Pre-ejaculate, also known as pre-cum, is often a topic of curiosity: Can it cause pregnancy?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/lusttropfen/lusttropfen.component').then(m => m.LusttropfenDeComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Sperm Analysis: Everything You Need to Know About Assessing Male Fertility",
        description: "Semen Analysis: Everything You Need to Know About Evaluating Male Fertility",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/spermiogramm/spermiogramm.component').then(m => m.SpermiogramDeComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Preimplantation Genetic Diagnosis in India: A Hope for a Healthy Family",
        description: "Preimplantation Genetic Diagnosis (PGD) is an advanced medical procedure that helps detect genetic abnormalities in embryos before implantation.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/PID/PID.component').then(m => m.PIDDeComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Luteal Phase Deficiency: Causes, Symptoms, and Effective Treatment Options",
        description: "Welcoming a new baby is a joyous milestone, but luteal phase deficiency (LPD) can bring both medical and emotional hurdles.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/gelbkörperschwäche/gelbkörperschwäche.component').then(m => m.GelbkörperschwächeDeComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Unvaccinated Sperm vs. Vaccinated Sperm: Facts About Sperm Quality After the COVID-19 Vaccine",
        description: "The COVID-19 pandemic has not only disrupted the daily lives of many but also raised questions about human reproduction. Specifically, the debate around unvaccinated sperm vs. vaccinated sperm has sparked lively discussions.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/impf/impf.component').then(m => m.ImpfDeComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "In Vitro Fertilization (IVF): Comprehensive Guide, Costs, Steps, and More",
        description: "In Vitro Fertilization (IVF): Comprehensive Guide, Costs, Steps, and More",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/ivf/ivf.component').then(m => m.IvfDeComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: Die Revolution der Reproduktionsmedizin zur Behandlung männlicher Unfruchtbarkeit",
        description: "Wie die Intrazytoplasmatische Spermieninjektion (ICSI) die Reproduktionsmedizin verändert: Chancen für Paare mit Fruchtbarkeitsproblemen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/icsi/icsi.component').then(m => m.IcsiDeComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "All About Intrauterine Insemination (IUI): Procedure, Costs, and Success Rates",
        description: "All About Intrauterine Insemination (IUI): Procedure, Costs, and Success Rates",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/iui/iui.component').then(m => m.IuiDeComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Intracervical Insemination (ICI): The Gentle Method for Family Planning",
        description: "Intracervical Insemination (ICI): The Gentle Method for Family Planning",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/ici/ici.component').then(m => m.IciDeComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Artificial Insemination",
        description: "Artificial Insemination: Methods, Success Rates, and Costs Overview",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/kunstl/kunstl.component').then(m => m.KunstlDeComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Causes and Significance of the Dark Line During Pregnancy",
        description: "Linea Nigra: Causes and Significance of the Dark Line During Pregnancy",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/linea_nigra/linea_nigra.component').then(m => m.Linea_nigraDeComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantation: Definition, Timing, and Essential Information on Early Pregnancy",
        description: "Implantation: Definition, Timing, and Essential Information on Early Pregnancy",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/einnistung/einnistung.component').then(m => m.EinnistungDeComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Egg Donation in India: Key Opportunities, Risks, and Regulations",
        description: "In India, challenges related to infertility affect many couples every year. For those yearning to have a child but encountering difficulties conceiving naturally, egg donation has emerged as a viable assisted reproductive technique.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeDeComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Understanding Cervical Mucus: How to Identify Your Fertile Days",
        description: "The female body sends subtle signals before and during ovulation to indicate its fertile days.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/zervix/zervix.component').then(m => m.ZervixDEComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Understanding Ovulation: Your Key to Higher Fertility",
        description: "Ovulation is the decisive moment in the female cycle: it is at this point that fertilization can take place.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/ei/ei.component').then(m => m.EiDeComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "LH Surge and Ovulation Tests: How to Identify Your Fertile Days",
        description: "Do you want to better understand your body, keep a closer eye on your cycle, or are you planning a pregnancy? You’re in the right place. In this post, you’ll learn everything about the LH surge and its central role in ovulation.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/lh/lh.component').then(m => m.LhDeComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Breast Growth and Tenderness During Pregnancy: Causes, Tips, and Holistic Care",
        description: "Pregnancy is an incredibly exciting time when your body undergoes a wide range of changes. These changes are particularly noticeable in your breasts as they prepare for breastfeeding.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN//brustbeischwang/brustbeischwang.component').then(m => m.BrustbeischwangDeComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Surrogacy in India: Legal Framework, Costs, and Alternatives",
        description: "Surrogacy can be a vital option for individuals and couples who are unable to have a child through natural conception or other reproductive procedures.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/leihmut/leihmut.component').then(m => m.LeihmutDeComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Transmittable Diseases in Sperm Donation",
        description: "Sperm donation enables many people to realise their desire to have a child – be it single women, lesbian couples, or heterosexual couples facing male infertility.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/krankheiten/krankheiten.component').then(m => m.KrankheitenDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Vasectomy Reversal – How Men Can Successfully Regain Their Fertility",
        description: "The decision to undergo a vasectomy is often made when family planning is considered complete. However, life is full of surprises and changes – the desire to have children again can quickly arise, for example, through a new partnership or changed life goals.",
       ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/referti-mann/referti-mann.component').then(m => m.RefertiMannDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Tubal Reversal After Sterilization: How Women Can Conceive After a Tubal Ligation",
        description: "Life circumstances change – what was considered a definitive decision just a few years ago can be reevaluated today. Women who once opted for sterilization (tubal ligation) may later desire to become pregnant.",
         ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/referti-frau/referti-frau.component').then(m => m.RefertiFrauDeComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Cost of Artificial Insemination in India: How Expensive is it?",
        description: "Artificial insemination can make the dream of having your own child come true – however, in India, it is often associated with significant costs.",
          ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/en-IN/kostenAI/kostenAI.component').then(m => m.KostenaiDEComponent)
    },



  ];
  static readonly MexicanRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Embarazo con Jeringa: Guía Práctica de Inseminación Artificial en Casa",
        description: "Inseminación artificial casera: guía completa, consejos, herramientas y respuestas a tus preguntas para cumplir tu sueño de ser mamá.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Donación de Esperma en México: Marco Legal, Consejos y Cómo Puede Ayudar RattleStork",
        description: "La donación de esperma en México es una práctica establecida que permite a diversas configuraciones familiares cumplir su sueño de tener hijos.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Convertirse en Donante de Esperma en México: Todo lo que Necesitas Saber – con RattleStork",
        description: "Convertirse en donante de esperma en México es una forma valiosa de ayudar a personas y parejas a cumplir su sueño de formar una familia.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "La leyenda de la cigüeña: ¿Por qué se dice que trae a los bebés?",
        description: "¿Te has preguntado alguna vez por qué la cigüeña está asociada con los bebés?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/klapper/klapper.component').then(m => m.KlapperComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "¿Cuánto tiempo sobreviven los espermatozoides? Consejos prácticos y factores importantes",
        description: "Los espermatozoides son esenciales para la reproducción humana, pero su capacidad para sobrevivir depende de muchos factores.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/ueberlebenspermien/ueberlebenspermien.component').then(m => m.UeberlebenspermienDeComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Calambres de esperma: Un mito de internet sin fundamento médico",
        description: "Internet es un espacio donde la información se difunde con rapidez, incluyendo conceptos que pueden sonar creíbles pero que carecen de base sólida.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/spermakrampf/spermakrampf.component').then(m => m.SpermakrampfDeComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: Un enfoque moderno para las familias mexicanas",
        description: "El Co-Parenting es un modelo familiar innovador en el que dos o más adultos comparten la responsabilidad de un niño, independientemente de una relación romántica.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/co-parenting/co-parenting.component').then(m => m.CoParentingDeComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Congestión Seminal y Testículos Azules: ¿Qué hay detrás del Dolor de Caballero?",
        description: "Congestión seminal, dolor testicular, testículos azules",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/samenstau/samenstau.component').then(m => m.SamenstauDeComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Pre-eyaculado y Embarazo: ¿Es posible quedar embarazada por el pre-eyaculado?",
        description: "El pre-eyaculado, también conocido como líquido preseminal, representa un enigma para muchas personas: ¿Es posible quedar embarazada por él?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/lusttropfen/lusttropfen.component').then(m => m.LusttropfenDeComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Espermiograma: Todo lo Importante sobre el Análisis de la Fertilidad Masculina",
        description: "Cuando el deseo de tener hijos no se cumple, las parejas a menudo entran en una fase llena de preguntas e incertidumbres. Un espermiograma puede ayudar a encontrar las primeras respuestas e identificar posibles razones para la falta de embarazo.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/spermiogramm/spermiogramm.component').then(m => m.SpermiogramDeComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnóstico Genético Preimplantacional (DGP): ¿Esperanza de un bebé sano?",
        description: "El Diagnóstico Genético Preimplantacional (DGP) es un procedimiento que permite analizar genéticamente a los embriones antes de transferirlos al útero.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/PID/PID.component').then(m => m.PIDDeComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Insuficiencia Lútea: Causas, Síntomas y Opciones de Tratamiento Efectivas",
        description: "El nacimiento de un hijo es un acontecimiento alegre que, sin embargo, puede traer desafíos médicos y emocionales en caso de insuficiencia lútea. Este trastorno hormonal, caracterizado por una deficiencia de progesterona, puede reducir significativamente las probabilidades de un embarazo exitoso.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/gelbkörperschwäche/gelbkörperschwäche.component').then(m => m.GelbkörperschwächeDeComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Esperma No Vacunado vs. Esperma Vacunado: Hechos sobre la Calidad del Esperma Después de la Vacunación COVID-19",
        description: "La pandemia de COVID-19 no solo ha cambiado la vida cotidiana de muchas personas, sino que también ha planteado preguntas sobre la reproducción humana.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/impf/impf.component').then(m => m.ImpfDeComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Fecundación In Vitro (FIV): Guía Completa: Costos, Pasos y Más",
        description: "Fecundación In Vitro (FIV): Guía Completa: Costos, Pasos y Más",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/ivf/ivf.component').then(m => m.IvfDeComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "Inyección Intracitoplasmática de Espermatozoides (ICSI): Revolución en la Medicina Reproductiva para el Tratamiento de la Infertilidad Masculina",
        description: "Gracias a la medicina reproductiva moderna, hoy en día existen múltiples opciones para ayudar a parejas con problemas de fertilidad. La Inyección Intracitoplasmática de Espermatozoides (ICSI) es un método particularmente eficaz para tratar la infertilidad masculina.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/icsi/icsi.component').then(m => m.IcsiDeComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Todo sobre la Inseminación Intrauterina (IUI): Proceso, Costos y Probabilidades de Éxito",
        description: "Todo sobre la Inseminación Intrauterina (IUI): Proceso, Costos y Probabilidades de Éxito",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/iui/iui.component').then(m => m.IuiDeComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminación Intracervical (ICI)",
        description: "Inseminación Intracervical (ICI)",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/ici/ici.component').then(m => m.IciDeComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Fecundación artificial: métodos, probabilidades de éxito y costos en resumen",
        description: "Fecundación artificial: métodos, probabilidades de éxito y costos en resumen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/kunstl/kunstl.component').then(m => m.KunstlDeComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Causas y Significado de la Línea Oscura Durante el Embarazo",
        description: "Linea Nigra: Causas y Significado de la Línea Oscura Durante el Embarazo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/linea_nigra/linea_nigra.component').then(m => m.Linea_nigraDeComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantación: Definición, Momento y Información Esencial sobre el Embarazo Temprano",
        description: "Implantación: Definición, Momento y Información Esencial sobre el Embarazo Temprano",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/einnistung/einnistung.component').then(m => m.EinnistungDeComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Donación de óvulos en México: Todo sobre oportunidades, riesgos y regulación",
        description: "En México, la infertilidad representa un desafío importante para muchas parejas. Quienes desean tener un hijo y no pueden lograrlo de forma natural suelen plantearse la donación de óvulos.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeDeComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Entendiendo el moco cervical: Cómo identificar tus días fértiles",
        description: "El cuerpo femenino envía señales sutiles antes y durante la ovulación para indicar sus días fértiles.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/zervix/zervix.component').then(m => m.ZervixDEComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Entendiendo la Ovulación: Tu Clave para una Mayor Fertilidad",
        description: "La ovulación es el momento decisivo en el ciclo femenino: es aquí donde se determina si puede ocurrir la fertilización.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/ei/ei.component').then(m => m.EiDeComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Aumento de LH y pruebas de ovulación: Así identificas tus días fértiles",
        description: "¿Quieres entender mejor tu cuerpo, llevar un control más preciso de tu ciclo o estás planeando un embarazo? Entonces, estás en el lugar correcto.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/lh/lh.component').then(m => m.LhDeComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Crecimiento de los senos y dolores en el embarazo: causas, consejos y cuidado integral",
        description: "El embarazo es una etapa increíblemente emocionante en la que tu cuerpo cambia de muchas maneras. Estos cambios se evidencian especialmente en los senos, que se preparan para la lactancia que se aproxima.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX//brustbeischwang/brustbeischwang.component').then(m => m.BrustbeischwangDeComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Gestación Subrogada en México: Cuestiones Legales, Costos y Alternativas",
        description: "La gestación subrogada puede ser una opción importante para personas o parejas que, por diversas razones, no pueden tener un hijo de manera natural o mediante otros tratamientos de reproducción asistida.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/leihmut/leihmut.component').then(m => m.LeihmutDeComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Enfermedades Transmisibles en la Donación de Semen",
        description: "La donación de semen permite a muchas personas cumplir su deseo de tener hijos, ya sean mujeres solteras, parejas lesbianas o parejas heterosexuales con infertilidad masculina.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/krankheiten/krankheiten.component').then(m => m.KrankheitenDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertilización después de una Vasectomía – Cómo los hombres pueden recuperar exitosamente su fertilidad",
        description: "La decisión de someterse a una vasectomía suele tomarse cuando se considera que la planificación familiar está completa. Sin embargo, la vida está llena de sorpresas y cambios; el deseo de tener hijos nuevamente puede surgir rápidamente, por ejemplo, debido a una nueva relación o a objetivos de vida cambiantes.",
       ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/referti-mann/referti-mann.component').then(m => m.RefertiMannDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertilización después de la Esterilización: Cómo las Mujeres Pueden Quedar Embarazadas Después de una Ligadura de Trompas",
        description: "Las circunstancias de la vida cambian: lo que hace unos años se consideraba una decisión definitiva, hoy puede ser reevaluado. Las mujeres que en su momento optaron por una esterilización (ligadura de trompas) pueden posteriormente sentir el deseo de quedar embarazadas.",
           ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/referti-frau/referti-frau.component').then(m => m.RefertiFrauDeComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Costos de la Fertilización Asistida en México: ¿Cuánto Cuesta?",
        description: "La fertilización asistida puede hacer realidad el sueño de tener un hijo propio, pero en México a menudo está asociada con costos significativos.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/es-MX/kostenAI/kostenAI.component').then(m => m.KostenaiDEComponent)
    },



  ];
  static readonly BrasilRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Inseminação Caseira: Engravidar sem sexo",
        description: "A Inseminação Caseira, é uma opção popular para casais ou indivíduos que desejam engravidar sem relações sexuais.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Doação de Esperma no Brasil: Fundamentos Legais, Dicas e Como a RattleStork Pode Ajudar",
        description: "A doação de esperma no Brasil tem ganhado destaque nos últimos anos, sendo uma alternativa para a realização do sonho de muitas famílias.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Como Ser um Doador de Sêmen no Brasil: Tudo o que Você Precisa Saber com a RattleStork",
        description: "Tornar-se um doador de sêmen no Brasil é uma oportunidade única para homens que desejam contribuir para a realização do sonho de formar uma família.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "A Lenda da Cegonha: De Mensageira dos Bebês a Símbolo da Vida",
        description: "Desde tempos antigos, a cegonha é vista como um símbolo de fertilidade e vida.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/klapper/klapper.component').then(m => m.KlapperComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Quanto tempo os espermatozoides sobrevivem? Descubra os fatores e dicas essenciais",
        description: "Você já se perguntou quanto tempo os espermatozoides podem sobreviver e quais fatores influenciam sua longevidade?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/ueberlebenspermien/ueberlebenspermien.component').then(m => m.UeberlebenspermienDeComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Cãibras de esperma: Separando mitos da realidade",
        description: "Na era digital, informações enganosas podem se espalhar em uma velocidade alarmante.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/spermakrampf/spermakrampf.component').then(m => m.SpermakrampfDeComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting 2024: Alles, was Sie über moderne Familienmodelle wissen müssen",
        description: "Co-Parenting ist ein innovatives Familienmodell, bei dem zwei oder mehr Erwachsene die Verantwortung für ein Kind teilen – unabhängig von einer romantischen Beziehung.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/co-parenting/co-parenting.component').then(m => m.CoParentingDeComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Congestão Seminal e Testículos Azuis: O que está por trás da dor do cavalheiro?",
        description: "Muitos homens já ouviram falar de congestão seminal (também conhecida como testículos azuis ou dor do cavalheiro).",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/samenstau/samenstau.component').then(m => m.SamenstauDeComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Pré-ejaculado e Gravidez: É possível engravidar com o pré-ejaculado?",
        description: "O pré-ejaculado, também conhecido como pré-sêmen, representa um mistério para muitas pessoas: é possível engravidar com ele?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/lusttropfen/lusttropfen.component').then(m => m.LusttropfenDeComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Espermiograma: Tudo o que Você Precisa Saber sobre a Análise da Fertilidade Masculina",
        description: "Um espermiograma pode ajudar a encontrar as primeiras respostas e identificar possíveis razões para a ausência de gravidez. Neste artigo, você descobrirá o que é um espermiograma, como o teste é realizado e quais valores são importantes na análise.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/spermiogramm/spermiogramm.component').then(m => m.SpermiogramDeComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnóstico Genético Pré-implantação: Procedimentos, Aspetos Legais e Experiências",
        description: "Diagnóstico Genético Pré-implantação: Procedimentos, Aspetos Legais e Experiências",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/PID/PID.component').then(m => m.PIDDeComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Gelbkörperschwäche: Ursachen, Symptome und wirksame Behandlungsoptionen",
        description: "Erfahren Sie alles über Gelbkörperschwäche: Ursachen, Symptome, Diagnose und wirksame Behandlungsmöglichkeiten zur Unterstützung bei Fruchtbarkeitsproblemen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/gelbkörperschwäche/gelbkörperschwäche.component').then(m => m.GelbkörperschwächeDeComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Esperma Não Vacinado vs. Esperma Vacinado: Fatos sobre a Qualidade do Esperma Após a Vacinação contra COVID-19",
        description: "A pandemia de COVID-19 não apenas mudou o cotidiano de muitas pessoas, mas também suscitou questões sobre a reprodução humana.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/impf/impf.component').then(m => m.ImpfDeComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Fertilização In Vitro: Guia Completo: Custos, Etapas e Taxas de Sucesso",
        description: " O desejo de ter um filho é um objetivo de vida central para muitas pessoas. No entanto, quando a tão esperada gravidez não se concretiza, isso pode ser angustiante – especialmente se todas as opções convencionais forem esgotadas.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/ivf/ivf.component').then(m => m.IvfDeComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: A Revolução da Medicina Reprodutiva no Tratamento da Infertilidade Masculina",
        description: "ICSI: A Revolução da Medicina Reprodutiva no Tratamento da Infertilidade Masculina",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/icsi/icsi.component').then(m => m.IcsiDeComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Tudo sobre Inseminação Intrauterina (IIU): Procedimento, Custos e Taxas de Sucesso",
        description: "Tudo sobre Inseminação Intrauterina (IIU): Procedimento, Custos e Taxas de Sucesso",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/iui/iui.component').then(m => m.IuiDeComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminação Intracervical (ICI): O Método Suave para Planejamento Familiar",
        description: "Inseminação Intracervical (ICI): O Método Suave para Planejamento Familiar",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/ici/ici.component').then(m => m.IciDeComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Fertilização Artificial: Métodos, Taxas de Sucesso e Custos em Perspectiva",
        description: "Fertilização Artificial: Métodos, Taxas de Sucesso e Custos em Perspectiva",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/kunstl/kunstl.component').then(m => m.KunstlDeComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linha Negra: Causas e Significado da Linha Escura Durante a Gravidez",
        description: "Linha Negra: Causas e Significado da Linha Escura Durante a Gravidez",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/linea_nigra/linea_nigra.component').then(m => m.Linea_nigraDeComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantação: Definição, Momento e Informações Essenciais sobre o Início da Gravidez",
        description: "Implantação: Definição, Momento e Informações Essenciais sobre o Início da Gravidez",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/einnistung/einnistung.component').then(m => m.EinnistungDeComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Doação de óvulos no Brasil: Tudo sobre oportunidades, riscos e enquadramento legal",
        description: "No Brasil, a infertilidade é um desafio enfrentado por diversos casais e indivíduos que desejam ter filhos. Problemas de ovulação, questões genéticas e outros fatores podem impedir a concepção de forma natural.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeDeComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Compreendendo o muco cervical: Como identificar seus dias férteis",
        description: "O corpo feminino envia sinais sutis antes e durante a ovulação para indicar seus dias férteis.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/zervix/zervix.component').then(m => m.ZervixDEComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Entenda a Ovulação: Sua Chave para uma Fertilidade Maior",
        description: "A ovulação é o momento decisivo no ciclo feminino: é exatamente nesse instante que se decide se a fertilização pode ocorrer.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/ei/ei.component').then(m => m.EiDeComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Aumento de LH e Testes de Ovulação: Assim você identifica seus dias férteis",
        description: "Você deseja entender melhor seu corpo, acompanhar seu ciclo com mais precisão ou está planejando uma gravidez? Então você está no lugar certo. Neste artigo você descobrirá tudo sobre o aumento de LH e seu papel central na ovulação.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/lh/lh.component').then(m => m.LhDeComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Crescimento das mamas e dores durante a gravidez: causas, dicas e cuidados holísticos",
        description: "A gravidez é um período incrivelmente empolgante, no qual seu corpo passa por várias transformações. Essas mudanças são especialmente evidentes nas mamas, que se preparam para a futura amamentação.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR//brustbeischwang/brustbeischwang.component').then(m => m.BrustbeischwangDeComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Barriga Solidária no Brasil: Questões Jurídicas, Custos e Alternativas",
        description: "A barriga solidária pode ser uma importante alternativa para pessoas ou casais que, por questões médicas ou outras dificuldades, não conseguem realizar o sonho da maternidade ou paternidade naturalmente.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/leihmut/leihmut.component').then(m => m.LeihmutDeComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Doenças Transmissíveis na Doação de Esperma",
        description: "A doação de esperma possibilita que muitas pessoas realizem o sonho de ter um filho – seja para mulheres solteiras, casais de lésbicas ou casais heterossexuais com infertilidade masculina.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/krankheiten/krankheiten.component').then(m => m.KrankheitenDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Reverter a Vasectomia – Como os Homens Podem Recuperar com Sucesso a Sua Fertilidade",
        description: "A decisão de realizar uma vasectomia é frequentemente tomada quando o planeamento familiar é considerado concluído. No entanto, a vida está cheia de surpresas e mudanças – um novo desejo de ter filhos pode surgir rapidamente, por exemplo, devido a um novo relacionamento ou a objetivos de vida alterados.",
       ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/referti-mann/referti-mann.component').then(m => m.RefertiMannDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertilização após Esterilização: Como Mulheres Podem Engravidar Após uma Ligadura de Trompas",
        description: "As circunstâncias de vida mudam – o que há poucos anos era considerado uma decisão definitiva, hoje pode ser avaliado de forma diferente. Mulheres que anteriormente optaram por uma esterilização (ligadura de trompas) podem posteriormente desejar engravidar novamente.",
          ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/referti-frau/referti-frau.component').then(m => m.RefertiFrauDeComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Custos de Fertilização Artificial no Brasil: Quanto Vai Custar?",
        description: "A fertilização artificial pode realizar o sonho de ter um filho – no entanto, no Brasil, frequentemente está associada a custos significativos.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/pt-BR/kostenAI/kostenAI.component').then(m => m.KostenaiDEComponent)
    },


  ];
  static readonly IndonesiaRoutes: Routes = [
    {
      path: Paths.Impressum,
      loadChildren: () => import('./core/components/footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogBechermethodeHeiminsemination,
      data: {
        title: "Inseminasi Mandiri: Hamil Tanpa Hubungan Seksual",
        description: "Inseminasi mandiri, atau inseminasi buatan di rumah, adalah metode yang memungkinkan pasangan atau individu untuk mencapai kehamilan tanpa hubungan seksual.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/bechermethodeheiminsemination/bechermethodeheiminsemination.component').then(m => m.BechermethodeHeiminseminationComponent)
    },
    {
      path: Paths.BlogSamenspendeLandXYZVersion1,
      data: {
        title: "Donor Sperma di Indonesia: Apa yang Perlu Anda Ketahui",
        description: "Di Indonesia, donor sperma tidak diizinkan secara hukum",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/BlogSamenspendeLandXYZVersion1/BlogSamenspendeLandXYZVersion1.component').then(m => m.BlogSamenspendeLandXYZVersion1Component)
    },
    {
      path: Paths.BlogWerdeSpenderLandXYZVersion1,
      data: {
        title: "Donor Sperma di Indonesia: Semua yang Perlu Anda Ketahui",
        description: "Di Indonesia, praktik donor sperma tidak diperbolehkan secara hukum.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/BlogWerdeSpenderLandXYZVersion1/BlogWerdeSpenderLandXYZVersion1.component').then(m => m.BlogWerdeSpenderLandXYZVersion1Component)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "Legenda Bangau: Mengapa Bangau Membawa Bayi?",
        description: "Pernahkah Anda bertanya-tanya mengapa bangau dianggap sebagai simbol bayi?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/klapper/klapper.component').then(m => m.KlapperComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Berapa Lama Sperma Bertahan Hidup? Panduan Lengkap dan Tips Praktis",
        description: "Berapa lama sperma dapat bertahan hidup di berbagai kondisi, dan faktor apa saja yang memengaruhinya?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/ueberlebenspermien/ueberlebenspermien.component').then(m => m.UeberlebenspermienDeComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Kram Sperma: Memahami Mitos dan Realita di Era Digital",
        description: "Dalam era informasi yang terus berkembang, internet menjadi sumber utama bagi banyak orang untuk mencari jawaban atas berbagai pertanyaan, termasuk masalah kesehatan.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/spermakrampf/spermakrampf.component').then(m => m.SpermakrampfDeComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting: Pendekatan Modern untuk Keluarga dan Pengasuhan",
        description: "Co-Parenting adalah konsep keluarga inovatif yang memungkinkan dua atau lebih orang dewasa untuk berbagi tanggung jawab dalam membesarkan anak, meskipun mereka tidak terlibat dalam hubungan romantis.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/co-parenting/co-parenting.component').then(m => m.CoParentingDeComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Stagnasi Sperma & Testis Biru: Apa Sebenarnya di Balik Blue Balls?",
        description: "Stagnasi sperma biasanya merupakan fenomena yang tidak berbahaya, yang terjadi akibat gairah seksual yang berkepanjangan tanpa ejakulasi. Biasanya rasa sakit akan hilang begitu ereksi berkurang atau orgasme terjadi. Namun, jika mengalami keluhan yang sering dan berat, sebaiknya berkonsultasi dengan <strong>dokter</strong> untuk menyingkirkan penyebab lain.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/samenstau/samenstau.component').then(m => m.SamenstauDeComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Pre-ejakulasi dan Kehamilan: Apakah Seseorang Bisa Hamil Karena Pre-ejakulasi?",
        description: "Pre-ejakulasi, juga dikenal sebagai cairan pra-ejakulasi, merupakan teka-teki bagi banyak orang: Apakah seseorang bisa hamil karenanya?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/lusttropfen/lusttropfen.component').then(m => m.LusttropfenDeComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Spermiogram: Semua yang Perlu Diketahui tentang Analisis Kesuburan Pria",
        description: "Spermiogram dapat membantu menemukan jawaban awal dan mengidentifikasi kemungkinan penyebab kegagalan kehamilan. Dalam artikel ini, Anda akan mempelajari apa itu spermiogram, bagaimana tes ini dilakukan, dan nilai-nilai apa saja yang diperhatikan dalam evaluasi.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/spermiogramm/spermiogramm.component').then(m => m.SpermiogramDeComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnosis Pra-Implantasi: Harapan untuk Memiliki Anak Sehat?",
        description: "Diagnosis Pra-Implantasi adalah prosedur untuk menilai kelainan genetik pada embrio sebelum ditempatkan di dalam rahim.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/PID/PID.component').then(m => m.PIDDeComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Defisiensi Fase Luteal: Penyebab, Gejala, dan Pilihan Pengobatan yang Efektif",
        description: "Kelahiran seorang anak adalah momen yang penuh kebahagiaan, namun bagi beberapa wanita, defisiensi fase luteal (insufisiensi luteal) dapat menimbulkan tantangan medis dan emosional.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/gelbkörperschwäche/gelbkörperschwäche.component').then(m => m.GelbkörperschwächeDeComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Sperma Tidak Divaksinasi vs. Sperma Divaksinasi: Fakta tentang Kualitas Sperma Setelah Vaksin COVID-19",
        description: "Pandemi COVID-19 tidak hanya mengubah kehidupan sehari-hari banyak orang, tetapi juga menimbulkan pertanyaan tentang reproduksi manusia.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/impf/impf.component').then(m => m.ImpfDeComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "In-Vitro Fertilisasi: Panduan Lengkap: Biaya, Tahapan, dan Peluang Keberhasilan",
        description: "Keinginan untuk memiliki anak adalah tujuan hidup utama bagi banyak orang. Namun, jika kehamilan yang diidamkan tidak terjadi, hal ini bisa menjadi beban—terutama jika semua opsi konvensional telah dicoba.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/ivf/ivf.component').then(m => m.IvfDeComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "Injeksi Spermatozoa Intrazitoplasma: Revolusi dalam Kedokteran Reproduksi untuk Mengobati Infertilitas Pria",
        description: "Berkat kedokteran reproduksi modern, saat ini terdapat berbagai kemungkinan untuk membantu pasangan yang menghadapi masalah kesuburan. Injeksi Spermatozoa Intrazitoplasma (ICSI) adalah metode yang sangat efektif untuk mengobati infertilitas pria.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/icsi/icsi.component').then(m => m.IcsiDeComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Semua Tentang Inseminasi Intrauterine: Prosedur, Biaya, dan Peluang Keberhasilan",
        description: "Inseminasi intrauterine (IUI) adalah metode yang terbukti untuk membantu pasangan yang mengalami kesulitan hamil. Artikel ini memberikan gambaran singkat tentang prosedur, biaya, dan peluang keberhasilan metode pengobatan ini.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/iui/iui.component').then(m => m.IuiDeComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminasi Intraserikal: Metode Ramah untuk Perencanaan Keluarga",
        description: "Inseminasi Intraserikal menawarkan pasangan dan individu metode yang lembut dan fleksibel untuk perencanaan keluarga. Artikel ini menjelaskan cara kerja ICI, manfaatnya, dan mengapa metode ini menjadi pilihan menarik bagi banyak orang.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/ici/ici.component').then(m => m.IciDeComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Inseminasi Buatan: Metode, Peluang Keberhasilan, dan Biaya secara Keseluruhan",
        description: "Inseminasi buatan memberikan banyak orang kesempatan untuk mewujudkan keinginan memiliki anak meskipun menghadapi masalah kesuburan. Prosedur medis ini mencakup berbagai metode yang bertujuan meningkatkan peluang kehamilan yang sukses.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/kunstl/kunstl.component').then(m => m.KunstlDeComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Penyebab, Makna, dan Perawatan Garis Gelap selama Kehamilan",
        description: "Selama kehamilan, tubuh seorang wanita mengalami perubahan yang terlihat dan tidak terlihat. Salah satu perubahan yang mencolok adalah Linea nigra – garis gelap yang memanjang secara vertikal di perut.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/linea_nigra/linea_nigra.component').then(m => m.Linea_nigraDeComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantasi: Definisi, Waktu dan Informasi tentang Kehamilan Awal",
        description: "Kehamilan sering dimulai dengan tidak mencolok, namun dalam periode ini terjadi hal-hal menakjubkan: Begitu sel telur dibuahi, ia memulai perjalanannya menuju rahim. Di sana, terjadi langkah penting – yang disebut implantasi.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/einnistung/einnistung.component').then(m => m.EinnistungDeComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Donor Sel Telur di Indonesia: Peluang, Risiko, dan Kerangka Hukum",
        description: "Di Indonesia, pasangan yang mengalami masalah kesuburan menghadapi tantangan besar. Bagi mereka yang mengidamkan kehadiran anak namun tidak mampu hamil secara alami, donor sel telur menjadi salah satu solusi dalam program reproduksi berbantuan.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeDeComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Memahami Lendir Serviks: Cara Mengenali Hari Subur Anda",
        description: "Tubuh wanita mengirimkan sinyal-sinyal halus sebelum dan selama ovulasi untuk menunjukkan hari-hari subur.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/zervix/zervix.component').then(m => m.ZervixDEComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Memahami Ovulasi: Kunci Anda Menuju Kesuburan yang Lebih Tinggi",
        description: "Ovulasi adalah momen krusial dalam siklus wanita: Di sinilah keputusan terjadi, apakah pembuahan dapat terjadi.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/ei/ei.component').then(m => m.EiDeComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Peningkatan LH dan Tes Ovulasi: Begini Cara Mengetahui Hari Suburmu",
        description: "Apakah kamu ingin lebih memahami tubuhmu, memantau siklus menstruasimu dengan lebih akurat, atau sedang merencanakan kehamilan? Maka kamu berada di tempat yang tepat. Pada tulisan ini, kamu akan mempelajari segala hal tentang peningkatan LH dan perannya yang krusial dalam ovulasi.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/lh/lh.component').then(m => m.LhDeComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Pertumbuhan Payudara dan Nyeri Payudara Selama Kehamilan: Penyebab, Tips, dan Perawatan Holistik",
        description: "Kehamilan adalah masa yang sangat menarik, di mana tubuhmu mengalami banyak perubahan. Perubahan ini terutama terlihat pada payudara, yang dipersiapkan untuk menyusui yang akan datang.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id//brustbeischwang/brustbeischwang.component').then(m => m.BrustbeischwangDeComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Ibu Pengganti di Indonesia: Pertanyaan Hukum, Biaya, dan Alternatif",
        description: "Ibu pengganti dapat menjadi opsi penting bagi individu atau pasangan yang tidak dapat memiliki anak melalui cara alami atau prosedur reproduksi lainnya.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/leihmut/leihmut.component').then(m => m.LeihmutDeComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Penyakit Menular pada Donasi Sperma",
        description: "Donasi sperma memungkinkan banyak orang untuk mewujudkan keinginan memiliki anak – baik itu untuk wanita lajang, pasangan lesbian, maupun pasangan heteroseksual dengan masalah infertilitas pada pria.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/krankheiten/krankheiten.component').then(m => m.KrankheitenDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertilitas setelah Vasektomi – Bagaimana Pria Dapat Mengembalikan Kesuburan Mereka dengan Sukses",
        description: "Keputusan untuk menjalani vasektomi sering kali diambil ketika perencanaan keluarga dianggap selesai. Namun, kehidupan penuh dengan kejutan dan perubahan – keinginan untuk memiliki anak lagi bisa muncul dengan cepat, misalnya karena hubungan baru atau tujuan hidup yang berubah.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/referti-mann/referti-mann.component').then(m => m.RefertiMannDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertiliasi Setelah Sterilisasi: Bagaimana Wanita Dapat Hamil Kembali Setelah Ligatur Tuba",
        description: "Kondisi hidup berubah – apa yang beberapa tahun lalu dianggap sebagai keputusan akhir, sekarang bisa dinilai berbeda. Wanita yang pernah memilih sterilisasi (ligatur tuba) mungkin kemudian merasakan keinginan untuk hamil kembali.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/referti-frau/referti-frau.component').then(m => m.RefertiFrauDeComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Biaya Fertilisasi Buatan di Indonesia: Seberapa Mahal Prosesnya?",
        description: "Fertilisasi buatan dapat mewujudkan impian memiliki anak sendiri – namun di Indonesia sering kali disertai dengan biaya yang signifikan.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./core/components/blog/blogs/id/kostenAI/kostenAI.component').then(m => m.KostenaiDEComponent)
    },


  ];
  static readonly devOnlyRoutes: Routes = [
    {
      path: Paths.Workbench,
      loadComponent: () => import('./shared/components/workbench/workbench.component').then(m => m.WorkbenchComponent)
    },
  ];
  /**
   * The route relations are used to determine which routes are available in which languages. Theses relations are used to
   * to craete the hreflang links in the head of the html document.
   */
  static routeRelations = AppRoutingModule.setRouteRelations();

  /**
   * Adds the localized AppRoutingModule.generalRoutes to the router config, e.g. the faq route
   * with its localized component.
   */
  static addLocalizedRoutes(router: Router, LOCALE_ID: InjectionToken<string>, PLATFORM_ID: InjectionToken<Object>): () => Promise<void> {

    return () =>
      new Promise((resolve) => {

        switch (LOCALE_ID.toString()) {
          case 'de': AppRoutingModule.addGermanRoutes(router); break;
          case 'de-CH': AppRoutingModule.addSwissRoutes(router); break;
          case 'en': AppRoutingModule.addEnglishRoutes(router); break;
          case 'en-IN': AppRoutingModule.addIndianEnglishRoutes(router); break;
          case 'en-GB': AppRoutingModule.addGBRoutes(router); break;
          case 'zh': AppRoutingModule.addChineseRoutes(router); break;
          case 'es': AppRoutingModule.addSpanishRoutes(router); break;
          case 'es-MX': AppRoutingModule.addMexicanRoutes(router); break;
          case 'fr': AppRoutingModule.addFrenchRoutes(router); break;
          case 'hi': AppRoutingModule.addHindiRoutes(router); break;
          case 'ms': AppRoutingModule.addMalayRoutes(router); break;
          case 'id': AppRoutingModule.addIndonesiaRoutes(router); break;
          case 'pt': AppRoutingModule.addPortugueseRoutes(router); break;
          case 'pt-BR': AppRoutingModule.addBrasilRoutes(router); break;
          case 'ru': AppRoutingModule.addRussianRoutes(router); break;
          case 'pl': AppRoutingModule.addPolishRoutes(router); break;
          case 'tr': AppRoutingModule.addTurkishRoutes(router); break;
          case 'nl': AppRoutingModule.addDutchRoutes(router); break;
          case 'it': AppRoutingModule.addItalianRoutes(router); break;
          default: AppRoutingModule.addEnglishRoutes(router); break;
        }

        resolve();

      });
  }
  static setRouteRelations() {

    let routeTypeToLocale = {
      generalRoutes: ['de', 'de-CH', 'en', 'en-IN', 'en-GB', 'zh', 'es', 'es-MX', 'fr', 'hi', 'ms', 'id', 'pt', 'pt-BR', 'ru', 'pl', 'tr', 'nl', 'it'],
      germanRoutes: 'de',
      swissRoutes: 'de-CH',
      englishRoutes: 'en',
      IndianEnglishRoutes: 'en-IN',
      GBRoutes: 'en-GB',
      chineseRoutes: 'zh',
      spanishRoutes: 'es',
      MexicanRoutes: 'es-MX',
      frenchRoutes: 'fr',
      hindiRoutes: 'hi',
      malayRoutes: 'ms',
      IndonesiaRoutes: 'id',
      portugueseRoutes: 'pt',
      BrasilRoutes: 'pt-BR',
      russianRoutes: 'ru',
      polishRoutes: 'pl',
      turkeyRoutes: 'tr',
      dutchRoutes: 'nl',
      italianRoutes: 'it'
    }

    let routeRelations = {};
    let locale;

    for (let routeType of Object.keys(routeTypeToLocale)) {

      locale = routeTypeToLocale[routeType];

      for (let route of AppRoutingModule[routeType]) {

        if (!(route.path in routeRelations)) {
          routeRelations[route.path] = Array.isArray(locale) ? locale : [locale];
        } else {
          routeRelations[route.path].push(locale);
        }


      }

    }

    return routeRelations;

  }
  static getUniversalRoutes() {

    if (isDevMode()) {

      return [
        ...AppRoutingModule.generalRoutes,
        ...AppRoutingModule.devOnlyRoutes
      ]

    } else return [
      ...AppRoutingModule.generalRoutes,
    ]

  }
  static addGermanRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.germanRoutes
    ]);

  }
  static addSwissRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.swissRoutes
    ]);

  }
  static addEnglishRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.englishRoutes
    ]);

  }
  static addIndianEnglishRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.IndianEnglishRoutes
    ]);

  }
  static addGBRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.GBRoutes
    ]);

  }
  static addIndonesiaRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.IndonesiaRoutes
    ]);

  }
  static addBrasilRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.BrasilRoutes
    ]);

  }
  static addChineseRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.chineseRoutes
    ]);

  }
  static addSpanishRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.spanishRoutes
    ]);

  }
  static addFrenchRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.frenchRoutes
    ]);

  }
  static addHindiRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.hindiRoutes
    ]);

  }
  static addMalayRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.malayRoutes
    ]);

  }
  static addMexicanRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.MexicanRoutes
    ]);

  }
  static addPortugueseRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.portugueseRoutes
    ]);

  }
  static addRussianRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.russianRoutes
    ]);

  }
  static addPolishRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.polishRoutes
    ]);

  }
  static addTurkishRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.turkeyRoutes
    ]);

  }
  static addDutchRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.dutchRoutes
    ]);

  }
  static addItalianRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.getUniversalRoutes(),
      ...AppRoutingModule.italianRoutes
    ]);

  }
}






