import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import Analytics, { AnalyticsInstance } from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import { Subscription, filter } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AnalyticsConsentState } from '../interfaces/analytics.interface';

enum AllowAnalytics {
  true = 'true',
  false = 'false'
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements OnDestroy {

  analytics: AnalyticsInstance = null;
  termly: any = null;
  consentState: AnalyticsConsentState = {
    advertising: false,
    analytics: false,
    essential: false,
    performance: false,
    social_networking: false,
    unclassified: false
  };

  //keep refs to subscriptions to be able to unsubscribe later
  private subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
  ) {

    this.setAndTrackConsentState();
    this.startAnalyticsService();


  }
  ngOnDestroy() {

    // unsubscribe to cookieconsent observables to prevent memory leaks
    if (this.subscriptions) this.subscriptions.unsubscribe();
  }
  private setAndTrackConsentState() {

    if (isPlatformBrowser(this.platformId) && Capacitor.getPlatform() === 'web') {

      this.termly = window["Termly"];

      if (this.termly && this.termly.getConsentState) {

        this.checkAnalytics(this.termly.getConsentState());

      }

      //FIXME: When accepting or denying the cookie consent, the analytics should be updated accordingly

      // if (this.termly && typeof this.termly.getConsentState === 'function') {

      //   console.log('Termly.on is available');
      //   console.log('Termly.getConsentState', this.termly.getConsentState());

      //   this.termly.on('initialized', (data) => {

      //     console.log('Termly initialized', data);
      //     this.checkAnalytics(data);

      //   })

      //   this.termly.on('consent', (data) => {

      //     console.log('Termly consent', data);
      //     this.checkAnalytics(data);

      //   })

      // }

    } else {

      /**
       * If the app is not running in the browser, analytics are allowed by default. This is the case for native apps.
       */

      this.allowAnalytics();

    }

  }
  public async startAnalyticsService() {

    this.analytics = Analytics({
      app: 'awesome-app',
      plugins: [
        googleAnalytics({
          measurementIds: ['G-67RPFMPJ29']
        })
      ]
    });

    this.trackCookieEvents();
    this.startPageTracker();

  }
  private trackCookieEvents() {

  }
  /**
   * Check if analytics is allowed and set the local storage accordingly.
   *
   * @param data ConsentState data from Termly
   */
  private checkAnalytics(data) {

    if (data && data["analytics"] && data["analytics"] === true) {

      this.allowAnalytics();

    } else {

      this.denyAnalytics();

    }

  }
  private allowAnalytics() {
    if (isPlatformBrowser(this.platformId)) {

      console.log('allowAnalytics');
      localStorage.setItem('allowAnalytics', AllowAnalytics.true);

    }
  }
  private denyAnalytics() {
    if (isPlatformBrowser(this.platformId)) {

      console.log('denyAnalytics');
      localStorage.setItem('allowAnalytics', AllowAnalytics.false);

    }
  }
  public isAnalyticsAllowed(): boolean {
    if (isPlatformBrowser(this.platformId)) {

      const allow = localStorage.getItem('allowAnalytics');

      if (allow == AllowAnalytics.true) return true;
      else return false;

    }
    return false;
  }
  public trackPageView() {
    if (this.isAnalyticsAllowed() && isPlatformBrowser(this.platformId)) {

      this.analytics.page({
        url: window.location.href,
        path: window.location.pathname,
        referrer: document.referrer,
        title: document.title
      });

    }
  }
  public trackEvent(event: string, properties: any) {
    if (this.isAnalyticsAllowed() && isPlatformBrowser(this.platformId)) {

      this.analytics.track(event, properties);

    }
  }
  public trackUser(userId: string) {
    if (this.isAnalyticsAllowed() && isPlatformBrowser(this.platformId)) {

      this.analytics.identify(userId);

    }
  }
  private startPageTracker() {

    this.subscriptions.add(this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    ).subscribe((event) => {

      if (this.isAnalyticsAllowed()) this.trackPageView();

    }));

  }
}
